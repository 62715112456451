import React from "react";
import Skeleton from "react-loading-skeleton";

const MyLinksSkeleton = () => {
  const SkeletonStatBox = () => {
    return (
      <div className="link-skeleton">
        <Skeleton className="bannerSkeleton" />
      </div>
    );
  };
  return (
    <>
      {[...Array(6)].map((_, index) => (
        <React.Fragment key={index}>
          <SkeletonStatBox />
        </React.Fragment>
      ))}
    </>
  );
};

export default MyLinksSkeleton;
