import { Button, Input, List, Modal } from "antd";
import React, { useEffect, useState } from "react";
import "../../StayUpToDateControls.css";
import { updateComponent } from "../../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import EditFeedCheckboxComponent from "./EditFeedCheckboxComponent";

const EditCustomContent = (props: any) => {
  const {
    username,
    user_id,
    isContentEditModalOpen,
    type,
    component,
    refetch,
    initial,
    setInitial,
  } = props;
  const [name, setName] = useState(component.name || "");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [postIds, setPostIds] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [defaultQuery, setDefaultQuery] = useState("");
  const [isChange, setIsChange] = useState(false);
  useEffect(() => {
    if (isContentEditModalOpen) {
      setInitial((prev: any) => !prev);
    }
  }, [isContentEditModalOpen]);
  useEffect(() => {
    setName(component?.name);
    setPostIds(component?.post_ids);
    setDefaultQuery(component?.query);
  }, [component]);

  const handleInputChange = (value: string, setter: Function) => {
    setter(value);
    setIsButtonDisabled(!value);
  };
  const handleOk = async () => {
    setConfirmLoading(true);
    const res = await updateComponent(
      name,
      component,
      username,
      user_id,
      defaultQuery,
      "content",
      postIds.toString(),
      ""
    );
    if (res === "true") {
      props.refetch();

      props.setIsContentEditModalOpen(false);
      props.setSelectedComponent("");
      setConfirmLoading(false);
      setInitial((prev: any) => !prev);

      // props.setIsCustomContentComponentOpen(false);
      setName("");
      setPostIds("");
      setErrorMsg("");
      setDefaultQuery("");
      setIsChange(false);
    } else {
      setErrorMsg("Category with this name already exist.");
      setConfirmLoading(false);
    }
    props.refetch();
  };

  const handleCancel = () => {
    setInitial((prev: any) => !prev);

    props.setIsContentEditModalOpen(false);
    props.setSelectedComponent("");
    props.refetch();
    setName("");
    setPostIds("");
    setErrorMsg("");
    setDefaultQuery("");
  };

  function handlePostIds(post_ids: any) {
    setPostIds(post_ids);
  }
  return (
    <>
      <Modal
        open={isContentEditModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Update"
        confirmLoading={confirmLoading}
        okButtonProps={{ disabled: !name }}
      >
        <div>
          <h5>Editing {name}</h5>
          <label className="bold-label">Carousel Title</label>
          <Input
            placeholder="Enter Title"
            value={name}
            onChange={(e: any) => {
              handleInputChange(e.target.value, setName);
              setErrorMsg("");
            }}
            className="mb-20"
          />
          {errorMsg && <p className="errorMsg">{errorMsg}</p>}
          <EditFeedCheckboxComponent
            username={username}
            user_id={user_id}
            handlePostIds={handlePostIds}
            // className="ant-checkbox-wrapper"
            post_ids={component?.post_ids}
            componentName={name}
            type={type}
            defaultQuery={defaultQuery}
            setDefaultQuery={setDefaultQuery}
            showTag={component?.type === "query-content" ? true : false}
            refetchDashboard={refetch}
            setIsChange={setIsChange}
            isChange={isChange}
            initial={initial}
            setInitial={setInitial}
          />
        </div>
        {/* <p>Your component will be ready within 24 hours!</p> */}
      </Modal>
    </>
  );
};

export default EditCustomContent;
