import { Button, Switch, Modal } from "antd";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { BsInfoCircle } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";

import { useEffect, useState } from "react";
import { useAuth } from "../../../../../hooks/useAuth";
import { useLoadControls } from "../../../../../Utils/customHooks/useLoadControls";
import { useLoadCustomCategories } from "../../../../../Utils/customHooks/useLoadCustomCategories";
import { useLoadTopHashtags } from "../../../../../Utils/customHooks/useLoadTopHashtags";
import { useLoadPopularSearches } from "../../../../../Utils/customHooks/useLoadPopularSearches";
import {
  updateBrandColor,
  updateLiveAdsToggle,
  updateLiveBrowserCategoriesToggle,
  updateLiveCaptionToggle,
  updateLiveCommentsToggle,
  updateLiveCustomCategoriesToggle,
  updateLiveFilterToggle,
  updateLiveHighlightsToggle,
  updateLiveLinksToggle,
  updateLivePopularSearchesToggle,
  updateLiveShoppableLinksToggle,
  updateLiveSocialLinksToggle,
  updateLiveThisWeekStoriesToggle,
  updateLiveTopHashtagsToggle,
  updateLiveTrendingCategoriesToggle,
  updateLiveYesterdayStoriesToggle,
} from "../../../../../Utils/SupabaseServices/ControlsDatabase";
import ControlsStagingSkeleton from "../../../../../components/skeleton/DashboardView/controlsStagingSkeleton";
import ContentTypeRadio from "../../ContentTypeRadio";
import ContentOrderRadio from "../../ContentOrderRadio";
import ColorPicker from "react-pick-color";
import { ColorModal } from "../../../../../components/ColorModal/ColorModal";

const GeneralControls = (props: any) => {
  const { showSocials, myLinks, subscription_type, username, user_id, refetch } = props;
  const [categoryAdded, setCategoryAdded] = useState();

  const page = "follower";
  const navigate = useNavigate();
  const {
    isLoading: controlsLoading,
    data: controls,
    refetch: refetchControls,
  }: any = useLoadControls(user_id || "", page);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    isLoading,
    data,
    refetch: refetchCustomCategories,
  } = useLoadCustomCategories(user_id, page);
  const maxPopularSearches = controls?.maxPopularSearches;
  const maxHashtags = controls?.maxHashtags;
  //   const { isLoading: topHashtagsLoading, data: topHashtags }: any =
  //     useLoadTopHashtags([user_id], maxHashtags);
  //   const { isLoading: popularSearchesLoading, data: popularSearches }: any =
  //     useLoadPopularSearches([user_id], username, maxPopularSearches);

  const toggleCaption = async (checked: boolean) => {
    const toggle = checked;

    updateLiveCaptionToggle(user_id, toggle).then(() => {
      refetchControls();
    });
  };
  const toggleSocialLinks = async (checked: boolean) => {
    const toggle = checked;

    updateLiveSocialLinksToggle(user_id, toggle).then(() => {
      refetchControls();
    });
  };
  //   const toggleFilters = async (checked: boolean) => {
  //     const toggle = checked;

  //     updateLiveFilterToggle(user_id, toggle).then(() => {
  //       refetchControls();
  //     });
  //   };

  //   const togglePopularSearches = async (checked: boolean) => {
  //     const toggle = checked;
  //     updateLivePopularSearchesToggle(user_id, toggle).then(() => {
  //       refetchControls();
  //     });
  //   };

  //   const toggleTopHashtags = async (checked: boolean) => {
  //     const toggle = checked;
  //     updateLiveTopHashtagsToggle(user_id, toggle).then(() => {
  //       refetchControls();
  //     });
  //   };
  const toggleAds = async (checked: boolean) => {
    const toggle = checked;

    updateLiveAdsToggle(user_id, toggle).then(() => {
      refetchControls();
    });
  };
  //   const toggleComments = async (checked: boolean) => {
  //     const toggle = checked;
  //     updateLiveCommentsToggle(user_id, toggle).then(() => {
  //       refetchControls();
  //     });
  //   };
  const toggleBrowserCatogories = async (checked: boolean) => {
    const toggle = checked;
    updateLiveBrowserCategoriesToggle(user_id, toggle).then(() => {
      refetchControls();
    });
  };
  const toggleYesterdayStories = async (checked: boolean) => {
    const toggle = checked;
    updateLiveYesterdayStoriesToggle(user_id, toggle).then(() => {
      refetchControls();
    });
  };
  const toggleThisWeekStories = async (checked: boolean) => {
    const toggle = checked;
    updateLiveThisWeekStoriesToggle(user_id, toggle).then(() => {
      refetchControls();
    });
  };

  //   const toggleTrendingCategories = async (checked: boolean) => {
  //     const toggle = checked;
  //     updateLiveTrendingCategoriesToggle(user_id, toggle).then(() => {
  //       refetchControls();
  //     });
  //   };

  const { defaultColor, defaultTextColor } = props;
  const [localColor, setLocalColor] = useState(defaultColor);
  const [localTextColor, setLocalTextColor] = useState(defaultTextColor);
  const [color, setColor] = useState(defaultColor);
  const [textColor, setTextColor] = useState(defaultTextColor);

  const handleColor = (bgColor: string, textColor: string) => {
    setColor(bgColor);
    setTextColor(textColor)
  };
  const [isColorModalOpen, setIsColorModalOpen] = useState(false);

  const showModal = () => {
    setIsColorModalOpen(true);
  };


  const PreviewBtn = () => {
    return (
      <button
        className="push-btn"
        onClick={() =>
          navigate(`/follower/${username}`, { state: { from: "dashboard" } })
        }
      >
        Preview Live Page
      </button>
    );
  };
  const handleCategoryAdded = (category: any) => {
    setCategoryAdded(category);
  };




  return (
    <div>
      {controlsLoading ? (
        <ControlsStagingSkeleton />
      ) : (
        <>
          <div className="controls-container">
            {/* <p className="section-heading"> General Content </p> */}

            <div className="row">
              <div className="controls">
                <div className="dashboard-grid-items-grid">
                  <div className="dashboard-grid-item">
                    <p>
                      Landing Page Content{" "}
                      <BsInfoCircle
                        className="info-tooltip"
                        data-tooltip-id="initialContent"
                      />
                    </p>
                    <ContentTypeRadio
                      username={username}
                      user_id={user_id}
                      page={page}
                      checkedType={controls.checkedType}
                    />
                    {/* <Switch
                    style={{
                      backgroundColor: controls?.showCaption
                        ? "green"
                        : "rgb(241, 98, 98)",
                    }}
                    checked={controls?.showCaption}
                    onChange={toggleCaption}
                  /> */}
                  </div>

                  <div className="dashboard-grid-item">
                    <p>
                      Show Captions{" "}
                      <BsInfoCircle
                        className="info-tooltip"
                        data-tooltip-id="showCaption"
                      />
                    </p>
                    <Switch
                      style={{
                        backgroundColor: controls?.showCaption
                          ? "green"
                          : "rgb(241, 98, 98)",
                      }}
                      checked={controls?.showCaption}
                      onChange={toggleCaption}
                    />
                  </div>

                  {subscription_type !== "basic" && (
                    <div className="dashboard-grid-item">
                      <p>
                        Show Ads{" "}
                        <BsInfoCircle
                          className="info-tooltip"
                          data-tooltip-id="showAds"
                        />
                      </p>
                      <Switch
                        style={{
                          backgroundColor: controls?.showAds
                            ? "green"
                            : "rgb(241, 98, 98)",
                        }}
                        checked={controls?.showAds}
                        onChange={toggleAds}
                      />
                    </div>
                  )}
                  <div className="dashboard-grid-item">
                    <p>
                      Show Social Links{" "}
                      <BsInfoCircle
                        className="info-tooltip"
                        data-tooltip-id="showSocialLinks"
                      />
                    </p>
                    <Switch
                      style={{
                        backgroundColor: controls?.showSocialLinks
                          ? "green"
                          : "rgb(241, 98, 98)",
                      }}
                      checked={controls?.showSocialLinks}
                      onChange={toggleSocialLinks}
                    />
                  </div>
                  {/* <div className="dashboard-grid-item">
                    <p>
                      Show Comments{" "}
                      <BsInfoCircle
                        className="info-tooltip"
                        data-tooltip-id="showComments"
                      />
                    </p>
                    <Switch
                      style={{
                        backgroundColor: controls?.showComments
                          ? "green"
                          : "rgb(241, 98, 98)",
                      }}
                      checked={controls?.showComments}
                      onChange={toggleComments}
                    />
                  </div> */}
                  <div className="dashboard-grid-item">
                    <p>
                      Show Categories{" "}
                      <BsInfoCircle
                        className="info-tooltip"
                        data-tooltip-id="showCategories"
                      />
                    </p>
                    <Switch
                      style={{
                        backgroundColor: controls?.showBrowserCategories
                          ? "green"
                          : "rgb(241, 98, 98)",
                      }}
                      checked={controls?.showBrowserCategories}
                      onChange={toggleBrowserCatogories}
                    />
                  </div>
                  <div className="dashboard-grid-item">
                    <p>
                      Select brand color for stay up to date page{" "}
                      <BsInfoCircle
                        className="info-tooltip"
                        data-tooltip-id="showCategories"
                      />
                    </p>
                    <Button
                      style={{ backgroundColor: `${color}`, color: `${textColor}` }}
                      onClick={showModal}
                    >{color}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <p className="section-heading"> Time Based Content </p>
            <div className="row">
              <div className="controls">
                <div className="dashboard-grid-items-grid">
                  <div className="dashboard-grid-item">
                    <p>
                      Show Yesterday's Content{" "}
                      <BsInfoCircle
                        className="info-tooltip"
                        data-tooltip-id="showYesterdayStories"
                      />
                    </p>
                    <Switch
                      style={{
                        backgroundColor: controls?.showYesterdayStories
                          ? "green"
                          : "rgb(241, 98, 98)",
                      }}
                      checked={controls?.showYesterdayStories}
                      onChange={toggleYesterdayStories}
                    />
                  </div>

                  <div className="dashboard-grid-item">
                    <p>
                      Show Last 7 Day's Content{" "}
                      <BsInfoCircle
                        className="info-tooltip"
                        data-tooltip-id="showThisWeekStories"
                      />
                    </p>
                    <Switch
                      style={{
                        backgroundColor: controls?.showThisWeekStories
                          ? "green"
                          : "rgb(241, 98, 98)",
                      }}
                      checked={controls?.showThisWeekStories}
                      onChange={toggleThisWeekStories}
                    />
                  </div>
                </div>
              </div>
            </div>

            <p className="section-heading"> Search Content </p>
            <div className="row">
              <div className="controls">
                <div className="dashboard-grid-items-grid">
                  <div className="dashboard-grid-item">
                    <p>
                      Search Content Order{" "}
                      <BsInfoCircle
                        className="info-tooltip"
                        data-tooltip-id="contentOrder"
                      />
                    </p>
                    <ContentOrderRadio
                      username={username}
                      user_id={user_id}
                      page={page}
                      checkedType={controls.contentOrder}
                    />
                    {/* <Switch
                    style={{
                      backgroundColor: controls?.showCaption
                        ? "green"
                        : "rgb(241, 98, 98)",
                    }}
                    checked={controls?.showCaption}
                    onChange={toggleCaption}
                  /> */}
                  </div>
                </div>
              </div>
            </div>
            <Tooltip
              className="tool-tip"
              id="showTopHashtags"
              place={"top"}
              offset={4}
              html="Controls whether or not Top Hashtags are shown to followers."
            />
            <Tooltip
              className="tool-tip"
              id="topHashtagsRange"
              place={"top"}
              offset={4}
              html="Sets the number of Top Hashtags shown to followers."
            />
            <Tooltip
              className="tool-tip"
              id="showPopularSearches"
              place={"top"}
              offset={4}
              html="Controls whether or not Popular Searches are shown to followers."
            />
            <Tooltip
              className="tool-tip"
              id="popularSearchesRange"
              place={"top"}
              offset={4}
              html="Sets the number of Popular Searches shown to followers."
            />

            <Tooltip
              className="tool-tip"
              id="showCaption"
              place={"top"}
              offset={4}
              html="Controls whether or not post captions are shown to followers."
            />
            <Tooltip
              className="tool-tip"
              id="initialContent"
              place={"top"}
              offset={4}
              html="Controls the type of landing content is shown to followers."
            />
            <Tooltip
              className="tool-tip"
              id="showComments"
              place={"top"}
              offset={4}
              html="Controls whether or not post Comments are shown to followers."
            />
            <Tooltip
              className="tool-tip"
              id="showFilters"
              place={"top"}
              offset={4}
              html="Controls whether or not Filters are shown to followers."
            />
            <Tooltip
              className="tool-tip"
              id="showAds"
              place={"top"}
              offset={4}
              html="Controls whether or not Ads are shown to followers."
            />
            <Tooltip
              className="tool-tip"
              id="showComments"
              place={"top"}
              offset={4}
              html="Controls whether or not Comments are shown to followers."
            />
            <Tooltip
              className="tool-tip"
              id="showCustomCategories"
              place={"top"}
              offset={4}
              html="Controls whether or not Custom Categories are shown to followers."
            />
            <Tooltip
              className="tool-tip"
              id="showTrendingCategories"
              place={"top"}
              offset={4}
              html="Controls whether or not Trending Categories are shown to followers."
            />
            <Tooltip
              className="tool-tip"
              id="showHighlights"
              place={"top"}
              offset={4}
              html="Controls whether or not Highlights are shown to followers."
            />
            <Tooltip
              className="tool-tip"
              id="showLinks"
              place={"top"}
              offset={4}
              html="Controls whether or not My Links are shown to followers."
            />
            <Tooltip
              className="tool-tip"
              id="showCategories"
              place={"top"}
              offset={4}
              html="Controls whether or not Categories are shown to followers."
            />
            <Tooltip
              className="tool-tip"
              id="showYesterdayStories"
              place={"top"}
              offset={4}
              html="Controls whether or not Yesterday Stories are shown to followers."
            />
            <Tooltip
              className="tool-tip"
              id="showThisWeekStories"
              place={"top"}
              offset={4}
              html="Controls whether or not This Week Stories are shown to followers."
            />
            <Tooltip
              className="tool-tip"
              id="showShoppableLinks"
              place={"top"}
              offset={4}
              html="Controls whether or not shoppable links are shown to followers."
            />
            <Tooltip
              className="tool-tip"
              id="contentOrder"
              place={"top"}
              offset={4}
              html="Control the order of search content shown to followers."
            />
            <div className="push-btn-div">
              <PreviewBtn />
            </div>
          </div>
          <ColorModal
            username={username}
            fromScreen="generalControls"
            ModalTitle="Brand Color"
            user_id={user_id}
            handleColor={handleColor}
            isColorModalOpen={isColorModalOpen}
            setIsColorModalOpen={setIsColorModalOpen}
            defaultColor={localColor}
            defaultTextColor={localTextColor}
            refetch={refetch}
            initialColor={defaultColor}
            initialTextColor={defaultTextColor}
            setLocalDefaultColor={setLocalColor}
            setLocalDefaultTextColor={setLocalTextColor}
            
          />
        </>
      )}
    </div>
  );
};

export default GeneralControls;
