import { BsFillPlayFill, BsLink45Deg, BsPinterest } from "react-icons/bs";
import { Post } from "../../FeedPost";
import track, {
  TRACKING_TYPES,
} from "../../../../Utils/SupabaseServices/Track";
import { useLocation } from "react-router-dom";
import "./PinterestPost.css";
import Caption from "../Caption";
import { useState } from "react";
import { Button, Modal } from "antd";
import React from "react";
import { MdOutlinePodcasts } from "react-icons/md";
import "../CommentsPost/CommentsPost.css"

export type PinterestPostType = Post & {
  platform: "pinterest";
  metadata: {
    description: string;
  };
};

export default function PinterestPost({
  post,
  showCaption,
  shoppableContent,
  user_id
}: {
  post: PinterestPostType;
  showCaption: any;
  isGlobalView: any;
  shoppableContent: any;
  user_id:any
}) {
  const location = useLocation();
  const { showAsAd, post_date, post_id, permalink } = post;
  function handleRedirect(postId: string) {
    track("Redirect to original post", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: postId,
    });
  }



  return (
    <div>
      <div className="pinterest-post-img-wrp">
        <img
          src={post.media_url}
          alt={post.caption}
          className="pinterest-post-img bg-f1"
        />
      </div>
      <div className="creator-feed-content-info">
        {!showAsAd ? (
          <>
            <p className="creator-feed-content-date">
              {new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                day: "numeric",
                month: "long",
              }).format(new Date(post_date))}
            </p>
            <div className="creator-feed-post-links">
              {permalink ? (
                <a href={permalink} target="_blank" rel="noreferrer">
                  <BsLink45Deg
                    className="bs-link"
                    onMouseEnter={(e: any) => {
                      e.target.style.color = "#888";
                    }}
                    onMouseLeave={(e: any) => {
                      e.target.style.color = "#000";
                    }}
                    onClick={() => handleRedirect(post_id)}
                  />
                </a>
              ) : (
                ""
              )}
              {permalink ? (
                <a href={permalink} target="_blank" rel="noreferrer">
                  <BsPinterest
                    className="bs-link"
                    id="pinterest-icon"
                    onMouseEnter={(e: any) => {
                      e.target.style.color = "#888";
                    }}
                    onMouseLeave={(e: any) => {
                      e.target.style.color = "#000";
                    }}
                    onClick={() => handleRedirect(post_id)}
                  />
                </a>
              ) : (
                <BsPinterest
                  className="ml-6"
                  id="pinterest-icon"
                />
              )}
            </div>
          </>
        ) : (
          <>
            <p className="creator-feed-ad-indicator">Sponsored</p>
            <div className="creator-feed-post-links">
              {permalink ? (
                <a href={permalink} target="_blank" rel="noreferrer">
                  <BsLink45Deg
                    className="bs-link"
                    onMouseEnter={(e: any) => {
                      e.target.style.color = "#888";
                    }}
                    onMouseLeave={(e: any) => {
                      e.target.style.color = "#000";
                    }}
                    onClick={() => handleRedirect(post_id)}
                  />
                </a>
              ) : (
                ""
              )}

              {permalink ? (
                <a href={permalink} target="_blank" rel="noreferrer">
                  <BsPinterest
                    className="bs-link"
                    id="pinterest-icon"
                    onMouseEnter={(e: any) => {
                      e.target.style.color = "#888";
                    }}
                    onMouseLeave={(e: any) => {
                      e.target.style.color = "#000";
                    }}
                    onClick={() => handleRedirect(post_id)}
                  />
                </a>
              ) : (
                <BsPinterest
                  className="ml-6"
                  id="pinterest-icon"
                />
              )}
            </div>
          </>
        )}
      </div>
      {post?.caption && <Caption caption={post.caption} />}
    </div>
  );
}
