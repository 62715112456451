import React, { useState } from "react";
import "../SubComponents.css";
import { useLocation } from "react-router-dom";

import track, {
  TRACKING_TYPES,
} from "../../../../../Utils/SupabaseServices/Track";
import ImagePostCarouselTag from "../../../../../components/GeneralPostTag/ImagePostCarouselTag";
import VideoPostTag from "../../../../../components/GeneralPostTag/VideoPostTag";
import ImagePostTag from "../../../../../components/GeneralPostTag/ImagePostTag";
import { useLoadQueryFeed } from "../../../../../Utils/customHooks/UpToDateContent/useLoadQueryFeed";
import { StaticVariables } from "../../../../../Utils/StaticVariables";
import DataSkeleton from "../../../../../components/StayUpToDateComponent/UpToDateComponents/DataSkeleton";
import { useDomLoaded } from "../../../../../hooks/DomLoadedContext";
const QueryLinksWrapper = (props: any) => {
  const {
    user_id,
    username,
    numberOfDays,
    title,
    isLinks,
    // handleShop,
    postIds,
    brand_color,
    query,
    removedPostIds,
  } = props;
  const location = useLocation();
  const domLoaded = useDomLoaded();
  const POSTS_PER_PAGE = 10;
  const isShopLinks = true;
  const showSocials = StaticVariables().PLATFORMS;
  const contentOrder = "Default";

  const {
    isLoading,
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  }: any = useLoadQueryFeed(
    username,
    user_id,
    query,

    showSocials,
    contentOrder,
    isShopLinks,
    removedPostIds,
    title
  );

  function handleShop(postId: string, media_url: string) {
    track("Redirect to shop link", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: { post_id: postId, media_url: media_url },
      user_id: user_id,
      title: "Stay Up To Date",
    });
    // updateRefClick(postId, user_id);
  }

  return (
    <>
      <div>
        {isLoading || !domLoaded ? (
          <div className="outer-container-subcomponents">
            <DataSkeleton dashboard={true}/>{" "}
          </div>
        ) : (
          <div className="outer-container-subcomponents">
            {data && data.length ? (
              <div className="links-grid-container-subcomponents">
                {data &&
                  data.map((link: any, index: any) => (
                    <React.Fragment key={index}>
                      {link.media_url ? (
                        link.media_url.split(",").length > 1 &&
                        link.media_url
                          .split(",")
                          .every((url: any) => url.trim() !== "") ? (
                          <>
                            <ImagePostCarouselTag
                              post={link}
                              handleRedirect={handleShop}
                              title={title}
                              brand_color={brand_color}
                              isLinks={true}
                              isDashboard={true}
                            />
                          </>
                        ) : (
                          <>
                            {/* {link.media_url.includes("video") ? (
                              <>
                                <VideoPostTag
                                  post={link}
                                  handleRedirect={handleShop}
                                  title={title}
                                  brand_color={brand_color}
                                  isLinks={true}
                                  isDashboard={true}
                                />
                              </>
                            ) : (
                              <>
                                <ImagePostTag
                                  data={link}
                                  handleRedirect={handleShop}
                                  title={title}
                                  brand_color={brand_color}
                                  isLinks={true}
                                  isDashboard={true}
                                />
                              </>
                            )} */}
                            {link.product_image && link.product_image !== "" ? (
                              <>
                                <ImagePostTag
                                  data={link}
                                  handleRedirect={handleShop}
                                  title={title}
                                  brand_color={brand_color}
                                  isLinks={true}
                                  isDashboard={true}
                                />
                              </>
                            ) : (
                              <>
                                {link.media_url.includes("video") ? (
                                  <>
                                    <VideoPostTag
                                      post={link}
                                      handleRedirect={handleShop}
                                      title={title}
                                      brand_color={brand_color}
                                      isLinks={true}
                                      isDashboard={true}
                                      thumbnail={link.thumbnail}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <ImagePostTag
                                      data={link}
                                      handleRedirect={handleShop}
                                      title={title}
                                      brand_color={brand_color}
                                      isLinks={true}
                                      isDashboard={true}
                                    />
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )
                      ) : (
                        <>
                          <ImagePostTag
                            data={link}
                            handleRedirect={handleShop}
                            title={title}
                            brand_color={brand_color}
                            isLinks={true}
                            isDashboard={true}
                          />
                        </>
                      )}
                    </React.Fragment>
                  ))}
              </div>
            ) : (
              <div className="outer-container-subcomponents">
                <p>No content at this time</p>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default QueryLinksWrapper;
