import { useInfiniteQuery, useQuery } from "react-query";
import { supabase } from "../../../config/supabaseClient";
import { extractDomains } from "../../../Utils/HelperFunction/HelperFunction";

export function useLoadLinkFilter(
  user_id: string,
  query_string: string,
  filter: string
) {
  const fetchProductBankLinkFilter = async () => {
    const { data: linkFilter, error: linkFilterError } = await supabase.rpc(
      "get_link_filterr",
      {
        userid: user_id,
        filter: filter,
        query_string: query_string === "All" ? null : [query_string],
        query_tagg: null,
      }
    );
    if (linkFilterError) {
      throw new Error(linkFilterError.message);
    }
    const filteredUrl = extractDomains(linkFilter);

    const uniqueFilteredUrl =
      filteredUrl.length > 1
        ? [
          { key: "AllLinks", label: "All Links" },
          ...filteredUrl.map((platform: any) => ({
            key: platform,
            label: platform,
          })),
        ]
        : [];
    return uniqueFilteredUrl;
  };
  const { data, isLoading, isError, error, refetch } = useQuery(
    ["productbankfiler", query_string, filter],
    fetchProductBankLinkFilter
  );

  return { data, isLoading, isError, error, refetch };
}
