import { Input, Modal } from "antd";
import { useEffect, useState } from "react";
import "../../StayUpToDateControls.css";
import { updateComponent } from "../../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import { BsGrid3X3GapFill } from "react-icons/bs";
import { FaBars } from "react-icons/fa";

const EditMyLinks = (props: any) => {
  const {
    username,
    user_id,
    isMyLinkEditModalOpen,
    setIsMyLinkEditModalOpen,
    type,
    component,
    refetch,
  } = props;

  const [name, setName] = useState("");
  const [view, setView] = useState("")
  useEffect(() => {
    if (component && component.name) {
      setName(component.name);
    }
    if (component && component.details) {
      setView(component.details)
    }
  }, [component]);

  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // State to manage button disable state
  const [postIds, setPostIds] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [defaultQuery, setDefaultQuery] = useState("");
  const [removePostIds, setRemovePostIds] = useState<any>("");

  const handleInputChange = (value: string,) => {
    setName(value); // Set the input value
    setIsButtonDisabled(!value); // Disable button if either name or details is empty
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    const res = await updateComponent(
      name,
      component,
      username,
      user_id,
      defaultQuery,
      component?.type,
      "",
      view,
      removePostIds
    );
    if (res === "true") {
      // props.refetch();
      refetch();
      setIsMyLinkEditModalOpen(false);
      setConfirmLoading(false);
      setView("grid")
    } else {
      setErrorMsg("Category with this name already exists");
      setConfirmLoading(false);
      setView("grid")
    }
  };

  const handleCancel = () => {
    setIsMyLinkEditModalOpen(false);
  };



  return (
    <>
      <Modal
        open={isMyLinkEditModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Update"
        confirmLoading={confirmLoading}
        okButtonProps={{ disabled: !name }}
      >
        <div className="mt-1">
          <h5 className="editTitle">Editing {name}</h5>
          <label className="bold-label">Carousel Title</label>
          <Input
            placeholder="Enter Carousel Title"
            value={name}
            onChange={(e: any) => {
              handleInputChange(e.target.value);
              setErrorMsg("");
            }}
            className="mb-15"
          />
          {errorMsg && <p className="errorMsg">{errorMsg}</p>}
          <h5 className="bold-label text-center">View</h5>
          <div className="link-presentation">
            <span className={view == "grid" ? "active" : ""} onClick={() => setView("grid")}> <BsGrid3X3GapFill className="links-view-icon" /> </span>
            {/* <HiBars4 /> */}
            <span className={view == "line" ? "active" : ""} onClick={() => setView("line")}> <FaBars className="links-view-icon" /> </span>
          </div>
        </div>
      </Modal>
    </>
  );
};


export default EditMyLinks;
