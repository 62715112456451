import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Button, Carousel, Input, Pagination, Tag } from "antd";
import "../../../../../components/CheckboxComponent/FeedCheckboxComponent.css";
import { StaticVariables } from "../../../../../Utils/StaticVariables";
import { useLoadSearchFeedCount } from "../../../../../Utils/customHooks/UpToDateContent/useLoadSearchFeedCount";
import { useLoadPostIdsData } from "../../../../../Utils/customHooks/UpToDateContent/useLoadPostIdsData";
import { useLoadSearchFeed } from "../../../../../Utils/customHooks/UpToDateContent/useLoadSearchFeed";
import SearchInput from "../../../../../components/SearchInput/SearchInput";
import Skeleton from "react-loading-skeleton";
import "../StayUpToDateComponentsWrapper.css";
import { supabase } from "../../../../../config/supabaseClient";
import { HiCheck, HiPencil } from "react-icons/hi";
import VideoComponent from "../../../../../components/VideoComponent/VideoComponent";
import _ from "lodash";
import { getPostTitle } from "../../../../../Utils/HelperFunction/HelperFunction";

const POSTS_PER_PAGE = 10;
const showSocials = StaticVariables().PLATFORMS;
const contentOrder = "Default";





const EditFeedCheckboxComponent = (props: any) => {
  const {
    username,
    user_id,
    isShopLinks,
    post_ids,
    componentName,
    isSearchbar,
    defaultQuery,
    setDefaultQuery,
    showTag,
    refetchDashboard,
    type,
    setIsChange,
    isChange,
    initial,
    setInitial,
  } = props;

  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [postIds, setPostIds] = useState<any>("");

  const { data: pages, refetch: refetchCount }: any = useLoadSearchFeedCount(
    username,
    user_id,
    query,
    POSTS_PER_PAGE,
    showSocials,
    isShopLinks
  );

  const {
    isLoading,
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  }: any = useLoadSearchFeed(
    currentPage,
    username,
    user_id,
    query,
    POSTS_PER_PAGE,
    pages?.totalPages || 0,
    pages?.feedCount,
    pages?.postIds,
    showSocials,
    contentOrder,
    isShopLinks
  );

  useEffect(() => {
    setPostIds(post_ids?.split(","));
  }, [post_ids, initial]);


  const {
    isLoading: PostIdFeedLoading,
    data: PostIdData,

    refetch: PostIdDataRefetch,
  }: any = useLoadPostIdsData(
    username,
    user_id,
    postIds,
    isChange
    // postIds && postIds.length?postIds?.reverse():postIds
  );

  function handleFeed(postIds: any) {
    setPostIds(postIds);
    props.handlePostIds(postIds);
  }
  const [tags, setTags] = useState<string[]>([query]);

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
    const elem: any = document.getElementById("search-feed");
    elem.value = "";
    setQuery("");
    refetch();
    refetchCount();
  };

  useEffect(() => {
    // setPostIds(post_ids?.split(","));
    setQuery(defaultQuery);
    if (defaultQuery === "") {
      refetch();
    }
  }, [defaultQuery]);

  return (
    <>
      <div className="margin-added-content">
        <AddedContentTable
          post_ids={postIds}
          username={username}
          user_id={user_id}
          setPostIds={setPostIds}
          handleFeed={handleFeed}
          componentName={componentName}
          type={type}
          refetch={refetch}
          refetchDashboard={refetchDashboard}
          PostIdData={PostIdData}
          PostIdDataRefetch={PostIdDataRefetch}
          setIsChange={setIsChange}
          initial={initial}
          setInitial={setInitial}
        />
        {isSearchbar !== false && (
          <SearchInput
            placeholder={
              isShopLinks ? "Search all shoppable content" : "Search content"
            }
            id="search-feed"
            value={query}
            onChange={(e: any) => {
              e.preventDefault();
              const elem: any = document.getElementById("search-feed");
              const searchVal = e.target.value;
              setQuery(e.target.value);
              setDefaultQuery(e.target.value);
              setCurrentPage(1);
            }}
            handleKeyUp={(e: any) => {
              e.preventDefault();
              if (e.keyCode === 13) {
                const elem: any = document.getElementById("search-feed");
                const searchVal = e.target.value;
                if (searchVal.length === 0) {
                  elem.blur();
                  setQuery("");
                  setDefaultQuery("");
                  return;
                }
                elem.blur();
                setQuery(searchVal);
                setDefaultQuery(searchVal);
                setCurrentPage(1);
              }
            }}
            isActiveFilter={Boolean(query)}
            onSearchClick={() => {
              const elem: any = document.getElementById("search-feed");
              const searchVal = elem.value;
              if (searchVal.length === 0) {
                return;
              }
              elem.blur();
              setQuery(searchVal);
              setDefaultQuery(searchVal);
              setCurrentPage(1);
            }}
            onClearClick={() => {
              const elem: any = document.getElementById("search-feed");
              elem.value = "";
              setQuery("");
              setDefaultQuery("");
              refetch();
              setCurrentPage(1);
              setPostIds(postIds);
            }}
          />
        )}
        {showTag && query && (
          <Tag
            closable
            className="tag-style"
            onClose={() => handleClose(query)}
          >
            <span onDoubleClick={(e) => {}}>{query}</span>
          </Tag>
        )}
      </div>

      <ContentTable
        username={username}
        user_id={user_id}
        data={data}
        handleFeed={handleFeed}
        postIds={postIds}
        isShopLinks={isShopLinks}
        initial={initial}
        setInitial={setInitial}
      />

      {pages?.feedCount > POSTS_PER_PAGE && (
        <Pagination
          className="pagination"
          current={currentPage}
          total={pages?.feedCount}
          size="small"
          showSizeChanger={false}
          showQuickJumper
          pageSize={POSTS_PER_PAGE}
          onChange={(page: number) => setCurrentPage(page)}
        />
      )}
    </>
  );
};

const AddedContentTable = (props: any) => {
  const {
    username,
    user_id,
    post_ids,
    componentName,
    refetchDashboard,
    type,
    refetch,
    PostIdDataRefetch,
    PostIdData,
    setIsChange,
    initial,
  } = props;
  const [postIds, setPostIds] = useState(post_ids);

  useEffect(() => {
    setPostIds(post_ids);
  }, [post_ids, initial]);

  const debouncedSetCheckedValues = _.debounce((updatedArray) => {
    props.setPostIds(updatedArray);
    setPostIds(updatedArray);
    props.handleFeed(updatedArray);
  }, 100);
  const onMinus = useCallback(
    (post_id: string) => {
      let updatedPostArray = postIds.filter(
        (postId: string) => postId !== post_id
      );
      debouncedSetCheckedValues(updatedPostArray);
    },
    [postIds, props]
  );

  const [value, setValue] = useState<string>();
  const [edit, setEdit] = useState(false);
  const [editIndex, setEditIndex] = useState("");

  const handleDone = async (post_id: string) => {
    const postData = PostIdData.find((post: any) => post.post_id === post_id);
    postData.product_name = value;
    if (type === "content"||type==="query-content") {
      const { data: user, error } = await supabase
        .from(`${username}`)
        .update({ content_title: value })
        .eq("post_id", post_id)
        .eq("user_id", user_id);
      const { data: newContent, error: newErroor } = await supabase
        .from("product_bank")
        .update({ content_title: value })
        .eq("product_id", post_id)
        .eq("user_id", user_id);
      postData.content_title = value;
    } else {
      const { data: user, error } = await supabase
        .from(`${username}`)
        .update({ shop_title: value })
        .eq("post_id", post_id)
        .eq("user_id", user_id);
      const { data: newContent, error: newErroor } = await supabase
        .from("product_bank")
        .update({ shop_title: value })
        .eq("product_id", post_id)
        .eq("user_id", user_id);
      postData.shop_title = value;
    }
    setIsChange(true);

    const { data: newContent, error: newErroor } = await supabase
      .from("content_new")
      .update({ product_name: value })
      .eq("post_id", post_id)
      .eq("user_id", user_id);

    refetch();
    PostIdDataRefetch();
    refetchDashboard();
    setEdit(false);
    setEditIndex("");
  };
  const SkeletonStatBox = () => {
    return (
      <div className="skeletonDiv">
        <Skeleton className="skeletonStyle" />
      </div>
    );
  };

  return (
    <>
      <div className="addedContent">
        <h5 className="addedContentTitle">{componentName}</h5>
        {PostIdData?.filter((element: any) => element !== undefined) ? (
          <div className="addedSvg">
            {PostIdData.filter((element: any) => element !== undefined)
              .length ? (
              PostIdData.filter((element: any) => element !== undefined).map(
                (post: any, index: any) => (
                  <React.Fragment key={index}>
                    <div
                      key={index}
                      className="feed-tab-post-container added-post"
                    >
                      <div
                        className="feed-tab-media-wrapper"
                        style={
                          post?.platform === "newsletter"
                            ? {
                                backgroundColor: "#efeded",
                                textAlign: "center",
                              }
                            : {}
                        }
                      >
                        <Button
                          onClick={() => onMinus(post.post_id)}
                          className="carousel-content-remove-btn"
                        >
                          Remove
                        </Button>

                        {post?.platform !== "newsletter" ? (
                          <>
                            {post?.media_url ? (
                              <>
                                {post?.media_url.split(",").length > 1 &&
                                post?.media_url
                                  .split(",")
                                  .every((url: any) => url.trim() !== "") ? (
                                  <Carousel
                                    dotPosition="bottom"
                                    className="feed-tab-media-carousel"
                                  >
                                    {post?.media_url
                                      .split(",")
                                      .map((media_url: any, index: any) => (
                                        <React.Fragment key={index}>
                                          {media_url.includes("video") ? (
                                            // <video
                                            //   style={
                                            //     {
                                            //       // maxHeight: "300px",
                                            //     }
                                            //   }
                                            //   className="feed-tab-media-check"
                                            //   autoPlay
                                            //   playsInline
                                            //   muted
                                            //   loop
                                            //   poster={
                                            //     post?.thumbnail &&
                                            //     post?.thumbnail.split(",")
                                            //       .length &&
                                            //     post?.thumbnail.split(",")[
                                            //       index
                                            //     ]
                                            //       ? post?.thumbnail.split(",")[
                                            //           index
                                            //         ]
                                            //       : "/assets/dummy_video.png"
                                            //   }
                                            // >
                                            //   <source src={media_url} />
                                            // </video>
                                            <div className="h-255">
                                              <VideoComponent
                                                media_url={media_url}
                                                thumbnail={
                                                  post.thumbnail &&
                                                  post.thumbnail.split(",")
                                                    .length &&
                                                  post.thumbnail.split(",")[
                                                    index
                                                  ]
                                                    ? post.thumbnail.split(",")[
                                                        index
                                                      ]
                                                    : "/assets/dummy_video.png"
                                                }
                                              />
                                            </div>
                                          ) : (
                                            <img
                                              className="feed-tab-media-check"
                                              src={media_url}
                                              alt="post"
                                            />
                                          )}
                                        </React.Fragment>
                                      ))}
                                  </Carousel>
                                ) : (
                                  <>
                                    {post.media_url.includes("video") ? (
                                      // <video
                                      //   style={{
                                      //     maxHeight: "300px",
                                      //     whiteSpace: "nowrap",
                                      //     overflowY: "scroll",
                                      //     borderRadius: "1rem",
                                      //   }}
                                      //   className="feed-tab-media-check"
                                      //   autoPlay
                                      //   playsInline
                                      //   muted
                                      //   loop
                                      //   poster={
                                      //     post.thumbnail
                                      //       ? post.thumbnail
                                      //       : "/assets/dummy_video.png"
                                      //   }
                                      // >
                                      //   <source src={post.media_url} />
                                      // </video>
                                      <div className="h-255">
                                        <VideoComponent
                                          media_url={post.media_url}
                                          thumbnail={post.thumbnail}
                                        />
                                      </div>
                                    ) : (
                                      <img
                                        className="feed-tab-media-check"
                                        src={
                                          post.media_url
                                            ? post.media_url
                                            : "/assets/dummy_video.png"
                                        }
                                        alt="post"
                                      />
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <img
                                className="feed-tab-media-check"
                                src={
                                  post?.thumbnail
                                    ? post?.thumbnail
                                    : "/assets/dummy_video.png"
                                }
                                alt="post"
                              />
                            )}
                          </>
                        ) : (
                          <div>
                            <h4 className="mt-7">
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                day: "numeric",
                                month: "long",
                              }).format(new Date(post?.post_date))}
                            </h4>
                            <h4> Newsletter</h4>
                          </div>
                        )}
                      </div>
                      {edit === true && editIndex === index ? (
                        <>
                          <Input
                            value={value}
                            onChange={(e: any) => setValue(e.target.value)}
                            className="w-80"
                          />{" "}
                          <HiCheck
                            onClick={() => {
                              handleDone(post.post_id);
                            }}
                          />
                        </>
                      ) : (
                        <p
                          style={{
                            textAlign:
                              post?.shop_title !== "" ||
                              post?.product_name !== "" ||
                              post?.content_title !== ""
                                ? "left"
                                : "center",
                          }}
                        >
                          {getPostTitle(post, type)}
                          <HiPencil
                            onClick={() => {
                              const nameValue = getPostTitle(post, type);
                              setValue(nameValue);
                              setEdit(true);
                              setEditIndex(index);
                            }}
                          />
                        </p>
                      )}
                    </div>
                  </React.Fragment>
                )
              )
            ) : (
              <React.Fragment>
                {/* <p>No Added Data</p> */}
                <>
                  <div
                    // key={index}
                    // className="feed-tab-post-container"
                    className="feed-tab-post"
                  >
                    {[...Array(4)].map((_, index) => (
                      <SkeletonStatBox key={index} />
                    ))}
                  </div>
                </>
              </React.Fragment>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

const ContentTable = (props: any) => {
  const { data, postIds, isShopLinks, initial, setInitial } = props;

  const [checkedValues, setCheckedValues] = useState<any>(postIds || []);
  useEffect(() => {
    setCheckedValues(postIds);
  }, [postIds, initial]);
  const debouncedSetCheckedValues = _.debounce((updatedArray) => {
    setCheckedValues(updatedArray);
    props.handleFeed(updatedArray);
  }, 100);
  const onAdd = useCallback(
    (post_id: string) => {
      if (Array.isArray(checkedValues)) {
        const updatedCheckedValues = [post_id, ...checkedValues];
        debouncedSetCheckedValues(updatedCheckedValues);
      }
    },
    [checkedValues, props]
  );
  // const onMinus = (post_id: string) => {

  //   let updatedPostArray = checkedValues.filter(
  //     (postId: any) => postId !== post_id
  //   );

  //   setCheckedValues(updatedPostArray);
  //   props.handleFeed(updatedPostArray);
  //   // setCheckedValues(updatedPostArray);
  // };

  const onMinus = useCallback(
    (post_id: string) => {
      // Filter out the post_id to remove it from the checkedValues array
      let updatedPostArray = checkedValues.filter(
        (postId: string) => postId !== post_id
      );
      debouncedSetCheckedValues(updatedPostArray);

      // Update state and pass to parent component's handleFeed
      // setCheckedValues(updatedPostArray);
      // props.handleFeed(updatedPostArray);
    },
    [checkedValues, props]
  );
  return (
    <>
      {data?.pages && data?.pages[0].length ? (
        <div className="feed-tab-posts-container">
          {data.pages.map((group: any, pageIndex: number) => (
            <Fragment key={pageIndex}>
              {group.map((image: any, index: number) => (
                <div key={index} className="feed-tab-post-container post-width">
                  <div
                    className="feed-tab-media-wrapper"
                    style={
                      image.platform === "newsletter"
                        ? {
                            backgroundColor: "#efeded",
                            textAlign: "center",
                          }
                        : {}
                    }
                  >
                    {Array.isArray(checkedValues) &&
                    checkedValues?.includes(image.post_id) &&
                    postIds?.includes(image.post_id) ? (
                      <Button
                        onClick={() => onMinus(image.post_id)}
                        className="carousel-content-remove-btn"
                      >
                        Remove
                      </Button>
                    ) : (
                      <Button
                        onClick={() => onAdd(image.post_id)}
                        className="carousel-content-add-btn"
                      >
                        Add
                      </Button>
                    )}

                    {image.platform !== "newsletter" ? (
                      <>
                        {image.media_url ? (
                          <>
                            {image.media_url.split(",").length &&
                            image.media_url
                              .split(",")
                              .every((url: any) => url.trim() !== "").length >
                              1 ? (
                              <Carousel
                                dotPosition="bottom"
                                className="feed-tab-media-carousel"
                              >
                                {image.media_url
                                  .split(",")
                                  .map((media_url: any, index: any) => (
                                    <React.Fragment key={index}>
                                      {media_url.includes("video") ? (
                                        // <video
                                        //   style={{
                                        //     maxHeight: "300px",
                                        //     whiteSpace: "nowrap",
                                        //     overflowY: "scroll",
                                        //     borderRadius: "1rem",
                                        //   }}
                                        //   className="feed-tab-media-check"
                                        //   autoPlay
                                        //   playsInline
                                        //   muted
                                        //   loop
                                        //   poster={
                                        //     image.thumbnail &&
                                        //     image.thumbnail.split(",").length &&
                                        //     image.thumbnail.split(",")[index]
                                        //       ? image.thumbnail.split(",")[
                                        //           index
                                        //         ]
                                        //       : "/assets/dummy_video.png"
                                        //   }
                                        // >
                                        //   <source src={media_url} />
                                        // </video>
                                        <div className="h-255">
                                          <VideoComponent
                                            media_url={media_url}
                                            thumbnail={
                                              image.thumbnail &&
                                              image.thumbnail.split(",")
                                                .length &&
                                              image.thumbnail.split(",")[index]
                                                ? image.thumbnail.split(",")[
                                                    index
                                                  ]
                                                : "/assets/dummy_video.png"
                                            }
                                          />
                                        </div>
                                      ) : (
                                        <img
                                          style={
                                            {
                                              // maxHeight: "300px",
                                            }
                                          }
                                          className="feed-tab-media-check"
                                          src={media_url}
                                          alt="post"
                                        />
                                      )}
                                    </React.Fragment>
                                  ))}
                              </Carousel>
                            ) : (
                              <>
                                {image.media_url.includes("video") ? (
                                  // <video
                                  //   style={{
                                  //     // maxHeight: "300px",
                                  //     whiteSpace: "nowrap",
                                  //     overflowY: "scroll",
                                  //     borderRadius: "1rem",
                                  //   }}
                                  //   className="feed-tab-media-check"
                                  //   autoPlay
                                  //   playsInline
                                  //   muted
                                  //   loop
                                  //   poster={
                                  //     image.thumbnail
                                  //       ? image.thumbnail
                                  //       : "/assets/dummy_video.png"
                                  //   }
                                  // >
                                  //   <source src={image.media_url} />
                                  // </video>
                                  <div className="h-255">
                                    <VideoComponent
                                      media_url={image.media_url}
                                      thumbnail={image.thumbnail}
                                    />
                                  </div>
                                ) : (
                                  <img
                                    className="feed-tab-media-check"
                                    src={
                                      image.media_url
                                        ? image.media_url
                                        : "/assets/dummy_video.png"
                                    }
                                    alt="post"
                                  />
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <img
                            className="feed-tab-media-check"
                            src={
                              image.thumbnail
                                ? image.thumbnail
                                : "/assets/dummy_video.png"
                            }
                            alt="post"
                          />
                        )}
                      </>
                    ) : (
                      <div>
                        <h4 className="mt-7">
                          {new Intl.DateTimeFormat("en-US", {
                            year: "numeric",
                            day: "numeric",
                            month: "long",
                          }).format(new Date(image.post_date))}
                        </h4>
                        <h4> Newsletter</h4>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </Fragment>
          ))}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default EditFeedCheckboxComponent;
