import { Button, Carousel } from "antd";
import React from "react";
import { Card } from "react-bootstrap";
import { BsHourglassSplit } from "react-icons/bs";
import ImagePostTag from "./ImagePostTag";
import VideoPostTag from "./VideoPostTag";
import { getPostTitle } from "../../Utils/HelperFunction/HelperFunction";
import { getThumbnail } from "../StayUpToDateComponent/StayUpToDate";

const ImagePostCarouselTag = (props: any) => {
  const {
    post,
    redirectVariable,
    isLinks,
    handleRedirect,
    title,
    brand_color,
    isDashboard,
    height,
    isYoutube,
    isHasMediaUrl, productName, type
  } = props;
  const mediaUrls = post.media_url
    .split(",")
    .filter((url: any) => url.trim() !== "");
  const onChange = (currentSlide: number) => { };
  const ImagePostTagCarousel = React.memo((props: any) => {
    const { media_url, isVideo, post, handleRedirect , productName} = props;

    const redirectUrl = !isLinks
    ? post?.permalink || post?.shop_link || post?.table_url
    : post?.shop_link || post?.table_url || post?.permalink;

    return (
      <>
        <a
          href={
            redirectUrl
            // !isLinks ? (post?.permalink ? post?.permalink : post?.table_url ? post?.table_url : post?.shop_link) :
            //   (post?.shop_link && post?.shop_link !== ""
            //     ? post?.shop_link
            //     : post?.permalink ? post?.permalink : post?.table_url)
          }
          target="_blank"
          onClick={(e) => {
            e.preventDefault();
            handleRedirect(
              post?.post_id,
              post?.media_url.includes("video")
                ? post?.product_image
                  ? post?.product_image
                  : post?.thumbnail
                : post?.product_image
                  ? post?.product_image
                  : post?.media_url,
              title,
              post?.product_name
            );
            if (
              (post?.shop_link && post?.shop_link !== "") ||
              (post?.permalink && post?.permalink !== "") || (post?.table_url && post?.table_url !== "")
            ) {
              window.open(
                redirectUrl,
                // post?.shop_link && post?.shop_link !== ""
                //   ? post?.shop_link
                //   : post.permalink ? post?.permalink : post?.table_url,
                "_blank"
              );
            }
          }}
        >
          {media_url === "" ? (
            <div className={isYoutube ? "creator-feed-card-video not-available youtube-feed-height" : isHasMediaUrl ? "creator-feed-card-video not-available media-feed-height" : "creator-feed-card-video not-available"}>
              <BsHourglassSplit
                className="hourglassSplit"
              />
              <p>
                Work in progress: This content will be available in a few
                minutes.
              </p>
            </div>
          ) : (
            <>
              <Card.Img
                variant="top"
                src={media_url}
                className={isYoutube ? "creator-feed-card-links-image youtube-feed-height" : isHasMediaUrl ? "creator-feed-card-video not-available media-feed-height" : "creator-feed-card-links-image"}
                loading="eager"
                style={{
                  backgroundColor: brand_color,
                  marginBottom: "2%",
                  height: height,
                }}
              />
              {type === "content" || type === "query-content" ? (
                productName ? (
                  <p
                    className="product-name"
                  >
                    {productName}
                    {/* {post?.product_name} */}
                  </p>
                ) : null
              ) : post?.shop_title ? (
                <p className="product-name">{post.shop_title}</p>
              ) : post?.product_name ? (
                <p className="product-name">{post.product_name}</p>
              ) : null}

              {isLinks && (
                <Button className={"template-shop-btn-links"}>Shop</Button>
              )}
            </>
          )}
        </a>
      </>
    );
  });


  return (
    <>
      {" "}
      <span>
        <div className={isYoutube ? "creator-feed-image-wrapper-links youtube-feed-wrapper" : isHasMediaUrl ? "creator-feed-image-wrapper-links media-feed-wrapper" : "creator-feed-image-wrapper-links"}>
          {mediaUrls.some((media_url: string) =>
            media_url.includes("video")
          ) ? (
            <Carousel afterChange={onChange} dotPosition="bottom">
              {mediaUrls.map((media_url: string, index: number) => (
                <div key={index}>
                  {media_url.includes("video") ? (
                    <div className={isYoutube ? "creator-feed-image-wrapper-links youtube-feed-wrapper" : isHasMediaUrl ? "creator-feed-image-wrapper-links media-feed-wrapper" : "creator-feed-image-wrapper-links"}>
                      <VideoPostTag
                        media_url={media_url}
                        productName={productName}
                        post={post}
                        thumbnail={getThumbnail(post, index)}

                        handleRedirect={handleRedirect}
                      />
                    </div>
                  ) : (
                    <div className={isYoutube ? "creator-feed-image-wrapper-links youtube-feed-wrapper" : isHasMediaUrl ? "creator-feed-image-wrapper-links media-feed-wrapper" : "creator-feed-image-wrapper-links"}>
                      <ImagePostTag
                        data={post}
                        title={title}
                        media_url={media_url}
                        isVideo={true}
                        handleRedirect={handleRedirect}
                        productName={productName}
                        isCarousel={true}
                      />
                    </div>
                  )}
                </div>
              ))}
            </Carousel>
          ) : (
            <Carousel afterChange={onChange} dotPosition="bottom">
              {mediaUrls.map((media_url: any, index: any) => (
                <div key={index} className={isYoutube ? "creator-feed-image-wrapper-links youtube-feed-wrapper" : isHasMediaUrl ? "creator-feed-image-wrapper-links media-feed-wrapper" : "creator-feed-image-wrapper-links"}>
                  <ImagePostTagCarousel
                    post={post}
                    media_url={media_url}
                    handleRedirect={handleRedirect}
                    productName = {productName}
                  />
                </div>
              ))}
            </Carousel>
          )}
        </div>
      </span>
    </>
  );
};

export default ImagePostCarouselTag;
