import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BannerSkeletonTemplateWrapper } from "../BannerSkeletonTemplateWrapper";

const NewsletterPopupWrapper = (props: any) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const { title, component, user_id, brand_color, cta, flowdeskId } = props;
  const location = useLocation();

  useEffect(() => {
    // Flodesk Script Initialization
    (function (w: any, d: any, t: any, h: any, s: any, n: any) {
      w.FlodeskObject = n;
      const fn = function () {
        (w[n].q = w[n].q || []).push(arguments);
      };
      w[n] = w[n] || fn;

      const f = d.getElementsByTagName(t)[0];
      const v = "?v=" + Math.floor(new Date().getTime() / (120 * 1000)) * 60;

      const sm = d.createElement(t);
      sm.async = true;
      sm.type = "module";
      sm.src = h + s + ".mjs" + v;
      f.parentNode.insertBefore(sm, f);

      const sn = d.createElement(t);
      sn.async = true;
      sn.noModule = true;
      sn.src = h + s + ".js" + v;
      f.parentNode.insertBefore(sn, f);
    })(
      window,
      document,
      "script",
      "https://assets.flodesk.com",
      "/universal",
      "fd"
    );

    // Flodesk form initialization
    // if (window.fd) {
    //   window.fd("form", {
    //     formId:flowdeskId,
    //     containerEl: `#fd-form-${flowdeskId}`,
    //     // formId: "66b547f8876ad814d05bcfc0",
    //     // containerEl: "#fd-form-66b547f8876ad814d05bcfc0",
    //   });
    // }
    const intervalId = setInterval(() => {
      if (window.fd && flowdeskId) {
        window.fd("form", {
          formId: flowdeskId,
          containerEl: `#fd-form-${flowdeskId}`,
        });
        setLoading(false);
        clearInterval(intervalId);
      }
    }, 100);
    return () => clearInterval(intervalId);

    // setLoading(false)
  }, [flowdeskId,loading]);

  return (
    <>
      <div className="newsletter-msg-div m-4 mb-0 text-left ">
        This Popup will be visible on the Live page.
      </div>
      {/* <div id={`fd-form-${flowdeskId}`}></div> */}

      {/* {loading || !flowdeskId ? (
        <BannerSkeletonTemplateWrapper />
      ) : (
        <div
          className="outter-section-banner-subcomponent"
          style={{
            backgroundColor: brand_color && `${brand_color}`,
          }}
        >
        </div>
      )} */}
    </>
  );
};

export default NewsletterPopupWrapper;
