import { useEffect, useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import AccountSkeleton from "../../../components/skeleton/DashboardView/accountSkeleton";
import { StaticVariables } from "../../../Utils/StaticVariables";
import "./Account.css";
import { useAuth } from "../../../hooks/useAuth";
import IntegrationsBox from "../../../components/IntegrationsBox/IntegrationsBox";
import AccountsIntegration from "../../../components/AccountsIntegration/AccountsIntegration";
import Username from "./AccountSection/Username";
import Name from "./AccountSection/Name";
import { useLoadUser } from "../../../Utils/customHooks/useLoadUser";
import Email from "./AccountSection/Email";
import Phone from "./AccountSection/Phone";
import AccountDescription from "../../../components/AccountDescription/AccountDescription";
import ProfilePicture from "./AccountSection/ProfilePicture";
import { Button } from "antd";

const Account = (props: any) => {
  const { user: sessionUser } = useAuth();
  const { username, setIsEmail } = props;
  const [isLinksModalOpen, setIsLinksModalOpen] = useState(false);

  const {
    isLoading: userLoading,
    data: userData,
    refetch,
  }: any = useLoadUser(username);

  return (
    <div>
      {userLoading ? (
        <AccountSkeleton />
      ) : (
        <>
          <div className="details">
            <h1 className="dashboard-mobile-hide">
              {StaticVariables().ACCOUNT_TITLE}
            </h1>
            <div className="account-values-grid">
              <Username username={username} sessionUser={sessionUser} />
              <Name
                username={username}
                userData={userData}
                setIsName={props.setIsName}
                user_id={sessionUser?.id}
              />

              <Email
                username={username}
                userData={userData}
                setIsEmail={props.setIsEmail}
                setIsEmailVerified={props.setIsEmailVerified}
                user_id={sessionUser?.id}
              />
              <Phone
                username={username}
                userData={userData}
                setIsPhone={props.setIsPhone}
                user_id={sessionUser?.id}
              />
              {/* <ProfilePicture
                username={username}
                userData={userData}
                setIsPhone={props.setIsPhone}
                user_id={sessionUser?.id}
              /> */}
              <div>
                <a
                  href="https://payments.searchbubble.com/p/login/14kg1ecrU8Lt8vK4gg"
                  target="_blank"
                  style={{textDecoration: 'none'}}
                >
                  {/* <button className="manage-subscription"> */}
                  <Button
                    className="saveButton"
                    onClick={() => { }}
                    id="save-phone"
                  >
                    Manage Subscription
                    {/* </button> */}
                  </Button>
                </a>
              </div>
            </div>
            <div className="account-values-profiles">
              <ProfilePicture username={username} userData={userData} refetch={refetch} setIsEmail={setIsEmail} />
            </div>

            <div className="account-values-profiles">
              <AccountDescription
                isLoading={userLoading}
                userData={userData}
                refetch={refetch}
              />
            </div>

            <div className="account-values-profiles">
              <div className="account-social-logins-heading">
                <h5>Social Integrations</h5>
              </div>
              <IntegrationsBox
                subscription_type={userData?.subscription_type}
                first_connected_platform={userData?.first_connected_platform}
              />
            </div>
          </div>
        </>
      )}
      <Tooltip
        className="tool-tip"
        id="displayName"
        place={"top"}
        offset={4}
        html="How your name will be shown on your page."
      />
      <Tooltip
        className="tool-tip"
        id="username"
        place={"top"}
        offset={4}
        html="This is your Bubble username."
      />
      <Tooltip
        className="tool-tip"
        id="email"
        place={"top"}
        offset={4}
        html="This is the email connected to your account."
      />
      <Tooltip
        className="tool-tip"
        id="phone"
        place={"top"}
        offset={4}
        html="This is the phone number connected to your account."
      />
    </div>
  );
};

export default Account;
