import { Button, Input, message, Upload, UploadProps } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useState } from "react";
import { supabase } from "../../../../../config/supabaseClient";
import { BsGrid3X3GapFill } from "react-icons/bs";
import { FaBars } from "react-icons/fa";
import { StaticCompnents } from "../../../../../Utils/StaticComponent";
import { debounce } from "lodash";
import { handleProductFileUpload } from "../../../../../Utils/uploadImage/UploadImage";

export const EditHighlightLinksComponents = (props: any) => {
  const {
    username,
    user_id,
    defaultName,
    link,
    cta,
    image,
    setIsButtonDisabled,
    view, setView, errorMsg
  } = props;
  const [name, setName] = useState("");
  const [shoplink, setShoplink] = useState("");
  const [shopImage, setShopImage] = useState("");
  const [shopCTA, setShopCTA] = useState("");
  const [fileList, setFileList] = useState<any[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [errorInputMsg, setErrorInputMsg] = useState({
    buttonErrorMsg: "",
    titleErrorMsg: "",
  });

  const {
    LeftViewIcon
  } = StaticCompnents();

  useEffect(() => {
    setName(defaultName);
    setShoplink(link);
    setShopCTA(cta);
    setShopImage(image);
  }, [defaultName, link, cta, image]);

  useEffect(() => {
    props.handleData(shopImage, name, shoplink, "link", shopCTA);
  }, [shopImage, name, shoplink, shopCTA]);

  // const handleInputChange = (value: any, setter: any) => {
  //   setter(value);
  //   setIsButtonDisabled(!value);
  // };

  const handleInputChange = (value: string, setter: React.Dispatch<React.SetStateAction<string>>, limit: number) => {
    if (limit && value.length <= limit) {
      limit === 15 ? setErrorInputMsg({ ...errorInputMsg, buttonErrorMsg: "" }) : setErrorInputMsg({ ...errorInputMsg, titleErrorMsg: "" })
      setter(value);
      limit === 15 ? props.setCTA(value) : props.setName(value)
    } else {
      limit === 15 ? setErrorInputMsg({
        ...errorInputMsg,
        buttonErrorMsg: `Button Text is limited to ${limit} characters.`
      }) : setErrorInputMsg({
        ...errorInputMsg,
        titleErrorMsg: `Highlight Title is limited to ${limit} characters.`
      })
    }
  };

  const debouncedUpload = useCallback(
    debounce(async (info) => {
      const { file } = info;

      // Prevent multiple uploads
      if (isUploading) {
        return;
      }

      // Set uploading flag
      setIsUploading(true);
      props.setErrorMsg("Please wait till the highlight upload");

      try {
        await handleProductFileUpload({
          info,
          username,
          productName: "",
          setFileList,
          setProductImage: setShopImage,
          storageName: "highlight_section"
        });
      } finally {
        // Reset uploading flag after completion
        setIsUploading(false);
        props.setErrorMsg("");
      }
    }, 300), // Debounce with 300ms delay
    [isUploading, username, supabase]
  );


  const uploadProps: UploadProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    accept: "image/*",
    async onChange(info) {
      setFileList([...info.fileList]);
      debouncedUpload(info);
    },
  }

  useEffect(() => {
    let buttonErrorTimer: NodeJS.Timeout;
    let titleErrorTimer: NodeJS.Timeout;

    if (errorInputMsg.buttonErrorMsg) {
      buttonErrorTimer = setTimeout(() => {
        setErrorInputMsg((prev) => ({ ...prev, buttonErrorMsg: "" }));
      }, 10000);
    }

    if (errorInputMsg.titleErrorMsg) {
      titleErrorTimer = setTimeout(() => {
        setErrorInputMsg((prev) => ({ ...prev, titleErrorMsg: "" }));
      }, 10000);
    }

    return () => {
      clearTimeout(buttonErrorTimer);
      clearTimeout(titleErrorTimer);
    };
  }, [errorInputMsg]);

  return (
    <div className="editModal">
      <div className="highlight-data-section edit-highlight" style={view === "right" ? { flexDirection: "row-reverse" } : {}}>
        <div className="highlight-media" style={view === "right" ? { marginLeft: "3%", marginRight: "0" } : { marginRight: "3%" }}>
          <img
            src={shopImage ? shopImage : "/assets/image-bg.jpeg"}
            className="highlight-image"
          />
        </div>

        <div className="highlight-details">
          <div className="highlight-name">{name ? name : "Highlight Name"}</div>
          <div className="highlight-link-tag">
            <Button className="highlight-shop-button">{shopCTA}</Button>
          </div>
        </div>
      </div>
      <div className="fieldGroup">
        <label className="bold-label">Highlight Title</label>
        <Input
          placeholder="Enter Title"
          id="edit-highlight-title"
          value={name}
          onChange={(e: any) => {
            handleInputChange(e.target.value, setName, 150);
            // props.setName(e.target.value);
            props.setErrorMsg("");
          }}
          className="mb-15"
        />
        {errorInputMsg && errorInputMsg.titleErrorMsg && <p className="errorMsg">{errorInputMsg.titleErrorMsg}</p>}
        <label className="bold-label">Button Text</label>
        <Input
          placeholder="Check it Out!"
          id="edit-highlight-button"
          value={shopCTA}
          onChange={(e: any) => {
            handleInputChange(e.target.value, setShopCTA, 15);
            // props.setCTA(e.target.value);
          }}
          className="mb-15"
        />
        {errorInputMsg && errorInputMsg.buttonErrorMsg && <p className="errorMsg">{errorInputMsg.buttonErrorMsg}</p>}
        <label className="bold-label">Button Link</label>
        <Input
          placeholder="https://links.com"
          value={shoplink}
          id="edit-highlight-link"
          onChange={(e: any) => {
            setShoplink(e.target.value)
            // handleInputChange(e.target.value, setShoplink);
            props.setLink(e.target.value);
          }}
          className="mb-15"
        />
        <label
          className="bold-label"
        >
          View
        </label>
        <div className="link-presentation-highlight mb-15">
          <span className={view == "left" ? "active" : ""} onClick={() => setView("left")}> <LeftViewIcon className="links-view-icon" /> </span>
          {/* <HiBars4 /> */}
          <span className={view == "right" ? "active right-view-icon" : "right-view-icon"} onClick={() => setView("right")}> <LeftViewIcon className="links-view-icon" /> </span>
        </div>

        <div className="image-upload-section">
          <Upload accept="image/*" {...uploadProps} multiple={false} maxCount={1} showUploadList={fileList.length === 0 ? false : {showRemoveIcon: false,}}>
            <Button
              disabled={!name}
              className="uploadButton"
              id="update-image"
            >
              <div className="text-center">
                <p className="textContainerHeading">
                  Click to select image or drag & drop to this area to upload
                </p>
                <p className="upload-text"> JPG, JPEG, PNG, HEIC, or WEBP</p>
              </div>
            </Button>
          </Upload>
        </div>
      </div>
      {errorMsg && <p className="errorMsg">{errorMsg}</p>}
    </div>
  );
};