import { Button, Input, Modal, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import { supabase } from "../../../config/supabaseClient";
import { BsFillTrashFill } from "react-icons/bs";

const ProductBankEditModal = (props: any) => {
  const { isModalOpen, setIsModalOpen, data, user_id, refetchData,linkFilterRefetch,dataCount,setSelectedProductLnikFilter } = props;
  const [name, setName] = useState("");
  const [url, setURL] = useState("");
  useEffect(() => {
    setName(data?.table_name);
    setURL(data?.table_url)
  }, [data]);

  const handleOk = async () => {
    const { data: upd } = await supabase
      .from("product_bank")
      .update({ name: name,url:url })
      .eq("product_id", data.table_id)
      .eq("user_id", user_id);
    refetchData();
    linkFilterRefetch()
    dataCount <=1 && setSelectedProductLnikFilter("AllLinks")
    setIsModalOpen(false);
  };
  async function deletePost(post_id: any) {
    const { data: upd } = await supabase
      .from("product_bank")
      .delete()
      .eq("product_id", post_id)
      .eq("user_id", user_id);
    refetchData();
    linkFilterRefetch()
    setIsModalOpen(false);
  }
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleInputChange = (value: any, setter: any) => {
    setter(value);
  };
  return (
    <>
      <Modal
        title="Edit Product"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ disabled: !name || !url}}
        okText="Update"
        className="edit-product-modal"
      >
        <label
          className="label-modal"
        >
          Product Name
        </label>
        <Input
          className="mb-4"
          value={name}
          placeholder="Enter product name here"
          onChange={(e: any) => {
            handleInputChange(e.target.value, setName);
          }}
        />
         <label
          className="label-modal"
        >
          Product URL
        </label>
        <Input
          className="mb-4"
          value={url}
          placeholder="Enter product url here"
          onChange={(e: any) => {
            handleInputChange(e.target.value, setURL);
          }}
        />
       
        <Popconfirm
          title="Are you sure you want to permanently delete this product?"
          onConfirm={() => deletePost(data?.table_id)}
          okText="Yes"
          cancelText="No"
          overlayStyle={{ maxWidth: "240px" }}
        >
          
        </Popconfirm>
      </Modal>
    </>
  );
};

export default ProductBankEditModal;
