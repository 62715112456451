import { BsInfoCircle } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import StatBox from "../../../../components/StatBox/StatBox";
import List from "../List/list";
import PopularSearchesTable from "../PopularSearchesTable/PopularSearchesTable";
import PostClicksTable from "../PostClicksTable/PostClicksTable";
import useTrendingAnalytics from "./useTrendingAnalytics";
import CustomCategoriesTable from "../CustomCategoriesTable/CustomCategoriesTable";

const Trending = ({
  startDate,
  endDate,
  customCategories,
  instagramLinks,
  instagramHighlights,
}: any) => {
  const {
    // loadingRecentSearches,
    // recentSearches,
    loadingPopularSearches,
    popularSearches,
    loadingPostClicks,
    postClicks,
    customCategoriesSearches,
    loadingCustomCategoriesSearches,
    instagramHighlightsSearches,
    loadinginstagramHighlightsSearches,
    instagramLinksSearches,
    loadinginstagramLinksSearches,
    loadingShoppableClicks,
    shoppableClicks,
  } = useTrendingAnalytics(
    startDate,
    endDate,
    customCategories,
    instagramHighlights,
    instagramLinks?.split(",").map((url: any) => url.split(":-")[0])
  );
  return (
    <div className="analytics">
      <div className="row">
        <div className="analytics-titles">
          {/* <StatBox
						isLoading={loadingRecentSearches}
						name='Recent Searches'
						icon={
							<BsInfoCircle
                                                  className="info-tooltip"

								data-tooltip-id='recentSearches'
								data-tooltip-content='These are the last 25 searches made on your page.'
							/>
						}
						component={<List recentSearches={recentSearches} />}
					/> */}
          <StatBox
            isLoading={loadingPopularSearches}
            name="Popular Searches"
            icon={
              <BsInfoCircle
                className="info-tooltip"
                data-tooltip-id="popularSearches"
                data-tooltip-content="These are the 25 most popular searches made on your page."
              />
            }
            component={
              <PopularSearchesTable popularSearches={popularSearches} />
            }
          />
           <StatBox
            isLoading={loadingShoppableClicks}
            name="Top Products & Shoppable Content"
            icon={
              <BsInfoCircle
                className="info-tooltip"
                data-tooltip-id="shopClicks"
                data-tooltip-content="These are the 25 most clicked on shoplinks from your page."
              />
            }
            component={<PostClicksTable postClicks={shoppableClicks} />}
          />
          <StatBox
            isLoading={loadingPostClicks}
            name="Top Viewed Content"
            icon={
              <BsInfoCircle
                className="info-tooltip"
                data-tooltip-id="postClicks"
                data-tooltip-content="These are the 25 most clicked on posts from your page."
              />
            }
            component={<PostClicksTable postClicks={postClicks} />}
          />
         
          {/* {!instagramHighlightsSearches ||
          instagramHighlightsSearches.length === 0 ? (
            <>
            
              {instagramHighlights &&
                instagramHighlights.length>0 &&
                // instagramHighlights.map((category: any) => 
                // (
                  <StatBox
                    isLoading={true}
                    name="Instagram Highlights"
                    icon={
                      <BsInfoCircle
                                                          className="info-tooltip"

                        data-tooltip-id="instagramHighlightsSearches"
                        data-tooltip-content="These are the 25 most instagram highlight searches made on your page."
                      />
                    }
                    // component={
                    // 	<instagramHighlightsTable searches={category.analytics}/>
                    // }
                  />
                // )
                // )
                }
            </>
          ) : (
            <> */}
          {
            instagramHighlightsSearches &&
              instagramHighlightsSearches.length > 0 && (
                // Object.keys(instagramHighlightsSearches).map((category: any) => (
                <StatBox
                  isLoading={loadinginstagramHighlightsSearches}
                  name="Instagram Highlights"
                  icon={
                    <BsInfoCircle
                      className="info-tooltip"
                      data-tooltip-id="instagramHighlightsSearches"
                      data-tooltip-content="These are the 25 most instagram highlight searches made on your page."
                    />
                  }
                  component={
                    <CustomCategoriesTable
                      searches={instagramHighlightsSearches}
                      from="highlights"
                    />
                  }
                />
              )
            // ))
          }
          {/* </>
          )} */}

          {!customCategoriesSearches ||
          customCategoriesSearches.length === 0 ? (
            <>
              {customCategories &&
                customCategories.length > 0 &&
                customCategories.map((category: any) => (
                  <StatBox
                    isLoading={true}
                    name={category.name}
                    icon={
                      <BsInfoCircle
                        className="info-tooltip"
                        data-tooltip-id="customCategorySearches"
                        data-tooltip-content="These are the 25 most custom category searches made on your page."
                      />
                    }
                    // component={
                    // 	<CustomCategoriesTable searches={category.analytics}/>
                    // }
                  />
                ))}
            </>
          ) : (
            <>
              {customCategoriesSearches &&
                customCategoriesSearches.length &&
                customCategoriesSearches.map((category: any) => (
                  <StatBox
                    isLoading={loadingCustomCategoriesSearches}
                    name={category.name}
                    icon={
                      <BsInfoCircle
                        className="info-tooltip"
                        data-tooltip-id="customCategorySearches"
                        data-tooltip-content="These are the 25 most custom category searches made on your page."
                      />
                    }
                    component={
                      <CustomCategoriesTable
                        searches={category.analytics}
                        from="categories"
                      />
                    }
                  />
                ))}
            </>
          )}


{
            instagramLinksSearches &&
              instagramLinksSearches.length > 0 && (
                // Object.keys(instagramLinksSearches).map((category: any) => (
                <StatBox
                  isLoading={loadinginstagramLinksSearches}
                  name="My Links"
                  icon={
                    <BsInfoCircle
                      className="info-tooltip"
                      data-tooltip-id="instagramLinksSearches"
                      data-tooltip-content="These are the 25 most instagram highlight searches made on your page."
                    />
                  }
                  component={
                    <CustomCategoriesTable
                      searches={instagramLinksSearches}
                      from="links"
                    />
                  }
                />
              )
            // ))
          }
        

          <Tooltip
            className="tool-tip"
            id="recentSearches"
            place={"top"}
            offset={4}
          />
          <Tooltip
            className="tool-tip"
            id="popularSearches"
            place={"top"}
            offset={4}
          />
          <Tooltip
            className="tool-tip"
            id="postClicks"
            place={"top"}
            offset={4}
          />
          <Tooltip
            className="tool-tip"
            id="customCategorySearches"
            place={"top"}
            offset={4}
          />
          <Tooltip
            className="tool-tip"
            id="instagramHighlightsSearches"
            place={"top"}
            offset={4}
          />
          <Tooltip
            className="tool-tip"
            id="instagramLinksSearches"
            place={"top"}
            offset={4}
          />
        </div>
      </div>
    </div>
  );
};

export default Trending;


  // {/* {!instagramLinksSearches || instagramLinksSearches.length ===0? (
  //           <>

  //             {instagramLinks &&
  //               instagramLinks.split(",").map((url: any) => url.split(":-")[0])
  //                 .length > 0 &&
  //               instagramLinks
  //                 .split(",")
  //                 .map((url: any) => url.split(":-")[0])
  //                 .map((category: any) => (
  //                   <StatBox
  //                     isLoading={true}
  //                     name={category.name}
  //                     icon={
  //                       <BsInfoCircle
  //                         className="info-tooltip"
  //                         data-tooltip-id="instagramLinksSearches"
  //                         data-tooltip-content="These are the 25 most instagram highlight searches made on your page."
  //                       />
  //                     }
  //                     // component={
  //                     // 	<instagramLinksTable searches={category.analytics}/>
  //                     // }
  //                   />
  //                 ))}
  //           </>
  //         ) : (
  //           <>
  //             {
  //               instagramLinksSearches && instagramLinksSearches.length && (
  //                 // Object.keys(instagramLinksSearches).map((category: any) => (
  //                 <StatBox
  //                   isLoading={loadinginstagramLinksSearches}
  //                   name="My Links"
  //                   icon={
  //                     <BsInfoCircle
  //                       className="info-tooltip"
  //                       data-tooltip-id="instagramLinksSearches"
  //                       data-tooltip-content="These are the 25 most instagram highlight searches made on your page."
  //                     />
  //                   }
  //                   component={
  //                     <CustomCategoriesTable
  //                       searches={instagramLinksSearches}
  //                       from="links"
  //                     />
  //                   }
  //                 />
  //               )
  //               // ))
  //             }
  //           </>
  //         )} */}