import { Button } from "antd";
import React from "react";
import { Card, Image } from "react-bootstrap";
import { BsHourglassSplit } from "react-icons/bs";
import "../StayUpToDateComponent/StayUpToDate.css";

const IndividualImagePostTag = React.memo((props: any) => {
  const {
    data,
    // handleRedirect,
    brand_color,
    title,
    redirectVariable,
    isLinks,
    isDashboard,
    type,
    isYoutube,
    isHasMediaUrl, productName
  } = props;
  function getMediaSrc(type: any, data: any) {
    if (type !== "content" && type !== "query-content") {
      if (type === "product-bank") {
        return data?.table_image ? data.table_image : data?.product_image;
      } else {
        return data?.media_url?.includes("video")
          ? data?.product_image
            ? data?.product_image
            : data?.thumbnail
          : data?.product_image
            ? data?.product_image
            : data?.media_url;
      }
    } else {
      return data?.media_url
        ? data?.media_url
        : data?.media_url.includes("video")
          ? data?.thumbnail
          : data?.table_image
            ? data.table_image
            : data?.product_image;
    }
  }

  return (
    <Card>
      <a
        href={
          data?.shop_link && data?.shop_link !== ""
            ? data?.shop_link
            : data?.permalink
              ? data?.permalink
              : data?.table_url
        }
        target="_blank"
        onClick={(e) => {
          e.preventDefault();
          props.handleRedirect(
            data?.post_id || data?.table_id,
            // data?.media_url.includes("video")
            //   ? data?.product_image
            //     ? data?.product_image
            //     : data?.thumbnail
            //   : data?.product_image
            //   ? data?.product_image
            //   : data?.media_url,
            getMediaSrc(type, data),
            title,
            (type === "content" || type === "query-content"
      ? data?.content_title || data?.product_name
      : data?.shop_title || data?.product_name || data?.table_name)
            // data?.product_name || data?.table_name
          );
          if (
            (data?.shop_link && data?.shop_link !== "") ||
            (data?.permalink && data?.permalink !== "") ||
            (data?.table_url && data?.table_url !== "")
          ) {
            window.open(
              data?.shop_link && data?.shop_link !== ""
                ? data?.shop_link
                : data.permalink
                  ? data.permalink
                  : data?.table_url,
              "_blank"
            );
          }
        }}
      >
        {!data?.media_url && !data?.table_image ? (
          <div className={"creator-feed-card-links not-available"}>
            <BsHourglassSplit className="hourglassSplit" />
            <p>
              Work in progress: This content will be available in a few minutes.
            </p>
          </div>
        ) : (
          <>
            <Card.Img
              src={getMediaSrc(type, data)}
              className={
                isYoutube
                  ? " youtube-feed-height card-image-individual"
                  : isHasMediaUrl
                    ? " not-available media-feed-height card-image-individual"
                    : "card-image-individual"
              }
              loading="eager"
              style={{ backgroundColor: brand_color && brand_color }}
            />
            {/* </div> */}
            {/* {(data?.product_name || data?.table_name) && (
              <p className="product-name">
                {data?.product_name || data?.table_name}
              </p>
            )} */}
            {productName && (
              <p className="product-name">
                {productName}
              </p>
            )}

            {isLinks && (
              <Button className={"template-shop-btn-links-individual"}>
                Shop
              </Button>
            )}
          </>
        )}
      </a>
    </Card>
  );
});

export default IndividualImagePostTag;
