import { Button, Input, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import "../AccountsIntegration/AccountsIntegration.css";
import useUpdateSocialLink from "./useUpdateSocialLink";
import { useLoadSocialLinks } from "../../Utils/customHooks/useLoadSocialLinks";
import { StaticCompnents } from "../../Utils/StaticComponent";

const SocialLinks = (props: any) => {
  const { user } = useAuth();
  const { defaultEdit, setDefaultEdit } = props;
  const user_id = user?.id || "";
  const [showLinkIndex, setShowLinkIndex] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [link, setLink] = useState("");
  const { mutate: updateSocialLink } = useUpdateSocialLink();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { isLoading, data, refetch } = useLoadSocialLinks(user_id);
  useEffect(() => {
    // setIsEdit(false);
    setShowConfirmation(defaultEdit);
  }, [defaultEdit]);
  const {
    LTKIcon,
    ShopMyIcon,
    AmazonIcon,
    InstagramIcon,
    TiktokIcon,
    TwitterIcon,
    NewsletterIcon,
    PinterestIcon,
    PodcastIcon,
    YoutubeIcon,
    FacebookIcon,
    SpotifyIcon,
    SnapchatIcon,
    ApplePodcastIcon,
    ShopIcon,
    LinkedinIcon,
    LinkIcon,
  } = StaticCompnents();
  const icons = [
    {
      key: "instagram",
      name: "Instagram",
      icon: <InstagramIcon />,
      link: data?.data?.social_instagram,
    },
    {
      key: "tiktok",
      name: "TikTok",
      icon: <TiktokIcon />,
      link: data?.data?.social_tiktok,
    },
    {
      key: "youtube",
      name: "YouTube",
      icon: <YoutubeIcon />,
      link: data?.data?.social_youtube,
    },
    {
      key: "podcast",
      name: "Podcast",
      icon: <PodcastIcon />,
      link: data?.data?.social_podcast,
    },
    {
      key: "pinterest",
      name: "Pinterest",
      icon: <PinterestIcon />,
      link: data?.data?.social_pinterest,
    },
    {
      key: "newsletter",
      name: "Newsletter",
      icon: <NewsletterIcon />,
      link: data?.data?.social_newsletter,
    },
    {
      key: "facebook",
      name: "Facebook",
      icon: <FacebookIcon />,
      link: data?.data?.social_facebook,
    },
    {
      key: "amazon",
      name: "Amazon Storefront",
      icon: <AmazonIcon />,
      link: data?.data?.social_amazon,
    },
    {
      key: "spotify",
      name: "Spotify",
      icon: <SpotifyIcon />,
      link: data?.data?.social_spotify,
    },
    {
      key: "shop",
      name: "Shop",
      icon: <ShopIcon />,

      link: data?.data?.social_shop,
    },
    {
      key: "applePodcast",
      name: "ApplePodcast",
      icon: <ApplePodcastIcon />,
      link: data?.data?.social_applepodcast,
    },
    {
      key: "ltk",
      name: "LTK",
      icon: <LTKIcon />,

      link: data?.data?.social_ltk,
    },
    {
      key: "shopmy",
      name: "ShopMy",
      icon: <ShopMyIcon />,

      link: data?.data?.social_shopmy,
    },
    {
      key: "snapchat",
      name: "Snapchat",
      icon: <SnapchatIcon />,
      link: data?.data?.social_snapchat,
    },
    {
      key: "linkedin",
      name: "LinkedIn",
      icon: <LinkedinIcon />,
      link: data?.data?.social_linkedin,
    },
    {
      key: "website",
      name: "Website",
      icon: <LinkIcon />,
      link: data?.data?.social_website,
    },
  ];
  const [newLink, setNewLink] = useState("");

  function handleIconClick(icon: any, index: any) {
    setLink("");
    setIsEdit(false);
    setShowConfirmation(true);
    setDefaultEdit(true);
    setShowLinkIndex(icon);
  }
  function handleAddLink(icon: any) {
    if (link && link.trim() !== "") {
      updateSocialLink({
        link,
        platform: icon,
        pageIndex: 1,
        index: 1,
        user_id,
      });
    }
    setIsEdit(false);
    setTimeout(() => {
      setShowConfirmation(false);
      setDefaultEdit(false);
    }, 4000);
  }
  function handleEditLink(icon: any) {
    const foundIcon = icons.find((icon) => icon.key === showLinkIndex);
    if (foundIcon) {
      foundIcon.link = newLink;
    }
    updateSocialLink({
      link: newLink,
      platform: icon,
      pageIndex: 1,
      index: 1,
      user_id,
    });
  }

  return (
    <>
      <>
        <div
          style={{ width: "100%" }}
          className="account-social-content-connect"
        >
          {isLoading ? (
            <div className="spin-integration">
              <Spin />
            </div>
          ) : (
            <>
              <div className="account-social-link-connect-row social-account">
                {icons.map((icon, index) => (
                  <React.Fragment key={icon.key}>
                    <div
                      key={icon.key}
                      className="account-social-link-connect-row-cont"
                    >
                      <Button
                        className="social-icon-btn"
                        onClick={() => handleIconClick(icon.key, index)}
                      >
                        {icon.icon}
                      </Button>
                    </div>
                  </React.Fragment>
                ))}
              </div>

              {showConfirmation && (
                <div
                  className="confirmation-dialog text-center"
                  style={{ margin: "auto", width: "100%" }}
                >
                  {icons.find((icon) => icon.key === showLinkIndex)?.link ? (
                    <>
                      <h5 className="icon-key-h5">
                        {icons.find((icon) => icon.key === showLinkIndex)?.name}{" "}
                        Link
                      </h5>
                      <p className="icon-key-p">
                        {isEdit === true ? (
                          <Input
                            value={newLink}
                            onChange={(e: any) => setNewLink(e.target.value)}
                            className="w-80"
                          />
                        ) : (
                          <>
                            {
                              icons.find((icon) => icon.key === showLinkIndex)
                                ?.link
                            }
                            <br />
                            <br />
                            <Button
                              className="social-edit-btn"
                              onClick={() => {
                                const foundIcon = icons.find(
                                  (icon) => icon.key === showLinkIndex
                                );
                                if (foundIcon) {
                                  setIsEdit(true);
                                  setNewLink(foundIcon.link);
                                }
                              }}
                            >
                              Edit
                            </Button>
                          </>
                        )}
                      </p>
                      {isEdit && (
                        <div className="confirmation-btns mt-25px text-center">
                          <button
                            className="confirm-btn cancel social-cancel-btn"
                            onClick={() => {
                              setShowConfirmation(false);
                              setDefaultEdit(false);
                            }}
                          >
                            Cancel
                          </button>
                          <Button
                            className="social-save-btn"
                            onClick={() => {
                              const foundIcon = icons.find(
                                (icon) => icon.key === showLinkIndex
                              );
                              if (foundIcon) {
                                setIsEdit(false);
                                handleEditLink(foundIcon.key);
                              }
                            }}
                            // disabled={newLink.length === 0}
                          >
                            Save
                          </Button>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <h5 className="icon-key-h5">
                        Link your{" "}
                        {icons.find((icon) => icon.key === showLinkIndex)?.name}{" "}
                        below
                      </h5>
                      <Input
                        value={link}
                        onChange={(e: any) => setLink(e.target.value)}
                        className="link-input"
                      />
                      <div className="confirmation-btns mt-25px text-center">
                        <button
                          className="confirm-btn cancel social-cancel-btn"
                          onClick={() => {
                            setShowConfirmation(false);
                            setDefaultEdit(false);
                          }}
                        >
                          Cancel
                        </button>
                        <Button
                          className="social-save-btn"
                          onClick={() => {
                            const foundIcon = icons.find(
                              (icon) => icon.key === showLinkIndex
                            );
                            if (foundIcon) {
                              handleAddLink(foundIcon.key);
                            }
                          }}
                          disabled={link.length === 0}
                        >
                          Add
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </>
    </>
  );
};

export default SocialLinks;

{
  /* <HiPencil
                              style={{ marginLeft: "2%" }}
                              onClick={() => {
                                const foundIcon = icons.find(
                                  (icon) => icon.key === showLinkIndex
                                );
                                if (foundIcon) {
                                  setIsEdit(true);
                                  // Use foundIcon to get the necessary information for editing
                                  setNewLink(foundIcon.link);
                                }
                              }}
                            /> */
}
