import React, { useEffect, useState } from "react";
import { Button, Popconfirm, Spin } from "antd";
import {
  BsArchiveFill,
  BsPlusCircleFill,
  BsFillBookmarkStarFill,
  BsFillBookmarkDashFill,
  BsFillTrashFill,
  BsFillChatDotsFill,
} from "react-icons/bs";
import useUpdatePostStatus from "../../Feed/useUpdatePostStatus";
import { TiPin } from "react-icons/ti";
import { FaLock } from "react-icons/fa";
import {
  changeAdSequence,
  deletePostFunction,
  getAdsFeedLength,
  reduceAdSequence,
  removePostasAd,
  removePostasFeature,
} from "../../../../Utils/SupabaseServices/ContentDatabase";
import { MdOutlineDragIndicator, MdOutlineFileDownload } from "react-icons/md";
import useDeletePosts from "../../Feed/useDeletePosts";
import { fetchComments } from "../../../../Utils/SupabaseServices/CommentsDatabase";
import { downloadContent } from "../../../../Utils/SupabaseServices/StorageBuckets";
import { IoIosLink } from "react-icons/io";
import ManageLinksModal from "../ManageLinksModal";
import ManageCaptionModal from "../ManageCaptionModal";
import "../../Feed/feedTab.css";

const FeedActionButtons = (props: any) => {
  const {
    activeKey,
    post,
    postId,
    pageIndex,
    index,
    postIndex,
    platform,
    type,
    url,
    filter,
    username,
    subscription_type,
    user_id,
    setLimitError,
    refetchData,
    setLoadingComments,
    setSelectedPostId,
    setIsModalOpen,
    setCommentsData,
    setNoCommentsMsg,
    localData,
    adsOrder,
    setLocalData
  } = props;

  const showModal = async (
    post_id: string,
    pageIndex: number,
    postIndex: number
  ) => {
    setLoadingComments(true);
    setSelectedPostId(post_id);

    setIsModalOpen(true);
    const comments = await fetchComments(post_id, user_id);
    if (comments) {
      setCommentsData(comments);
      setNoCommentsMsg("");
      setLoadingComments(false);
    } else {
      setCommentsData([]);
      setNoCommentsMsg("There are no Comments for this post");
      setLoadingComments(false);
    }
  };
  const { mutate: updatePostStatus, isSuccess } = useUpdatePostStatus();
  const [linksModal, setLinksModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState<any>();
  const [captionModal, setCaptionModal] = useState(false);
  const { mutate: deletePostPermanently } = useDeletePosts();

  async function handleManageLinks(post: any) {
    setSelectedPost(post);
    setLinksModal(true);
  }
  async function downloadPost(
    post_id: any,
    platform: any,
    type: any,
    url: any
  ) {
    await downloadContent(post_id, type, url);
  }

  async function handleManageCaption(post: any) {
    setSelectedPost(post);
    setCaptionModal(true);
  }
  async function archivePost(post_id: string, pageIndex: number, postIndex: number) {
    updatePostStatus({
      newPostStatus: "archived",
      postId: post_id,
      activeKey,
      pageIndex,
      postIndex,
      filter,
      username,
      user_id,
    });
    await refetchData()
  }
  async function activePost(post_id: string, pageIndex: number, postIndex: number) {
    updatePostStatus({
      newPostStatus: "active",
      postId: post_id,
      activeKey,
      pageIndex,
      postIndex,
      filter,
      username,
      user_id,
    });
    await refetchData()
  }
  const [loadingAdButton, setLoadingAdButton] = useState(false)
  function adPost(post_id: string, pageIndex: number, index: number) {

    // setLoadingAdButton(true)
    getAdsFeedLength(user_id, username).then((adCount: any) => {
      if (adCount + 1 <= 2) {
        updatePostStatus({
          newPostStatus: "active,ad",
          postId: post_id,
          activeKey,
          pageIndex,
          postIndex,
          filter,
          username,
          user_id,
          localData
        });
        refetchData()
        changeAdSequence(post_id, adCount, username, user_id);
        setLimitError(false);


      } else {
        setLimitError(true);
      }
      // setTimeout(()=>{
      //   setLoadingAdButton(false)

      // },500)
    });
  }
  async function featurePost(
    post_id: string,
    pageIndex: number,
    index: number,
    postIndex: string
  ) {
    if (postIndex == "active,ad") {
      await removeFromAds(post_id);
    }
    updatePostStatus({
      newPostStatus: "active,featured",
      postId: post_id,
      activeKey,
      pageIndex,
      postIndex: index,
      filter,
      username,
      user_id,
    });
    refetchData()
  }

  async function removeFromAds(post_id: any) {
    removePostasAd(post_id, username, user_id).then(() => {
      getAdsFeedLength(user_id, username).then((adCount: any) => {
        reduceAdSequence(user_id, username);
        if (adCount + 1 <= 2) {
          setLimitError(false);
        }
      });
      refetchData();
    });

    const newData = localData && localData?.filter((data: any) => data.post_id != post_id);
    setLocalData(newData);
  }
  async function removeFromFeatured(post_id: any) {
    removePostasFeature(post_id, username, user_id).then(() => {
      refetchData();
    });
  }

  async function deletePost(post_id: string, pageIndex: number, index: number) {
    //  await deletePostPermanently({
    //     postId: post_id,
    //     activeKey,
    //     pageIndex,
    //     postIndex: index,
    //     filter,
    //     user_id
    //   })
    //   refetchData()
    deletePostFunction(post_id, username, user_id).then(() => {
      refetchData();
    });
  }
  useEffect(() => {
    if (isSuccess) {
      refetchData();
    }
  }, [isSuccess]);

  return (
    <>
      {activeKey === "active" ? (
        <Button
          type="default"
          icon={<BsArchiveFill className="iconSize" />}
          onClick={() => {
            archivePost(postId, pageIndex, index);
          }}
        >
          Archive
        </Button>
      ) : activeKey === "archived" ? (
        <Button
          type="default"
          icon={<BsPlusCircleFill className="iconSize" />}
          onClick={() => {
            activePost(postId, pageIndex, index);
          }}
        >
          Active
        </Button>
      ) : (
        ""
      )}

      {activeKey === "active" ? (
        <>
          <Button
            // icon={
            //   <>
            //     {subscription_type && subscription_type === "basic" ? (
            //       <FaLock className="iconSize" />
            //     ) : (
            //       <BsFillBookmarkStarFill className="iconSize" />
            //     )}
            //   </>
            // }
            icon={
              loadingAdButton ? (
                <span className="spinner"><Spin /></span> // Replace with your spinner component or loading icon
              ) : subscription_type === "basic" ? (
                <FaLock className="iconSize" />
              ) : (
                <BsFillBookmarkStarFill className="iconSize" />
              )
            }
            onClick={() => {
              adPost(postId, pageIndex, index);
            }}
            disabled={
              loadingAdButton || postIndex.includes("ad") || (subscription_type === "basic")

            }
          >
            {postIndex.includes("ad") ? "Added as Ad" : "Mark as Ad"}
          </Button>

          <Button
            icon={<TiPin className="ti-pin" />}
            className="manage-button-font"
            onClick={() => {
              featurePost(postId, pageIndex, index, postIndex);
            }}
            disabled={postIndex.includes("featured")}
          >
            {postIndex.includes("featured")
              ? "Added as Featured"
              : "Mark as Featured"}
          </Button>
        </>
      ) : activeKey === "active,ad" ? (
        <Button
          icon={<BsFillBookmarkDashFill className="iconSize" />}
          onClick={() => {
            removeFromAds(postId);
          }}
        >
          Remove as Ad
        </Button>
      ) : activeKey === "active,featured" ? (
        <Button
          // icon={
          //   <RiUnpinFil

          //     className="iconSize"
          //   />
          // }
          onClick={() => {
            removeFromFeatured(postId);
          }}
        >
          Remove as Featured
        </Button>
      ) : (
        ""
      )}
      {platform === "newsletter" ? (
        <>
          <Button onClick={() => showModal(postId, pageIndex, index)}>
            Add Thumbnail
          </Button>
        </>
      ) : (
        ""
      )}
      {platform !== "podcast" &&
        platform !== "newsletter" &&
        platform !== "youtube" && (
          <Button
            icon={<MdOutlineFileDownload />}
            onClick={() => downloadPost(postId, platform, type, url)}
          >
            Download
          </Button>
        )}
      {activeKey === "active" || activeKey === "archived" || activeKey === "active,featured" ? (
        <Button
          icon={<IoIosLink className="iconSize" />}
          onClick={() => handleManageLinks(post)}
          style={{ gap: "1px" }}
        >
          Manage Links
        </Button>
      ) : (
        ""
      )}
      {activeKey === "active" || activeKey === "archived" || activeKey === "active,featured" ? (
        <Button
          // icon={
          //   <IoIosLink className="iconSize" />
          // }
          onClick={() => handleManageCaption(post)}
          className="manage-button-font"
        >
          Manage Caption
        </Button>
      ) : (
        ""
      )}
      {/* <Button
              onClick={() => showModal(postId, pageIndex, index)}
              type="primary"
              icon={
                <BsFillChatDotsFill
                  style={{ fontSize: "12px", margin: "0 4px 0px 0" }}
                />
              }
            >
              <span className="comments-btn-title">Show Relevant Comments</span>
            </Button> */}
      {activeKey !== "active,ad" && activeKey !== "active,featured" ? (
        <Popconfirm
          title="Are you sure you want to permanently delete this post?"
          onConfirm={() => deletePost(postId, pageIndex, index)}
          okText="Yes"
          cancelText="No"
          overlayStyle={{ maxWidth: "240px" }}
        >
          <Button
            type="primary"
            danger
            icon={<BsFillTrashFill className="iconSize" />}
            style={{ gap: "1px" }}
          >
            Delete Post
          </Button>
        </Popconfirm>
      ) : (
        ""
      )}
      <ManageLinksModal
        username={username}
        user_id={user_id}
        selectedPost={selectedPost}
        setSelectedPost={setSelectedPost}
        refetchData={refetchData}
        linksModal={linksModal}
        setLinksModal={setLinksModal}
      />
      <ManageCaptionModal
        username={username}
        user_id={user_id}
        selectedPost={selectedPost}
        setSelectedPost={setSelectedPost}
        refetchData={refetchData}
        captionModal={captionModal}
        setCaptionModal={setCaptionModal}
      />
    </>
  );
};

export default FeedActionButtons;
