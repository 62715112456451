import { Button, Input, message, Upload, UploadProps } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import "../../StayUpToDateControls.css";
import { supabase } from "../../../../../config/supabaseClient";
import { BsGrid3X3GapFill } from "react-icons/bs";
import { FaBars } from "react-icons/fa";
import { StaticCompnents } from "../../../../../Utils/StaticComponent";
import { handleProductFileUpload } from "../../../../../Utils/uploadImage/UploadImage";
import { debounce } from "lodash";

const HighlightLinksComponents = (props: any) => {
  const { username, user_id, defaultName, link, image, defaultFileList, cta, view, setView, errorMsg, isUploading, setIsUploading, setErrorMsg } =
    props;
  const [name, setName] = useState("");
  const [shoplink, setShoplink] = useState("");
  const [fileList, setFileList] = useState<any[]>([]);
  const [shopImage, setShopImage] = useState("");
  const [shopCTA, setShopCTA] = useState("");
  const [errorInputMsg, setErrorInputMsg] = useState({
    buttonErrorMsg:"",
    titleErrorMsg:"",
  });

  const {
    LeftViewIcon
  } = StaticCompnents();

  useEffect(() => {
    setName(defaultName);
    setShoplink(link);
    setFileList(defaultFileList);
    setShopCTA(cta || "Check it out!");
    setShopImage(image);
  }, [defaultName, link, cta, image]);
  // const handleInputChange = (value: string, setter: Function) => {
  //   setter(value); // Set the input value
  //   props.handleData(shopImage, name, shoplink, "link",shopCTA);

  //   // setIsButtonDisabled(!value || !details); // Disable button if either name or details is empty
  // };
  // const handleInputChange = useCallback(
  //   (value: any, setter: any) => {
  //     setter(value);
  //     props.handleData(shopImage, name, shoplink, "link", shopCTA);
  //   },
  //   [props, shopImage, name, shoplink, shopCTA]
  // );

  useEffect(() => {
    props.handleData(shopImage || "", name, shoplink, "link", shopCTA);
  }, [name, shoplink, shopCTA, shopImage]);

  const handleInputChange = (value: string, setter: React.Dispatch<React.SetStateAction<string>>, limit: number) => {
    if (limit && value.length <= limit) {
      limit === 15 ? setErrorInputMsg({ ...errorInputMsg, buttonErrorMsg: "" }) : setErrorInputMsg({ ...errorInputMsg, titleErrorMsg: "" })
      setter(value);
      limit === 15 ? props.setCTA(value) : props.setName(value)
    } else {
      limit === 15 ? setErrorInputMsg({
        ...errorInputMsg,
        buttonErrorMsg: `Button Text is limited to ${limit} characters.`
      }) : setErrorInputMsg({
        ...errorInputMsg,
        titleErrorMsg: `Highlight Title is limited to ${limit} characters.`
      })
    }
  };
  const debouncedUpload = useCallback(
    debounce(async (info) => {
      const { file } = info;

      // Prevent multiple uploads
      if (isUploading) {
        return;
      }

      // Set uploading flag
      setIsUploading(true);
      setErrorMsg("Please wait till the highlight upload");

      try {
        await handleProductFileUpload({
          info,
          username,
          productName: "",
          setFileList,
          setProductImage: setShopImage,
          storageName: "highlight_section"
        });
      } finally {
        // Reset uploading flag after completion
        setIsUploading(false);
        setErrorMsg("")
      }
    }, 300), // Debounce with 300ms delay
    [isUploading, username, supabase]
  );


  const uploadProps: UploadProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    accept: "image/*",
    async onChange(info) {
      setFileList([...info.fileList]);
      debouncedUpload(info);
    },
  }

  
  const beforeUpload = (file: any) => {
    const isImage = file.type.startsWith('image/');
    if (!isImage) {
      message.error('You can only upload image files!');
    }
    return isImage || Upload.LIST_IGNORE; // Prevent uploading non-image files
  };

  useEffect(() => {
    let buttonErrorTimer: NodeJS.Timeout;
    let titleErrorTimer: NodeJS.Timeout;

    if (errorInputMsg.buttonErrorMsg) {
      buttonErrorTimer = setTimeout(() => {
        setErrorInputMsg((prev) => ({ ...prev, buttonErrorMsg: "" }));
      }, 10000);
    }

    if (errorInputMsg.titleErrorMsg) {
      titleErrorTimer = setTimeout(() => {
        setErrorInputMsg((prev) => ({ ...prev, titleErrorMsg: "" }));
      }, 10000);
    }

    return () => {
      clearTimeout(buttonErrorTimer);
      clearTimeout(titleErrorTimer);
    };
  }, [errorInputMsg]);
  return (
    <div>
      {" "}
      {/* <p>Preview</p> */}
      {/* <h6 className="mb-15">
        Create a custom shop links highlight{" "}
      </h6> */}
      <div className="highlight-data-section mb-2" style={view === "right" ? { flexDirection: "row-reverse" } : {}}>
        <div className="highlight-media" style={view === "right" ? { marginLeft: "3%", marginRight: "0" } : { marginRight: "3%" }}>
          <img
            src={shopImage ? shopImage : "/assets/image-bg.jpeg"}
            className="shop-image"
          // src={component?.details?.split(",")[0]}
          />
        </div>

        <div className="highlight-details">
          <div className="highlight-name">{name ? name : "Highlight Title"}</div>
          <div className="highlight-link-tag">
            <Button className="highlight-shop-button" id="highlight-add">{shopCTA}</Button>
          </div>
        </div>
      </div>

      <div className="fieldGroup">
        <label
          className="bold-label"
        >
          Highlight Title
        </label>
        <Input
          placeholder="Enter Title"
          value={name}
          onChange={(e: any) => {
            handleInputChange(e.target.value, setName, 150);
            // props.setErrorMsg("");
          }}
          className="mb-15"
          id="highlight-title"
        />
        {errorInputMsg && errorInputMsg.titleErrorMsg && <p className="errorMsg">{errorInputMsg.titleErrorMsg}</p>}
        <label
          className="bold-label"
        >
          Button Text
        </label>
        <Input
          placeholder="Check it Out!"
          value={shopCTA}
          onChange={(e: any) => { handleInputChange(e.target.value, setShopCTA, 15);  }}
          className="mb-15"
          id="highlight-button"
        />
        {errorInputMsg && errorInputMsg.buttonErrorMsg && <p className="errorMsg">{errorInputMsg.buttonErrorMsg}</p>}
        <label
          className="bold-label"
        >
          Button Link
        </label>
        <Input
          placeholder="www.yourlink.com"
          value={shoplink}
          onChange={(e: any) => setShoplink(e.target.value)}
          className="mb-15"
          id="highlight-link"
        />
        <label
          className="bold-label"
        >
          View
        </label>
        <div className="link-presentation-highlight mb-15">
          <span className={view == "left" ? "active" : ""} onClick={() => setView("left")}> <LeftViewIcon className="links-view-icon" /> </span>
          {/* <HiBars4 /> */}
          <span className={view == "right" ? "active right-view-icon" : "right-view-icon"} onClick={() => setView("right")}> <LeftViewIcon className="links-view-icon" /> </span>
        </div>
        <div
          className="image-upload-section"
        >
          <Upload
            accept="image/*"
            {...uploadProps}
            multiple={false}
            maxCount={1}
            // progress={}
            beforeUpload={beforeUpload}
            showUploadList={fileList.length === 0 ? false : {showRemoveIcon: false,}}
          >
            <Button
              className="uploadButton"
              id="highlight-image"
            >
              <div className="text-center">
                <p
                  className="textContainerHeading"
                >
                  Click to select image or drag & drop to this area to upload
                </p>
                <p
                  className="upload-text"
                >
                  {" "}
                  JPG, JPEG, PNG, HEIC, or WEBP
                </p>
              </div>
              {/* Click to Upload */}
            </Button>
          </Upload>
        </div>
      </div>
      {errorMsg && <p className="errorMsg">{errorMsg}</p>}
    </div>
  );
};

export default HighlightLinksComponents;
