import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Button, Modal, Tabs } from "antd";
import HighlightLinksComponents from "./HighlightLinksComponents";
import "../../StayUpToDateControls.css";
import { addComponent } from "../../../../../Utils/SupabaseServices/StayUpToDateDatabase";

const HighlightComponents = (props: any) => {
  const { username, user_id, isHighlightModalOpen } = props;
  const [activeKey, setActiveKey] = useState("content");
  const [dataCompleted, setDataCompleted] = useState(false); // State to track if all data is present
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [cta, setCTA] = useState("Check it out!");

  const [type, setType] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [image, setImage] = useState("");
  const [view, setView] = useState("left")
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const handleOk = async () => {
    setConfirmLoading(true);
    const res = await addComponent(
      name,
      [image || "", link, type, view].toString(),
      username,
      user_id,
      "false",
      cta,
      "",
      name,
      "highlight"
    );
    if (res === "true") {
      setErrorMsg("");
      props.setIsHighlightModalOpen(false);

      props.setIsModalOpen(false);
      props.refetch();

      setActiveKey("content");
      setName("");
      setLink("");
      setImage("");
      setType("");
      setView("left")
      setCTA("Check it out!")
      setDefaultFileList([]);
      setDataCompleted(false);
      setConfirmLoading(false);

    } else {
      setErrorMsg("A Highlight with the same name already exists.");
      setConfirmLoading(false);

    }

  };

  const handleCancel = () => {
    props.setIsHighlightModalOpen(false);
    props.setIsModalOpen(false);
    setName("");
    setLink("");
    setImage("");
    setType("");
    setView("left")
    setCTA("Check it out!")
    setErrorMsg("");
    setActiveKey("content");

    setDefaultFileList([]);
    setDataCompleted(false);
  };
  const handlePrevious = () => {
    props.setIsModalOpen(true);
    props.setIsHighlightModalOpen(false);
    setName("");
    setLink("");
    setImage("");
    setType("");
    setErrorMsg("");
    setView("left")
    setCTA("Check it out!")
    setActiveKey("content");

    setDefaultFileList([]);

    setDataCompleted(false);
  };
  const navTabs = [
    {
      key: "content",
      label: "Content",
      disabled: false,
    },
    {
      key: "shoplink",
      label: "Shop Link",
      disabled: false,
    },
  ];

  async function handleData(
    image: any,
    name: any,
    link: any,
    kind: any,
    cta: any
  ) {
    if (name && link) {
      setName(name);
      setLink(link);
      setImage(image || "");
      setType(kind);
      setCTA(cta);
      setDataCompleted(true);
    } else {
      setDataCompleted(false);
    }
  }

  return (
    <>
      <Modal
        title={
          <div className="modal-title">
            <button
              onClick={handlePrevious}
              className="newbutton"
            >
              <FaArrowLeft />
            </button>
          </div>
        }
        open={isHighlightModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Add"
        confirmLoading={confirmLoading}
        okButtonProps={{ disabled: isUploading || !dataCompleted || cta?.length > 15 || name?.length > 150 }}
        data-testid="create-highlights"
      >
        <h5 className="title mb-15">Create a Highlight</h5>

        <HighlightLinksComponents
          username={username}
          user_id={user_id}
          handleData={handleData}
          defaultName={name}
          link={link}
          image={image}
          errorMsg={errorMsg}
          defaultFileList={defaultFileList}
          setErrorMsg={setErrorMsg}
          cta={cta}
          setCTA={setCTA}
          setName={setName}
          view={view}
          setView={setView}
          setIsUploading={setIsUploading}
          isUploading={isUploading}
        />

      </Modal>
    </>
  );
};


const TabOptions = (props: any) => {
  const { navTabs, activeKey } = props;
  const onChange = (key: string) => {
    props.setActiveKey(key);
    props.setName("");
    props.setLink("");
    props.setImage("");
    props.setType("");
    props.setDefaultFileList([]);
    props.setErrorMsg("");
    props.setCTA("");
  };
  return (
    <div>
      <Tabs defaultActiveKey="1" activeKey={activeKey} onChange={onChange}>
        {navTabs.map((tab: any) => (
          <Tabs.TabPane
            key={tab.key}
            tab={
              <div className="modal-title">
                <span style={{ color: tab.isLocked ? "grey" : "" }}>
                  {tab.label}
                </span>
              </div>
            }
          ></Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};
export default HighlightComponents;
