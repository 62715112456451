import React, { useCallback, useState } from "react";
import { Button, Input, Upload, UploadProps, Modal } from "antd";
import { supabase } from "../../../config/supabaseClient";
import { addToProductBank } from "../../../Utils/SupabaseServices/ProductBankDatabase";
import { handleProductFileUpload } from "../../../Utils/uploadImage/UploadImage";
import { debounce } from "lodash";

const AddProductModal = ({
  username,
  user_id,
  addProductModalShow,
  setAddProductModalShow,
  refetchData,
  refetchCount,
  linkFilterRefetch,
}: any) => {
  const [productName, setProductName] = useState<string>("");
  const [productLink, setProductLink] = useState<string>("");
  const [productImage, setProductImage] = useState<string>("");
  const [fileList, setFileList] = useState<any[]>([]);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [isUploading, setIsUploading] = useState(false);

  const handleInputChange = (
    value: string,
    setter: React.Dispatch<React.SetStateAction<string>>
  ) => {
    setter(value);
  };
  const debouncedUpload = useCallback(
    debounce(async (info) => {
      const { file } = info;

      // Prevent multiple uploads
      if (isUploading) {
        return;
      }

      // Set uploading flag
      setIsUploading(true);

      try {
        await handleProductFileUpload({
          info,
          username,
          productName,
          setFileList,
          setProductImage,
          storageName: "product_bank",
        });
      } finally {
        // Reset uploading flag after completion
        setIsUploading(false);
      }
    }, 300), // Debounce with 300ms delay
    [isUploading, username, productName, supabase]
  );

  const uploadProps: UploadProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    accept: "image/*",
    async onChange(info) {
      setFileList([...info.fileList]);
      debouncedUpload(info);
    },
  };

  const handleCreate = async () => {
    if (!productName || !productLink || !productImage) {
      setErrorMsg("All fields are required!");
      return;
    }

    try {
      await addToProductBank(
        productName,
        productLink,
        productImage,
        user_id,
        ""
      );
      refetchCount();
      refetchData();
      linkFilterRefetch();
      // Reset fields
      handleClear();
      setAddProductModalShow(false);
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  const handleClear = () => {
    setProductName("");
    setProductLink("");
    setProductImage("");
    setFileList([]);
  };

  const handleCancel = () => {
    setAddProductModalShow(false);
    handleClear();
  };

  return (
    <Modal
      title="Add Product"
      open={addProductModalShow}
      onCancel={handleCancel}
      onOk={handleCreate}
      okText="Add"
      okButtonProps={{
        disabled:
          productName &&
          productName !== "" &&
          productLink &&
          productLink !== "" &&
          productImage &&
          productImage !== ""
            ? false
            : true,
      }}
    >
      <div className="fieldGroup">
        <label className="bold-label">Product Name</label>
        <Input
          placeholder="Enter Product Name"
          value={productName}
          onChange={(e: any) => {
            handleInputChange(e.target.value, setProductName);
            setErrorMsg("");
          }}
          className="mb-15"
          id="product-name-add"
        />
        <label className="bold-label">Product Link</label>
        <Input
          placeholder="www.yourlink.com"
          value={productLink}
          onChange={(e: any) =>
            handleInputChange(e.target.value, setProductLink)
          }
          className="mb-15"
          id="product-link-add"
        />

        <div className="image-upload-section">
          <Upload
            {...uploadProps}
            multiple={false}
            maxCount={1}
            showUploadList={fileList.length === 0 ? false : {showRemoveIcon: false}}
            
          >
            <Button
              className="uploadButton"
              data-testid="upload-product"
              disabled={!productName}
            >
              <div className="text-center">
                <p className="textContainerHeading">
                  Click to select image or drag & drop to this area to upload
                </p>
                <p className="upload-text"> JPG, JPEG, PNG, HEIC, or WEBP</p>
              </div>
            </Button>
          </Upload>
        </div>
      </div>

      {/* Preview Section */}
      {productName && productImage ? (
        <>
          <div
            className="mt-2"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <img
              src={productImage}
              alt="Product Preview"
              style={{
                width: "140px",
                height: "250px",
                objectFit: "cover",
                borderRadius: "8px",
                border: "1px solid #ddd",
              }}
            />
            <p
              style={{
                textAlign: productName !== "" ? "left" : "center",
              }}
            >
              {productName}{" "}
            </p>
          </div>
        </>
      ) : null}
    </Modal>
  );
};

export default AddProductModal;
