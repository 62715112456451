import { Button, Carousel, Input, List, Modal } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "../../StayUpToDateControls.css";
import { updateComponent } from "../../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import Skeleton from "react-loading-skeleton";
import { supabase } from "../../../../../config/supabaseClient";
import { HiCheck, HiPencil } from "react-icons/hi";
import VideoComponent from "../../../../../components/VideoComponent/VideoComponent";
import RemovedContentTable from "../../../../../components/EditModalComponents.tsx/RemovedContentTable";
import { useLoadTrendingProducts } from "../../../../../Utils/customHooks/UpToDateContent/useLoadTrendingProducts";
import { getMaxTrendingCount } from "../../../../../Utils/SupabaseServices/AnalyticsDatabse";
import CustomDateRange from "../../../../../components/CustomDateRange/CustomDateRange";
import dayjs from "dayjs";
import { getPostTitle } from "../../../../../Utils/HelperFunction/HelperFunction";
const EditTrendingProducts = (props: any) => {
  const {
    username,
    user_id,
    isTrendingProductsEditModalOpen,
    setIsTrendingProductsEditModalOpen,
    type,
    component,
    refetchDashboard
  } = props;
  const [name, setName] = useState(component.name || "");
  const [clickNumber, setClickNumber] = useState(""); // const [details, setDetails] = useState("");

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [postIds, setPostIds] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [defaultQuery, setDefaultQuery] = useState("");
  const [removePostIds, setRemovePostIds] = useState<any>("");

  const [activeTab, setActiveTab] = useState(2);
  const [localActiveTab, setLocalActiveTab] = useState(activeTab);
  const [isCustomDateModalOpen, setIsCustomDateModalOpen] = useState(false); // State for the custom date modal
  const [customStartDate, setCustomStartDate] = useState<any>("");
  const [customEndDate, setCustomEndDate] = useState<any>("");
  const [days, setDays] = useState<any>("");
  const [isChanged, setIsChanged] = useState(false);

  const [selectedDates, setSelectedDates] = useState<
    [dayjs.Dayjs, dayjs.Dayjs] | null
  >(null);
  const [isEditDate, setIsEditDate] = useState(false);
  useEffect(() => {
    setPostIds(component?.post_ids);
    setName(component?.name);
    setDefaultQuery(component?.query);
    setRemovePostIds(component?.removed_postids);
    const [numberOfClicks, startDate, endDate, activeKey] = (
      component?.details ?? ""
    ).split(",");
    setDays(activeKey);
    setClickNumber(numberOfClicks);
    if (activeKey === "7" || activeKey === "30" || activeKey === "90") {
      const startDate = dayjs().subtract(activeKey, "day").format("YYYY-MM-DD");
      const endDate = dayjs().format("YYYY-MM-DD");
      setCustomStartDate(startDate);
      setCustomEndDate(endDate);
    } else {
      setCustomStartDate(startDate);
      setCustomEndDate(endDate);
    }

    setActiveTab(+activeKey); // The unary + operator converts the string to a number
  }, [component]);
  const handleInputChange = (value: string, setter: Function) => {
    const numericValue = parseInt(value, 10); // or parseFloat(value) if dealing with decimals

    if (numericValue > maxCount) {
      setErrorMsg(`Number should not exceed ${maxCount}`);
    } else {
      setErrorMsg("");
      setIsButtonDisabled(!value);
    }
    setter(value); // Set the input value
    // setIsButtonDisabled(!value); // Disable button if either name or details is empty
  };
  const [maxCount, setMaxCount] = useState<any>();
  useEffect(() => {
    fetchMaxCount();
  }, [clickNumber]);
  async function fetchMaxCount() {
    const res = await getMaxTrendingCount(username, user_id);
    setMaxCount(res);
  }
  const handleOk = async () => {
    setConfirmLoading(true);
    const res = await updateComponent(
      name,
      component,
      username,
      user_id,
      defaultQuery,
      component?.type,
      "",
      `${clickNumber},${customStartDate},${customEndDate},${activeTab}`,

      removePostIds
    );
    if (res === "true") {
      // props.refetch();
      refetchDashboard();
      props.setIsTrendingProductsEditModalOpen(false);
      props.setSelectedComponent("");
      setConfirmLoading(false);
      setIsChanged(true)
      setName("");
      setPostIds("");
      setErrorMsg("");
      setDefaultQuery("");
      setRemovePostIds("");
    } else {
      setErrorMsg("Category with this name already exists");
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    props.setIsTrendingProductsEditModalOpen(false);
    props.setSelectedComponent("");
    refetchDashboard();
    setName("");
    setPostIds("");
    setErrorMsg("");
    setDefaultQuery("");
    setRemovePostIds("");
  };

  function handleRemovedPostIds(post_ids: any, type: any) {
    if (type === "minus") {
      setRemovePostIds((prev: any) => {
        const idsArray = prev?.split(",").filter(Boolean);
        idsArray.push(post_ids);

        return idsArray.join(",");
      });
    } else if (type === "add") {
      setRemovePostIds((prev: any) => {
        const idsArray = prev.split(",").filter(Boolean);
        const filteredArray = idsArray.filter((elem: any) => elem !== post_ids);
        return filteredArray.join(",");
      });
    }
  }
  function handleEdit() {
    setIsEditDate(true);
    setLocalActiveTab(2);
    setSelectedDates(null);
    setCustomStartDate("");
    setCustomEndDate("");
  }
  function handleUpdateCancel() {
    setIsEditDate(false);
  }
  function handleUpdateOk() {
    let startDate: any;
    let endDate: any;

    if (localActiveTab !== 1 && localActiveTab !== 2) {
      endDate = dayjs().add(1, "day").format("YYYY-MM-DD");
      startDate = dayjs().subtract(localActiveTab, "day").format("YYYY-MM-DD");
      setCustomStartDate(startDate);
      setCustomEndDate(endDate);
    } else {
      if (localActiveTab === 2) {
        endDate = dayjs().add(1, "day").format("YYYY-MM-DD");
        startDate = dayjs().subtract(100000, "day").format("YYYY-MM-DD");
        setCustomStartDate(startDate);
        setCustomEndDate(endDate);
      } else {
        startDate = dayjs(customStartDate)?.format("YYYY-MM-DD");
        endDate = dayjs(customEndDate)?.add(1, "day").format("YYYY-MM-DD");
        setCustomStartDate(startDate);
        setCustomEndDate(endDate);
      }
    }
    setActiveTab(localActiveTab);
    setIsEditDate(false);
  }

  const renderDate = useCallback(
    (startDate: string, endDate: string) => {
      if (activeTab != 2) {
        return (
          <p className="m-0">
            {dayjs(startDate).format("MMMM D, YYYY")} -{" "}
            {dayjs(endDate).subtract(1, "day").format("MMMM D, YYYY")}
          </p>
        );
      } else {
        return <p className="active-tab">Showing All data</p>;
      }
    },
    [activeTab] // This ensures the function updates only when activeTab changes
  );


  return (
    <>
      <Modal
        open={isTrendingProductsEditModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Update"
        confirmLoading={confirmLoading}
        okButtonProps={{
          disabled: !name || !clickNumber || clickNumber > maxCount,
        }}
      >
        <div className="mt-1">
          <h5 className="editTitle">Editing {name}</h5>
          <label className="bold-label">Carousel Title</label>
          <Input
            placeholder="Enter Carousel Title"
            value={name}
            onChange={(e: any) => {
              handleInputChange(e.target.value, setName);
              setErrorMsg("");
            }}
            className="mb-15"
          />
          <label className="bold-label">Clicks Threshold</label>
          <Input
            placeholder="Enter Clicks Threshold"
            value={clickNumber}
            type="number"
            onChange={(e: any) => {
              handleInputChange(e.target.value, setClickNumber);
              //   setErrorMsg("");
            }}
            className="mb-15"
          />
          {errorMsg && <p className="errorMsg">{errorMsg}</p>}
          <div className="custom-date">
            {customStartDate &&
              customEndDate &&
              typeof customStartDate === "string" &&
              typeof customEndDate === "string" && (
                <>{renderDate(customStartDate, customEndDate)}</>
              )}

            <Button
              className="ant-btn css-dev-only-do-not-override-ntu4wn ant-btn-primary"
              onClick={handleEdit}
            >
              Edit
            </Button>
          </div>
          {component?.query && component?.query !== "" && (
            <p>Content shown based on "{component?.query}"</p>
          )}
          <AddedContentTable
            post_ids={component?.post_ids?.split(",")}
            username={username}
            user_id={user_id}
            setPostIds={setPostIds}
            handleRemovedPostIds={handleRemovedPostIds}
            componentName={name}
            refetchDashboard={refetchDashboard}
            removePostIds={removePostIds}
            setRemovePostIds={setRemovePostIds}
            type={component?.type}
            displayName={component?.display_name}
            query={component?.query}
            title={component?.name}
            numberOfClicks={clickNumber}
            customStartDate={customStartDate}
            customEndDate={customEndDate}
            days={days}
            setIsChanged={setIsChanged}
            isChanged={isChanged}
          />
          <RemovedContentTable
            post_ids={component?.post_ids?.split(",")}
            username={username}
            user_id={user_id}
            setPostIds={setPostIds}
            handleRemovedPostIds={handleRemovedPostIds}
            componentName={name}
            refetchDashboard={refetchDashboard}
            removePostIds={removePostIds}
            setRemovePostIds={setRemovePostIds}
            type={component?.type}
            displayName={component?.display_name}
            query={component?.query}
            title={component?.name}
            numberOfClicks={clickNumber}
            customStartDate={customStartDate}
            customEndDate={customEndDate}
            days={days}
          />
        </div>
      </Modal>
      <Modal
        open={isEditDate}
        onOk={handleUpdateOk}
        onCancel={handleUpdateCancel}
        okText="Update"
      // confirmLoading={confirmLoading}
      // okButtonProps={{
      //   disabled: !name || !clickNumber || clickNumber > maxCount,
      // }}
      >
        <CustomDateRange
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          setIsCustomDateModalOpen={setIsCustomDateModalOpen}
          isCustomDateModalOpen={isCustomDateModalOpen}
          setSelectedDates={setSelectedDates}
          setCustomStartDate={setCustomStartDate}
          setCustomEndDate={setCustomEndDate}
          selectedDates={selectedDates}
          customStartDate={customStartDate}
          customEndDate={customEndDate}
          setLocalActiveTab={setLocalActiveTab}
          localActiveTab={localActiveTab}
          setDays={setDays}
        />
      </Modal>
    </>
  );
};
const AddedContentTable = (props: any) => {
  const {
    username,
    user_id,
    post_ids,
    componentName,
    refetchDashboard,
    setRemovePostIds,
    type,
    displayName,
    removePostIds,
    title,
    numberOfClicks,
    query,
    customStartDate,
    customEndDate,
    days, setIsChanged, isChanged
  } = props;

  const [postIds, setPostIds] = useState(post_ids);
  const [deletePostIds, setDeletePostIds] = useState(removePostIds);
  useEffect(() => {
    setDeletePostIds(removePostIds);
  }, [removePostIds]);

  const { isLoading, data, refetch }: any = useLoadTrendingProducts(
    user_id,
    username,
    title,
    numberOfClicks,
    deletePostIds,
    customStartDate,
    customEndDate,
    days,
    isChanged
  );
  // useEffect(() => {
  //   refetch();
  // }, [customStartDate, customEndDate, refetch]);
  const onMinus = (post_id: string) => {
    setDeletePostIds((prev: any) => {
      const idsArray = prev.split(",").filter(Boolean);
      idsArray.push(post_id);
      return idsArray.join(",");
    });
    props.handleRemovedPostIds(post_id, "minus");
    refetch();
  };
  const [value, setValue] = useState<string>();
  const [edit, setEdit] = useState(false);
  const [editIndex, setEditIndex] = useState("");

  const handleDone = async (post_id: string) => {
    const { data: user, error } = await supabase
      .from(`${username}`)
      .select("*")
      .eq("post_id", post_id)
      .eq("user_id", user_id);
    const { data } = await supabase
      .from(`${username}`)
      .update({
        shop_title: value,
        combined_text: user?.[0]?.combined_text + " " + `|${value}|`,
      })
      .eq("post_id", post_id)
      .eq("user_id", user_id);
    const { data: newContent, error: newErroor } = await supabase
      .from("content_new")
      .update({
        product_name: value,
        combined_text: user?.[0]?.combined_text + " " + `|${value}|`,
      })
      .eq("post_id", post_id)
      .eq("user_id", user_id);
    const { data: bankContent, error: bankErroor } = await supabase
      .from("product_bank")
      .update({
        shop_title: value,
      })
      .eq("product_id", post_id)
      .eq("user_id", user_id);
    setIsChanged(true);
    setEdit(false);
    setEditIndex("");
    refetch();
    refetchDashboard();
  };
  const SkeletonStatBox = () => {
    return (
      <div className="skeletonDiv">
        <Skeleton className="skeletonStyle" />
      </div>
    );
  };

  const filteredData = useMemo(() => {
    return (data || [])
      .filter((element: any) => element !== undefined)
      .filter((obj: any) => {
        const deleteIdsArray = deletePostIds?.split(",") || [];
        return !deleteIdsArray.includes(obj.post_id);
      });
  }, [data, deletePostIds]);

  const hasFilteredData = filteredData.length > 0;
  return (
    <>
      <div className="addedContent">
        <h5 className="addedContentTitle">{componentName}</h5>
        {!isLoading ? (
          <div className="addedSvg">
            {hasFilteredData ? (
              filteredData.map((post: any, index: any) => (
                <React.Fragment key={post.post_id}>
                  <div
                    key={index}
                    className="feed-tab-post-container added-post"
                  >
                    <div
                      className="feed-tab-media-wrapper"
                      style={
                        post?.platform === "newsletter"
                          ? {
                            backgroundColor: "#efeded",
                            textAlign: "center",
                          }
                          : {}
                      }
                    >
                      <Button
                        onClick={() => onMinus(post.post_id)}
                        className="carousel-content-remove-btn"
                      >
                        Remove
                      </Button>

                      {post?.platform !== "newsletter" ? (
                        <>
                          {post?.media_url ? (
                            <>
                              {post?.media_url.split(",").length > 1 &&
                                post?.media_url
                                  .split(",")
                                  .every((url: any) => url.trim() !== "") ? (
                                <Carousel
                                  dotPosition="bottom"
                                  className="feed-tab-media-carousel"
                                >
                                  {post?.media_url
                                    .split(",")
                                    .map((media_url: any, index: any) => (
                                      <React.Fragment key={index}>
                                        {media_url.includes("video") ? (
                                          <div className="h-255">
                                            <VideoComponent
                                              media_url={media_url}
                                              thumbnail={
                                                post.thumbnail &&
                                                  post.thumbnail.split(",")
                                                    .length &&
                                                  post.thumbnail.split(",")[index]
                                                  ? post.thumbnail.split(",")[
                                                  index
                                                  ]
                                                  : "/assets/dummy_video.png"
                                              }
                                            />
                                          </div>
                                        ) : (
                                          <img
                                            className="feed-tab-media-check mt-300"
                                            src={media_url}
                                            alt="post"
                                          />
                                        )}
                                      </React.Fragment>
                                    ))}
                                </Carousel>
                              ) : (
                                <>
                                  {post.media_url.includes("video") ? (
                                    <div className="h-255">
                                      <VideoComponent
                                        media_url={post.media_url}
                                        thumbnail={post.thumbnail}
                                      />
                                    </div>
                                  ) : (
                                    <img
                                      className="feed-tab-media-check"
                                      src={
                                        post.media_url
                                          ? post.media_url
                                          : "/assets/dummy_video.png"
                                      }
                                      alt="post"
                                    />
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <img
                              className="feed-tab-media-check"
                              src={
                                post?.thumbnail
                                  ? post?.thumbnail
                                  : "/assets/dummy_video.png"
                              }
                              alt="post"
                            />
                          )}
                        </>
                      ) : (
                        <div>
                          <h4 className="mt-7">
                            {new Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              day: "numeric",
                              month: "long",
                            }).format(new Date(post?.post_date))}
                          </h4>
                          <h4> Newsletter</h4>
                        </div>
                      )}
                    </div>
                    {edit === true && editIndex === index ? (
                      <>
                        <Input
                          value={value}
                          onChange={(e: any) => setValue(e.target.value)}
                        />{" "}
                        <HiCheck
                          onClick={() => {
                            handleDone(post.post_id);
                          }}
                        />
                      </>
                    ) : (
                      <p
                        style={{
                          textAlign:
                            post.product_name !== "" ? "left" : "center",
                        }}
                      >
                        {getPostTitle(post, type)}
                        {/* {type === "query-content" ? post?.content_title : post?.shop_title ? post?.shop_title : post?.product_name} */}
                        <HiPencil
                          onClick={() => {
                            const name = getPostTitle(post, type)
                            setValue(name);
                            setEdit(true);
                            setEditIndex(index);
                          }}
                        />
                      </p>
                    )}
                  </div>
                </React.Fragment>
              ))
            ) : (
              <React.Fragment>
                <>
                  <div className="feed-tab-post">
                    {[...Array(4)].map((_, index) => (
                      <SkeletonStatBox key={index} />
                    ))}
                  </div>
                </>
              </React.Fragment>
            )}
          </div>
        ) : (
          <>
            <React.Fragment>
              <>
                <div className="feed-tab-post">
                  {[...Array(4)].map((_, index) => (
                    <SkeletonStatBox key={index} />
                  ))}
                </div>
              </>
            </React.Fragment>
          </>
        )}
      </div>
    </>
  );
};

export default EditTrendingProducts;
