import { Button, Input, List, Modal } from "antd";
import React, { useRef, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import "../../../StayUpToDateControls.css";
import { addComponent } from "../../../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import axios from "axios";
import FeedCheckboxComponent from "../../../../../../components/CheckboxComponent/FeedCheckboxComponent";
import QueryFeedCheckboxComponent from "../../../../../../components/CheckboxComponent/QueryFeedCheckboxComponent";

const QueryContentComponents = (props: any) => {
  const {
    username,
    user_id,
    isQueryContentComponentOpen,
    isContentEditModalOpen,
    type,
  } = props;
  const [name, setName] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [postIds, setPostIds] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [query, setQuery] = useState("");
  const [defaultQuery,setDefaultQuery]=useState("")
  const nameRef = useRef(""); 
  const handleInputChange = (value: string) => {
    nameRef.current = value;
    setName(value);
    setIsButtonDisabled(!value);
  };
  const handleOk = async () => {
    setConfirmLoading(true);
    const finalName = nameRef.current; 
    const res = await addComponent(
      finalName,
      "",
      username,
      user_id,
      "false",
      "Check it out!",
      query,
      finalName,
      "query-content",
      ""
    );
    if (res === "true") {
      props.refetch();

      props.setIsContentModalOpen(false);
      props.setIsQueryContentComponentOpen(false);
      setName("");
      setPostIds("");
      setErrorMsg("");
      setQuery("")
      setDefaultQuery("")
      setConfirmLoading(false);


    } else {
      setErrorMsg("Category with this name already exists");
      setConfirmLoading(false);

    }

  };

  const handleCancel = () => {
    props.setIsContentModalOpen(false);
    props.setIsQueryContentComponentOpen(false);
    setName("");
    setPostIds("");
    setErrorMsg("");
    setQuery("")
    setDefaultQuery("")

  };
  const handlePrevious = () => {
    props.setIsQueryContentComponentOpen(false);
    props.setIsContentModalOpen(true);
    setName("");
    setPostIds("");
    setErrorMsg("");
    setQuery("")
    setDefaultQuery("")

  };
  function handlePostIds(post_ids: any) {
    setPostIds(post_ids);
  }
  function handleQuery(term: any) {
    setQuery(term);
  }
  return (
    <>
      <Modal
        title={
          <div className="modal-title">
            <button
              onClick={handlePrevious}
                            className="newbutton"
            >
              <FaArrowLeft />
            </button>
          </div>
        }
        open={isQueryContentComponentOpen || isContentEditModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Add"
        confirmLoading={confirmLoading}
        okButtonProps={{ disabled: !name }}
        data-testid="add-shoppable-content"
      >

        <h5 className="title mb-15 text-center">
          Create a Word or Phrase Based Content Carousel{" "}
        </h5>
        <label
           className="bold-label"
          >Carousel Title</label>
        <Input
          placeholder="Enter Title"
          value={name}
          onChange={(e: any) => {
            handleInputChange(e.target.value);
            setErrorMsg("");
          }}
          className="mb-15"
          id="add-content-carousel-title"
        />
        {errorMsg && <p className="errorMsg">{errorMsg}</p>}

        <QueryFeedCheckboxComponent
          isShopContent={false}
          username={username}
          user_id={user_id}
          handlePostIds={handlePostIds}
          componentName={nameRef.current}
          post_ids={postIds}
          handleQuery={handleQuery}
          defaultQuery={defaultQuery}
          type="content"
          setDefaultQuery={setDefaultQuery}
        />
      </Modal>
    </>
  );
};

export default QueryContentComponents;
