import { Button, Input, List, Modal } from "antd";
import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import "../../../StayUpToDateControls.css";
import { addComponent } from "../../../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import FeedCheckboxComponent from "../../../../../../components/CheckboxComponent/FeedCheckboxComponent";

const CustomContentComponents = (props: any) => {
  const { username, user_id, isCustomContentComponentOpen } = props;
  const [name, setName] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [postIds, setPostIds] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [defaultQuery, setDefaultQuery] = useState("");

  const [errorMsg, setErrorMsg] = useState("");

  const handleInputChange = (value: string, setter: Function) => {
    setter(value);
    setIsButtonDisabled(!value);
  };
  const handleOk = async () => {
    setConfirmLoading(true);

    const res = await addComponent(
      name,
      "",
      username,
      user_id,
      "false",
      "Check it out!",
      "",
      name,
      "content",
      postIds.toString()
    );

    if (res === "true") {
      setName("");
      setPostIds("");
      setDefaultQuery("");
      setErrorMsg("");
      props.refetch();

      props.setIsContentModalOpen(false);
      props.setIsCustomContentComponentOpen(false);
      setConfirmLoading(false);

    } else {
      setErrorMsg("Category with this name already exists.");
      setConfirmLoading(false);

    }
  };

  const handleCancel = () => {
    setName("");
    setPostIds("");
    setErrorMsg("");
    setDefaultQuery("");
    props.setIsContentModalOpen(false);
    props.setIsCustomContentComponentOpen(false);
  };
  const handlePrevious = () => {
    setName("");
    setPostIds("");
    setErrorMsg("");
    setDefaultQuery("");
    props.setIsCustomContentComponentOpen(false);
    props.setIsContentModalOpen(true);
  };

  function handlePostIds(post_ids: any) {
    setPostIds(post_ids);
  }
  return (
    <>
      <Modal
        title={
          <div className="modal-title">
            <button
              onClick={handlePrevious}
              className="newbutton"
            >
              <FaArrowLeft />
            </button>
          </div>
        }
        open={isCustomContentComponentOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        okText="Add"
        okButtonProps={{ disabled: !name }}
        data-testid="add-custom-content-carousel"
      >
        <h5 className="title mb-20">
          Create a Custom Content Carousel
        </h5>
        <label
          className="bold-label"
        >
          Carousel Title
        </label>
        <Input
          placeholder="Enter Title"
          value={name}
          onChange={(e: any) => {
            handleInputChange(e.target.value, setName);
            setErrorMsg("");
          }}
          className="mb-20"
          id="custom-content-title"
        />
        {errorMsg && <p className="errorMsg">{errorMsg}</p>}


        <FeedCheckboxComponent
          isShopLinks={false}
          username={username}
          user_id={user_id}
          handlePostIds={handlePostIds}
          className="ant-checkbox-wrapper"
          componentName={name}
          post_ids={postIds}
          defaultQuery={defaultQuery}
          setDefaultQuery={setDefaultQuery}
          type ="content"
        />
      </Modal>
    </>
  );
};

export default CustomContentComponents;
