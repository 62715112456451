import React, { useEffect, useState } from "react";
import { BsHourglassSplit } from "react-icons/bs";
import { Button } from "antd";
import Card from "react-bootstrap/esm/Card";
import PuffLoader from "react-spinners/PuffLoader";
import "../SubComponents.css";

import track, {
  TRACKING_TYPES,
} from "../../../../../Utils/SupabaseServices/Track";
import { useLocation } from "react-router-dom";
import { useLoadCustomContent } from "../../../../../Utils/customHooks/UpToDateContent/useLoadCustomContent";
import VideoPostTag from "../../../../../components/GeneralPostTag/VideoPostTag";
import ImagePostCarouselTag from "../../../../../components/GeneralPostTag/ImagePostCarouselTag";
import ImagePostTag from "../../../../../components/GeneralPostTag/ImagePostTag";
import { getPostTitle } from "../../../../../Utils/HelperFunction/HelperFunction";
import DataSkeleton from "../../../../../components/StayUpToDateComponent/UpToDateComponents/DataSkeleton";
import { useDomLoaded } from "../../../../../hooks/DomLoadedContext";
const CustomContentWrapper = (props: any) => {
  const {
    user_id,
    username,
    numberOfDays,
    title,
    isLinks,
    // handleShop,
    platform,
    type,
    postIds,
    brand_color,
  } = props;
  const location = useLocation();
  const domLoaded = useDomLoaded();
  function handleShop(postId: string, media_url: string) {
    track("Redirect to shop link", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: { post_id: postId, media_url: media_url },
      user_id: user_id,
      title: "Stay Up To Date",
    });
  }
  const {
    isLoading: CustomContentDataLoading,
    data: CustomContentData,
    forceRefetch,
  }: any = useLoadCustomContent(username, user_id, postIds?.split(","), title);
  // useEffect(() => {
  //   forceRefetch();

  //   return () => {
  //     // Cleanup code here (if needed)
  //   };
  // }, []);

  return (
    <>
      <div>
        {(CustomContentDataLoading || !domLoaded) ? (
          <div className="outer-container-subcomponents">
            <DataSkeleton dashboard={true} />{" "}
          </div>
        ) : (
          <div className="outer-container-subcomponents">
            {CustomContentData && CustomContentData.length > 0 ? (
              <div className="links-grid-container-subcomponents">
                {CustomContentData &&
                  CustomContentData.map((link: any, index: any) => (
                    <React.Fragment key={index}>
                      {link.media_url ? (
                        link.media_url.split(",").length > 1 &&
                          link.media_url
                            .split(",")
                            .every((url: any) => url.trim() !== "") ? (
                          <>
                            <ImagePostCarouselTag
                              post={link}
                              handleRedirect={handleShop}
                              title={title}
                              brand_color={brand_color}
                              isLinks={false}
                              isDashboard={true}
                              type={type}
                              productName={type ? getPostTitle(link, type) : ""}
                            />
                          </>
                        ) : (
                          <>
                            {link.media_url.includes("video") ? (
                              <>
                                <VideoPostTag
                                  post={link}
                                  handleRedirect={handleShop}
                                  title={title}
                                  brand_color={brand_color}
                                  isLinks={false}
                                  isDashboard={true}
                                  thumbnail={link.thumbnail}
                                  type={type}
                                  productName={
                                    type ? getPostTitle(link, type) : ""
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <ImagePostTag
                                  data={link}
                                  handleRedirect={handleShop}
                                  title={title}
                                  brand_color={brand_color}
                                  isLinks={false}
                                  isDashboard={true}
                                  type={type}
                                  productName={
                                    type ? getPostTitle(link, type) : ""
                                  }
                                />
                              </>
                            )}
                          </>
                        )
                      ) : (
                        <>
                          <ImagePostTag
                            data={link}
                            handleRedirect={handleShop}
                            title={title}
                            brand_color={brand_color}
                            isLinks={false}
                            isDashboard={true}
                            type={type}
                            productName={type ? getPostTitle(link, type) : ""}
                          />
                        </>
                      )}
                    </React.Fragment>
                  ))}
              </div>
            ) : (
              <div className="outer-container-subcomponents">
                <p>No content at this time</p>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default CustomContentWrapper;
