import dayjs from "dayjs";
import { supabase } from "../../config/supabaseClient";

export async function updateCount(route: any, username: any = "") {
  await supabase.rpc("increment_page_view", {
    page_route: route,
    username: username,
  });
}

export async function updateGlobalCount() {
  await supabase.rpc("update_global_count");
}

export async function updateGeneralPageCount(page: any) {
  await supabase.rpc("incrementGeneralPage", { page_route: page });
}

export async function getUniqueUsers(
  username: any,
  startDate: any,
  endDate: any,
  user_id: any
) {
  const { data, error } = await supabase.rpc("fetch_unique_users_date_rangee", {
    route_name: `/${username}`,
    start_date: startDate,
    end_date: endDate,
    userid: user_id,
  });
  if (data && data.length) {
    return data[0].count;
  } else {
    return 0;
  }
}
export async function getPageViews(
  username: any,
  startDate: any,
  endDate: any,
  user_id: any
) {
  const { data } = await supabase.rpc("get_page_views_date_rangee", {
    route_name: `/${username}`,
    start_date: startDate,
    end_date: endDate,
    userid: user_id,
  });

  if (data && data.length) {
    return data[0].count;
  } else {
    return 0;
  }
}

export async function getCarouselViews(
  username: any,
  startDate: any,
  endDate: any,
  user_id: any
) {
  const { data } = await supabase.rpc("get_carousel_views_date_range", {
    route_name: `/${username}`,
    start_date: startDate,
    end_date: endDate,
    userid: user_id,
  });
const acc: any = {};

data.forEach((item: any) => {
  const post = JSON.parse(item.details);
  const title = post.title;
  const unique_id= post.unique_id

  // Initialize an empty array if this title doesn't exist in the accumulator
  if (!acc[title]) {
    acc[title] = [];
  }

  // Find the entry with the same unique_id for this title
  const existingEntry = acc[title].find(
    (entry:any) => entry.unique_id === unique_id
  );

  if (existingEntry) {
    // If entry exists, increment the clicks to count duplicates
    existingEntry.clicks += 1;
  } else {
    // Otherwise, add a new entry with clicks initialized to 1
    acc[title].push({
      unique_id: unique_id,
      clicks: 1,  // Start with 1 for the first occurrence
    });
  }
});
  if (data && data.length) {
    return acc
  } else {
    return 0;
  }
}

export async function getPageAnalytics(
  username: any,
  startDate: any,
  endDate: any,
  user_id: any,
  isYesterday: any,
  daysNumber: any
) {
  if (
    daysNumber === 0 ||
    daysNumber === 30 ||
    daysNumber === 7 ||
    daysNumber === undefined
  ) {
    const { data } = await supabase.rpc("get_page_views_date_analyticsss", {
      route_name: `/${username}`,
      start_date: startDate,
      end_date: endDate,
      userid: user_id,
    });
    if (data && data.length) {
      return data.reverse();
    } else {
      return 0;
    }
  } else if (daysNumber === 1) {
    let { data } = await supabase.rpc("get_page_views_date_analyticsssy", {
      route_name: `/${username}`,
      start_date: dayjs().subtract(0, "day").format("YYYY-MM-DD"),

      end_date: endDate,
      userid: user_id,
      is_yesterday: isYesterday,
    });
    if (data && data.length) {
      if (daysNumber === 7) {
        data = data.slice(1);

        return data.reverse();
      } else if (daysNumber === 1) {
        data = data.slice(3);

        return data.reverse();
      } else {
        return data;
      }
    } else {
      return 0;
    }
  } else {
    let { data } = await supabase.rpc("get_page_views_date_analyticsssy", {
      route_name: `/${username}`,
      start_date: startDate,
      end_date: endDate,
      userid: user_id,
      is_yesterday: isYesterday,
    });
    if (data && data.length) {
      if (daysNumber === 7) {
        data = data.slice(1);

        return data.reverse();
      } else if (daysNumber === 1) {
        data = data.slice(3);

        return data.reverse();
      } else {
        return data;
      }
    } else {
      return 0;
    }
  }
}

export async function getPostAnalytics(
  username: any,
  startDate: any,
  endDate: any,
  user_id: any,
  isYesterday: any,
  daysNumber: any
) {
  const { data } = await supabase.rpc("get_post_views_date_analyticssss", {
    route_name: `/${username}`,
    start_date: startDate,
    end_date: endDate,
    userid: user_id,
  });
  if (data && data.length) {
    return data.reverse();
  } else {
    return 0;
  }
}

export async function getSocialLinksAnalytics(
  username: any,
  startDate: any,
  endDate: any,
  user_id: any,
  isYesterday: any,
  daysNumber: any
) {
  let socialLinksData: any = [];
  const { data } = await supabase.rpc("get_social_links_analyticss", {
    route_name: `/${username}`,
    start_date: startDate,
    end_date: endDate,
    userid: user_id,
  });

  if (data) {
    let indexCharCode = 65;

    for (let d of data) {
      const details = JSON.parse(d.post);

      socialLinksData.push({
        clicks: d.count,
        social: details.social,
        link: details.link,
        index: String.fromCharCode(indexCharCode),
      });
      indexCharCode++;
    }
  }

  return socialLinksData;
}

export async function getMyLinksAnalytics(
  username: any,
  startDate: any,
  endDate: any,
  user_id: any,
  isYesterday: any,
  daysNumber: any
) {
  let myLinksData: any = [];
  const { data } = await supabase.rpc("get_my_links_analyticss", {
    route_name: `/${username}`,
    start_date: startDate,
    end_date: endDate,
    userid: user_id,
  });

  if (data) {
    let indexCharCode = 65;

    for (let d of data) {
      const details = JSON.parse(d.post);

      let existingEntry = myLinksData.find(
        (entry: any) => entry.link === details.link
      );

      if (existingEntry) {
        existingEntry.count += d.count;
      } else {
        myLinksData.push({
          clicks: d.count,
          link: details.link,
          url: details.url,
          index: String.fromCharCode(indexCharCode),
        });
        indexCharCode++;
      }
    }
  }

  return myLinksData;
}

export async function getPostClicksAnalytics(
  username: any,
  startDate: any,
  endDate: any,
  user_id: any,
  isYesterday: any,
  daysNumber: any
) {
  let postClicksObj: any = [];
  const { data } = await supabase.rpc("get_post_clicks_analyticss", {
    route_name: `/${username}`,
    start_date: startDate,
    end_date: endDate,
    userid: user_id,
  });
  if (data) {
    const transformedData = data.reduce((acc: any, item: any) => {
      const post = JSON.parse(item.post);

      if (!acc[post.title]) {
        acc[post.title] = [];
      }

      acc[post.title].push({
        post_id: post.post_id,
        media_url: post.media_url,
        count: item.count,
        name: post.name,
      });

      return acc;
    }, {});
    return transformedData;
  }
}

export async function getDaysLinksAnalytics(
  username: any,
  startDate: any,
  endDate: any,
  user_id: any,
  isYesterday: any,
  daysNumber: any
) {
  const { data } = await supabase.rpc("get_days_links_analyticss", {
    route_name: `/${username}`,
    start_date: startDate,
    end_date: endDate,
    userid: user_id,
  });
}

export async function getUserViewsAnalytics(
  username: any,
  startDate: any,
  endDate: any,
  user_id: any,
  isYesterday: any,
  daysNumber: any
) {
  if (
    daysNumber === 0 ||
    daysNumber === 30 ||
    daysNumber === 7 ||
    daysNumber === undefined
  ) {
    const { data } = await supabase.rpc("get_user_views_date_analyticsss", {
      route_name: `/${username}`,
      start_date: startDate,
      end_date: endDate,
      userid: user_id,
    });
    if (data && data.length) {
      return data.reverse();
    } else {
      return 0;
    }
  } else if (daysNumber === 1) {
    let { data } = await supabase.rpc("get_user_views_date_analyticsssy", {
      route_name: `/${username}`,
      start_date: dayjs().subtract(0, "day").format("YYYY-MM-DD"),

      end_date: endDate,
      userid: user_id,
      is_yesterday: isYesterday,
    });
    if (data && data.length) {
      if (daysNumber === 7) {
        data = data.slice(1);

        return data.reverse();
      } else if (daysNumber === 1) {
        data = data.slice(3);

        return data.reverse();
      } else {
        return data;
      }
    } else {
      return 0;
    }
  } else {
    let { data } = await supabase.rpc("get_user_views_date_analyticsssy", {
      route_name: `/${username}`,
      start_date: startDate,
      end_date: endDate,
      userid: user_id,
      is_yesterday: isYesterday,
    });
    if (data && data.length) {
      if (daysNumber === 7) {
        data = data.slice(1);

        return data.reverse();
      } else if (daysNumber === 1) {
        data = data.slice(3);

        return data.reverse();
      } else {
        return data;
      }
    } else {
      return 0;
    }
  }
}

export async function getPostClicksViewsAnalytics(
  username: any,
  startDate: any,
  endDate: any,
  user_id: any,
  isYesterday: any,
  daysNumber: any
) {
  
  if (
    daysNumber === 0 ||
    daysNumber === 30 ||
    daysNumber === 7 ||
    daysNumber === undefined
  ) {
    let { data, error } = await supabase.rpc(
      "get_post_clicks_views_date_analyticsss",
      {
        route_name: `/${username}`,
        start_date: startDate,
        end_date: endDate,
        userid: user_id,
      }
    );
    if (data && data.length) {
      data.reverse();

      const acc: any = {};

      data.forEach((item: any) => {
        const post = JSON.parse(item.post);
        const title = post.title;

        if (!acc[title]) {
          acc[title] = [];
        }

        const existingDateEntry = acc[title].find(
          (entry: any) => entry.formatted_date === item.formatted_date
        );

        if (existingDateEntry) {
          existingDateEntry.clicks += item.count;
        } else {
          acc[title].push({
            formatted_date: item.formatted_date,
            clicks: item.count,
          });
        }
      });
      return acc;
    } else {
      return 0;
    }
  } else if (daysNumber === 1) {
    let { data } = await supabase.rpc(
      "get_post_clicks_views_date_analyticsssy",
      {
        route_name: `/${username}`,
        start_date: dayjs().subtract(0, "day").format("YYYY-MM-DD"),

        end_date: endDate,
        userid: user_id,
        is_yesterday: isYesterday,
      }
    );
    if (data && data.length) {
      if (daysNumber === 7) {
        data = data.slice(1);
        data.reverse();

        const acc: any = {};

        data.forEach((item: any) => {
          const post = JSON.parse(item.post);
          const title = post.title;

          if (!acc[title]) {
            acc[title] = [];
          }

          const existingDateEntry = acc[title].find(
            (entry: any) => entry.formatted_date === item.formatted_date
          );

          if (existingDateEntry) {
            existingDateEntry.count += item.count;
          } else {
            acc[title].push({
              formatted_date: item.formatted_date,
              clicks: item.count,
            });
          }
        });

        return acc;
      } else if (daysNumber === 1) {
        data = data.slice(3);

        data.reverse();

        const acc: any = {};

        data.forEach((item: any) => {
          const post = JSON.parse(item.post);
          const title = post.title;

          if (!acc[title]) {
            acc[title] = [];
          }

          const existingDateEntry = acc[title].find(
            (entry: any) => entry.formatted_date === item.formatted_date
          );

          if (existingDateEntry) {
            existingDateEntry.count += item.count;
          } else {
            acc[title].push({
              formatted_date: item.formatted_date,
              clicks: item.count,
            });
          }
        });

        return acc;
      } else {
        data.reverse();

        const acc: any = {};

        data.forEach((item: any) => {
          const post = JSON.parse(item.post);
          const title = post.title;

          if (!acc[title]) {
            acc[title] = [];
          }

          const existingDateEntry = acc[title].find(
            (entry: any) => entry.formatted_date === item.formatted_date
          );

          if (existingDateEntry) {
            existingDateEntry.count += item.count;
          } else {
            acc[title].push({
              formatted_date: item.formatted_date,
              clicks: item.count,
            });
          }
        });

        return acc;
      }
    } else {
      return 0;
    }
  } else {
    let { data } = await supabase.rpc("get_post_clicks_date_analyticsssy", {
      route_name: `/${username}`,
      start_date: startDate,
      end_date: endDate,
      userid: user_id,
      is_yesterday: isYesterday,
    });
    if (data && data.length) {
      if (daysNumber === 7) {
        data = data.slice(1);
        data.reverse();

        const acc: any = {};

        data.forEach((item: any) => {
          const post = JSON.parse(item.post);
          const title = post.title;

          if (!acc[title]) {
            acc[title] = [];
          }

          const existingDateEntry = acc[title].find(
            (entry: any) => entry.formatted_date === item.formatted_date
          );

          if (existingDateEntry) {
            existingDateEntry.count += item.count;
          } else {
            acc[title].push({
              formatted_date: item.formatted_date,
              clicks: item.count,
            });
          }
        });

        return acc;
      } else if (daysNumber === 1) {
        data = data.slice(3);
        data.reverse();

        const acc: any = {};

        data.forEach((item: any) => {
          const post = JSON.parse(item.post);
          const title = post.title;

          if (!acc[title]) {
            acc[title] = [];
          }

          const existingDateEntry = acc[title].find(
            (entry: any) => entry.formatted_date === item.formatted_date
          );

          if (existingDateEntry) {
            existingDateEntry.count += item.count;
          } else {
            acc[title].push({
              formatted_date: item.formatted_date,
              clicks: item.count,
            });
          }
        });

        return acc;
      } else {
        data.reverse();

        const acc: any = {};

        data.forEach((item: any) => {
          const post = JSON.parse(item.post);
          const title = post.title;

          if (!acc[title]) {
            acc[title] = [];
          }

          const existingDateEntry = acc[title].find(
            (entry: any) => entry.formatted_date === item.formatted_date
          );

          if (existingDateEntry) {
            existingDateEntry.count += item.count;
          } else {
            acc[title].push({
              formatted_date: item.formatted_date,
              clicks: item.count,
            });
          }
        });

        return acc;
      }
    } else {
      return 0;
    }
  }
}

export async function getMaxTrendingCount(username: any, user_id: any) {
  const { data } = await supabase.rpc("get_post_clicks_analyticss_trending", {
    route_name: `/${username}`,
    userid: user_id,
  });

  if (data) {
    const transformedData = data.reduce((acc: any, item: any) => {
      const post = JSON.parse(item.post);

      if (!acc[post.title]) {
        acc[post.title] = [];
      }

      acc[post.title].push({
        post_id: post.post_id,
        media_url: post.media_url,
        count: item.count,
        name: post.name,
      });

      return acc;
    }, {});

    // const postIds = Array.from(
    //   new Set(
    //     Object.keys(transformedData)
    //       .flatMap((key: any) =>
    //         transformedData[key].filter(
    //           (post: any) =>
    //             post.count >= numberOfClicks &&
    //             typeof post.post_id === "string"
    //         )
    //       )
    //       .sort((a: any, b: any) => b.count - a.count)
    //       .map((post: any) => post.post_id)
    //   )
    // );
    const postIds = Array.from(
      new Set(
        Object.keys(transformedData)
          .flatMap((key: any) => transformedData[key])
          .sort((a: any, b: any) => b.count - a.count) // Sort by post.count in descending order
        // .slice(0)// Get only the top 'numberOfClicks' posts
        // .map((post: any) => post.post_id) // Map to post_id after sorting
      )
    );
    return postIds?.[0]?.count;
  }
}

export async function getCarouselClicksViewsAnalytics(
  username: any,
  startDate: any,
  endDate: any,
  user_id: any,
  isYesterday: any,
  daysNumber: any
) {
  if (
    daysNumber === 0 ||
    daysNumber === 30 ||
    daysNumber === 7 ||
    daysNumber === undefined
  ) {
    let { data, error } = await supabase.rpc(
      "get_carousel_clicks_views_date_analyticsss",
      {
        route_name: `/${username}`,
        start_date: startDate,
        end_date: endDate,
        userid: user_id,
      }
    );

    if (data && data.length) {
      data.reverse();
      const acc: any = {};

      data.forEach((item: any) => {
        const post = JSON.parse(item.post);
        const title = post.title;
        // const id=post?.id

        if (!acc[title]) {
          acc[title] = [];
        }

        const existingDateEntry = acc[title].find(
          (entry: any) => entry.formatted_date === item.formatted_date
        );

        if (existingDateEntry) {
          existingDateEntry.clicks += item.count;
        } else {
          acc[title].push({
            formatted_date: item.formatted_date,
            clicks: item.count,
          });
        }
      });
      return acc;
    } else {
      return 0;
    }
  } else if (daysNumber === 1) {
    let { data } = await supabase.rpc(
      "get_carousel_clicks_views_date_analyticsssy",
      {
        route_name: `/${username}`,
        start_date: dayjs().subtract(0, "day").format("YYYY-MM-DD"),

        end_date: endDate,
        userid: user_id,
        is_yesterday: isYesterday,
      }
    );
    if (data && data.length) {

      if (daysNumber === 7) {
        data = data.slice(1);
        data.reverse();

        const acc: any = {};

        data.forEach((item: any) => {
          const post = JSON.parse(item.post);
          const title = post.title;

          if (!acc[title]) {
            acc[title] = [];
          }

          const existingDateEntry = acc[title].find(
            (entry: any) => entry.formatted_date === item.formatted_date
          );

          if (existingDateEntry) {
            existingDateEntry.count += item.count;
          } else {
            acc[title].push({
              formatted_date: item.formatted_date,
              clicks: item.count,
            });
          }
        });

        return acc;
      } else if (daysNumber === 1) {
        data = data.slice(3);

        data.reverse();

        const acc: any = {};

        data.forEach((item: any) => {
          const post = JSON.parse(item.post);
          const title = post.title;

          if (!acc[title]) {
            acc[title] = [];
          }

          const existingDateEntry = acc[title].find(
            (entry: any) => entry.formatted_date === item.formatted_date
          );

          if (existingDateEntry) {
            existingDateEntry.count += item.count;
          } else {
            acc[title].push({
              formatted_date: item.formatted_date,
              clicks: item.count,
            });
          }
        });

        return acc;
      } else {
        data.reverse();

        const acc: any = {};

        data.forEach((item: any) => {
          const post = JSON.parse(item.post);
          const title = post.title;

          if (!acc[title]) {
            acc[title] = [];
          }

          const existingDateEntry = acc[title].find(
            (entry: any) => entry.formatted_date === item.formatted_date
          );

          if (existingDateEntry) {
            existingDateEntry.count += item.count;
          } else {
            acc[title].push({
              formatted_date: item.formatted_date,
              clicks: item.count,
            });
          }
        });
        return acc;
      }
    } else {
      return 0;
    }
  } else {
    let { data } = await supabase.rpc("get_carousel_clicks_date_analyticsssy", {
      route_name: `/${username}`,
      start_date: startDate,
      end_date: endDate,
      userid: user_id,
      is_yesterday: isYesterday,
    });
    if (data && data.length) {

      if (daysNumber === 7) {
        data = data.slice(1);
        data.reverse();

        const acc: any = {};

        data.forEach((item: any) => {
          const post = JSON.parse(item.post);
          const title = post.title;

          if (!acc[title]) {
            acc[title] = [];
          }

          const existingDateEntry = acc[title].find(
            (entry: any) => entry.formatted_date === item.formatted_date
          );

          if (existingDateEntry) {
            existingDateEntry.count += item.count;
          } else {
            acc[title].push({
              formatted_date: item.formatted_date,
              clicks: item.count,
            });
          }
        });

        return acc;
      } else if (daysNumber === 1) {
        data = data.slice(3);
        data.reverse();

        const acc: any = {};

        data.forEach((item: any) => {
          const post = JSON.parse(item.post);
          const title = post.title;

          if (!acc[title]) {
            acc[title] = [];
          }

          const existingDateEntry = acc[title].find(
            (entry: any) => entry.formatted_date === item.formatted_date
          );

          if (existingDateEntry) {
            existingDateEntry.count += item.count;
          } else {
            acc[title].push({
              formatted_date: item.formatted_date,
              clicks: item.count,
            });
          }
        });

        return acc;
      } else {
        data.reverse();

        const acc: any = {};

        data.forEach((item: any) => {
          const post = JSON.parse(item.post);
          const title = post.title;

          if (!acc[title]) {
            acc[title] = [];
          }

          const existingDateEntry = acc[title].find(
            (entry: any) => entry.formatted_date === item.formatted_date
          );

          if (existingDateEntry) {
            existingDateEntry.count += item.count;
          } else {
            acc[title].push({
              formatted_date: item.formatted_date,
              clicks: item.count,
            });
          }
        });

        return acc;
      }
    } else {
      return 0;
    }
  }
}






// export async function getCarouselClicksAnalytics(
//   username: any,
//   startDate: any,
//   endDate: any,
//   user_id: any,
//   isYesterday: any,
//   daysNumber: any
// ) {
//   let postClicksObj: any = [];
//   const { data } = await supabase.rpc("get_carousel_clicks_analyticss", {
//     route_name: `/${username}`,
//     start_date: startDate,
//     end_date: endDate,
//     userid: user_id,
//   });
//   if (data) {
//     const transformedData = data.reduce((acc: any, item: any) => {
//       const post = JSON.parse(item.post);

//       if (!acc[post.title]) {
//         acc[post.title] = [];
//       }

//       acc[post.title].push({
//         post_id: post.post_id,
//         media_url: post.media_url,
//         count: item.count,
//         name: post.name,
//         ...(post.id && { id: post.id }) // Only include `id` if `post.id` is truthy
//       });

//       return acc;
//     }, {});
//     return transformedData;
//   }
// }


// export async function getCarouselClicksAnalytics(
//   username: any,
//   startDate: any,
//   endDate: any,
//   user_id: any,
//   isYesterday: any,
//   daysNumber: any
// ) {
//   const { data } = await supabase.rpc("get_carousel_clicks_analyticss", {
//     route_name: `/${username}`,
//     start_date: startDate,
//     end_date: endDate,
//     userid: user_id,
//   });

//   if (data) {
//     const transformedData = data.reduce((acc: any, item: any) => {
//       const post = JSON.parse(item.post);
//       const { post_id, media_url, name, id } = post;

//       if (!acc[post.title]) {
//         acc[post.title] = [];
//       }

//       const existingEntry = acc[post.title].find(
//         (entry: any) => entry.post_id === post_id
//       );

//       if (existingEntry) {
//         if (id) {
//           existingEntry.idCount = (existingEntry.idCount || 0) + item.count;
//         }
//         if (!id) {
//           existingEntry.count = (existingEntry.count || 0) + item.count;
//         }
//       } else {
//         // Create a new entry for the post_id with count and/or idCount as applicable
//         const newEntry: any = {
//           post_id,
//           media_url,
//           name,
//           ...(id ? { id, idCount: item.count } : { count: item.count }),
//         };

//         acc[post.title].push(newEntry);
//       }

//       return acc;
//     }, {});

//     return transformedData;
//   }
// }


export async function getCarouselClicksAnalytics(
  username: any,
  startDate: any,
  endDate: any,
  user_id: any,
  isYesterday: any,
  daysNumber: any
) {
  const { data } = await supabase.rpc("get_carousel_clicks_analyticsss", {
    route_name: `/${username}`,
    start_date: startDate,
    end_date: endDate,
    userid: user_id,
  });
  if (data) {
    const transformedData = data.reduce((acc: any, item: any) => {
      const post = JSON.parse(item.post);
      const { post_id, media_url, name, id, created_at } = post;

      if (!acc[post.title]) {
        acc[post.title] = [];
      }

      const existingEntry = acc[post.title].find(
        (entry: any) => entry.post_id === post_id
      );

      if (existingEntry) {
        if (id) {
          existingEntry.idCount = (existingEntry.idCount || 0) + item.count;
        }
        if (!id) {
          existingEntry.count = (existingEntry.count || 0) + item.count;
        }

        // Update name if the current item's created_at is more recent
        if (new Date(item.created_at) > new Date(existingEntry.created_at)) {
          existingEntry.name = name;
          existingEntry.created_at = item.created_at;
        }
      } else {
        // Create a new entry for the post_id with count and/or idCount as applicable
        const newEntry: any = {
          post_id,
          media_url,
          name,
          created_at: item.created_at,
          ...(id ? { id, idCount: item.count } : { count: item.count }),
        };

        acc[post.title].push(newEntry);
      }

      return acc;
    }, {});

    return transformedData;
  }
}

