import { useQueries, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import {
  getCarouselClicksAnalytics,
  getCarouselClicksViewsAnalytics,
  getCarouselViews,
  getMyLinksAnalytics,
  getPageAnalytics,
  getPageViews,
  getPostAnalytics,
  getPostClicksAnalytics,
  getPostClicksViewsAnalytics,
  getSocialLinksAnalytics,
  getUniqueUsers,
  getUserViewsAnalytics,
} from "../../../../Utils/SupabaseServices/AnalyticsDatabse";

function useGraphicalAnalytics(
  user_id: any,
  startDate: any,
  endDate: any,
  isYesterday: any,
  daysNumber: any
)
 {
  const { username } = useParams();
  //Based on usernames

  const { data: pageAnalytics, isLoading: loadingPageAnalytics } = useQuery(
    [`overview-page-analytics`, username, startDate],
    () => {
      return getPageAnalytics(
        username,
        startDate,
        endDate,
        user_id,
        isYesterday,
        daysNumber
      );
    },
    { refetchOnWindowFocus: true }
  );

  const { data: userViewAnalytics, isLoading: loadingUserViewsAnalytics } =
    useQuery(
      [`overview-user-views-analytics`, username, startDate],
      () => {
        return getUserViewsAnalytics(
          username,
          startDate,
          endDate,
          user_id,
          isYesterday,
          daysNumber
        );
      },
      { refetchOnWindowFocus: true }
    );
  const { data: postAnalytics, isLoading: loadingPostAnalytics } = useQuery(
    [`overview-post-analytics`, username, startDate],
    () => {
      return getPostAnalytics(
        username,
        startDate,
        endDate,
        user_id,
        isYesterday,
        daysNumber
      );
    },
    { refetchOnWindowFocus: true }
  );

  const { data: socialLinksAnalytics, isLoading: loadingSocialLinksAnalytics } =
    useQuery(
      [`overview-socialLinks-analytics`, username, startDate],
      () => {
        return getSocialLinksAnalytics(
          username,
          startDate,
          endDate,
          user_id,
          isYesterday,
          daysNumber
        );
      },
      { refetchOnWindowFocus: true }
    );

  const { data: myLinksAnalytics, isLoading: loadingMyLinksAnalytics } =
    useQuery(
      [`overview-myLinks-analytics`, username, startDate],
      () => {
        return getMyLinksAnalytics(
          username,
          startDate,
          endDate,
          user_id,
          isYesterday,
          daysNumber
        );
      },
      { refetchOnWindowFocus: true }
    );

  const { data: postClicksAnalytics, isLoading: loadingPostClicksAnalytics } =
    useQuery(
      [`overview-postClicks-analytics`, username, startDate],
      () => {
        return getPostClicksAnalytics(
          username,
          startDate,
          endDate,
          user_id,
          isYesterday,
          daysNumber
        );
      },
      { refetchOnWindowFocus: true }
    );
    const { data: carouselClicksAnalytics, isLoading: loadingCarouselClicksAnalytics } =
    useQuery(
      [`overview-carouselClicks-analytics`, username, startDate],
      () => {
        return getCarouselClicksAnalytics(
          username,
          startDate,
          endDate,
          user_id,
          isYesterday,
          daysNumber
        );
      },
      { refetchOnWindowFocus: true }
    );
  const {
    data: postClicksViewsAnalytics,
    isLoading: loadingPostClicksViewsAnalytics,
  } = useQuery(
    [`overview-postClicksViews-analytics`, username, startDate],
    () => {
      return getPostClicksViewsAnalytics(
        username,
        startDate,
        endDate,
        user_id,
        isYesterday,
        daysNumber
      );
    },
    { refetchOnWindowFocus: true }
  );
  const {
    data: carouselClicksViewsAnalytics,
    isLoading: loadingCarouselClicksViewsAnalytics,
  } = useQuery(
    [`overview-carouselClicksViews-analytics`, username, startDate],
    () => {
      return getCarouselClicksViewsAnalytics(
        username,
        startDate,
        endDate,
        user_id,
        isYesterday,
        daysNumber
      );
    },
    { refetchOnWindowFocus: true }
  );


  const { data: uniqueUsers, isLoading: loadingUniqueUsers } = useQuery(
    ["overview-unique-users", username, startDate],
    () => {
      return getUniqueUsers(username, startDate, endDate, user_id);
    },
    { refetchOnWindowFocus: true }
  );
  const { data: pageViews, isLoading: loadingPageViews } = useQuery(
    [`overview-page-views`, username, startDate],
    () => {
      return getPageViews(username, startDate, endDate, user_id);
    },
    { refetchOnWindowFocus: true }
  );

  const { data: carouselViews, isLoading: loadingCarouselViews } = useQuery(
    [`overview-carousel-views`, username, startDate],
    () => {
      return getCarouselViews(username, startDate, endDate, user_id);
    },
    { refetchOnWindowFocus: true }
  );
  return {
    loadingPageAnalytics,
    pageAnalytics,
    loadingPostAnalytics,
    postAnalytics,
    socialLinksAnalytics,
    loadingSocialLinksAnalytics,
    uniqueUsers,
    loadingUniqueUsers,
    pageViews,
    loadingPageViews,
    carouselViews,
    loadingCarouselViews,
    myLinksAnalytics,
    loadingMyLinksAnalytics,
    postClicksAnalytics,
    loadingPostClicksAnalytics,
    carouselClicksAnalytics,
    loadingCarouselClicksAnalytics,
    loadingUserViewsAnalytics,
    userViewAnalytics,
    postClicksViewsAnalytics,
    loadingPostClicksViewsAnalytics,
    carouselClicksViewsAnalytics,
    loadingCarouselClicksViewsAnalytics,
  };
}

export default useGraphicalAnalytics;
