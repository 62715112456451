import { useInfiniteQuery, useQuery } from "react-query";
import { supabase } from "../../../config/supabaseClient";
import { StaticVariables } from "../../StaticVariables";

export function useLoadProductBankContent(
  user_id: string,
  username: any,
  currentPage: any,
  postsPerPage: any,
  totalPages: any,
  query: any,
  productFilter?: any,
  linkFilter?: any
) {
  const fetchProductBankPosts = async () => {
    const start = (currentPage - 1) * postsPerPage;
    const end = start + postsPerPage - 1;
    // const input=query?.split(" ").map((elem:any)=>(`%${elem}%`))
    const input = query
      ? query
          .split(" ") // Split filter string into words
          .filter(
            (word: string) =>
              !StaticVariables().connectingWords.includes(word.toLowerCase())
          ) // Remove connecting words
          .map((elem: string) => `%${elem}%`)
      : [];

    const rpcFunction =
      productFilter && productFilter !== "All"
        ? "get_productbankk_postss_fiil"
        : "get_productbank_postsssd";

    const params: any = {
      query_string: input,
      userid: user_id,
      start_number: start,
      end_number: end,
      query_tagg: input,
    };

    if (productFilter) {
      productFilter != "All" && (params.filter = productFilter);
      params.urlfilter = linkFilter != "AllLinks" ? linkFilter : null;
    }

    const { data, error } = await supabase.rpc(rpcFunction, params);

    if (error) {
      throw new Error(error.message);
    }

    const seen = new Set();
    const uniquePosts = data.filter((post: any) => {
      const duplicate = seen.has(post.table_id);
      seen.add(post.table_id);
      return !duplicate;
    });

    return uniquePosts;
  };

  return useInfiniteQuery(
    ["ProductBankPosts", currentPage, query, productFilter, linkFilter],
    fetchProductBankPosts,
    {
      getNextPageParam: (lastPage, pages) => {
        if (pages.length < totalPages) {
          return pages.length + 1;
        } else {
          return undefined;
        }
      },
      enabled: Boolean(totalPages),
    }
  );
}
