import { Pagination } from "antd";
import "../Analytics.css";
import { useState } from "react";

function PostClicksTable(props: any) {
  const [localLoading, setLocalLoading] = useState(false);
  const { postClicks } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 4; // Number of items per page

  // Calculate the start and end indices for the current page
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = currentPage * pageSize;
  const handlePageChange = (page: number) => {
    setLocalLoading(true);
    setCurrentPage(page);
    setTimeout(() => {
      setLocalLoading(false);
    }, 100);
  };
  return (
    <>
      {postClicks && postClicks.length > pageSize ? (
        <>
          <div className="post-clicks-container">
            <table className="analytics-tables">
              <thead>
                <tr>
                  <th>Post</th>
                  <th>Clicks</th>
                </tr>
              </thead>
              <tbody>
                {postClicks
                  ?.slice(startIndex, endIndex)
                  ?.map((oneKey: any, index: any) => (
                    <tr key={index}>
                      <td>
                        {localLoading ? (
                          <div className="analytics-postClicks-thumbnail-div">
                            <img
                              className="analytics-postClicks-thumbnail"
                              src="/assets/dummy_video.png"
                              alt="feed"
                            />
                          </div>
                        ) : (
                          <div className="analytics-postClicks-thumbnail-div">
                            {oneKey.additionalData &&
                            oneKey.additionalData.media_url ? (
                              oneKey.additionalData.media_url
                                .split(",")[0]
                                .includes("video") ? (
                                <img
                                  className="analytics-postClicks-thumbnail"
                                  src={
                                    oneKey.additionalData.thumbnail.split(
                                      ","
                                    )[0]
                                  }
                                  alt="feed"
                                />
                              ) : (
                                <img
                                  className="analytics-postClicks-thumbnail"
                                  src={
                                    oneKey.additionalData.media_url.split(
                                      ","
                                    )[0]
                                  }
                                  alt="feed"
                                />
                              )
                            ) : oneKey.additionalData.image ? (
                              <img
                                className="analytics-postClicks-thumbnail"
                                src={oneKey.additionalData.image.split(",")[0]}
                                alt="feed"
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                      </td>
                      <td>{oneKey.count}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {/* <div className="pagination-container" style={{fontSize:'1rem'}}> */}
            <Pagination
            size="small"
              current={currentPage}
              total={postClicks?.length}
              pageSize={pageSize}
              onChange={handlePageChange}
              className="text-center mt-4"
              align="center"
            />
            {/* </div> */}
          </div>
        </>
      ) : (
        <table className="analytics-tables">
          <thead>
            <tr>
              <th>Post</th>
              <th>Clicks</th>
            </tr>
          </thead>

          {postClicks?.map((oneKey: any, index: any) => (
            <tbody key={index}>
              <tr>
                <td>
                  <div className="analytics-postClicks-thumbnail-div">
                    {oneKey.additionalData &&
                    oneKey.additionalData.media_url ? (
                      oneKey.additionalData.media_url
                        .split(",")[0]
                        .includes("video") ? (
                        // <video
                        //   className="analytics-postClicks-thumbnail"
                        //   autoPlay
                        //   muted
                        //   loop
                        // >
                        //   <source src={oneKey.media_url} />
                        // </video>
                        <img
                          className="analytics-postClicks-thumbnail"
                          src={oneKey.additionalData.thumbnail.split(",")[0]}
                          alt="feed"
                        />
                      ) : (
                        <img
                          className="analytics-postClicks-thumbnail"
                          src={oneKey.additionalData.media_url.split(",")[0]}
                          alt="feed"
                        />
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </td>
                <td>{oneKey.count}</td>
              </tr>
            </tbody>
          ))}
        </table>
      )}
    </>
  );
}

export default PostClicksTable;
