import CreatorQuote from "../../../components/CreatorQuote/creatorQuote";
import { TabTitle } from "../../../Utils/GeneralFunctions";
import "../Auth.css";
import AuthTerm from "../../../components/AuthTerm/authTerm";
import EmailOTPLogin from "../LoginProviders/EmailOTPLogin";

const Login = () => {
  TabTitle("Login • Bubble");

  return (
    <div className="auth-container">
      <div className="login-auth">
        <div className="auth-phone-signup-container-flex">
          <div className="navbar_container">
            <a
              href="/"
              className="navbar_logo-link w-nav-brand"
              aria-label="home"
            >
              <div className="logo-text">
                <img
                  className="homepage-icon"
                  src="/assets/output-onlinepngtools-edited.png"
                  alt="thesearchbubble logo"
                />
              </div>
            </a>
            <nav className="navbar_menu w-nav-menu">
              <div className="navbar_menu-link-wrapper">
                <a href="/subscribe" className="navbar_link w-nav-link sign-up">
                  sign up for free
                </a>
              </div>
            </nav>
          </div>

          <div className="auth-form-wrapper">
            <div className="auth-title-div">
              <p className="auth-title-div-p">Welcome back</p>
            </div>

            <EmailOTPLogin />
          </div>
          <AuthTerm />
        </div>
        <CreatorQuote />
      </div>
    </div>
  );
};

export default Login;
