import { useQuery, useQueryClient } from "react-query";
import { supabase } from "../../../config/supabaseClient";

export function useLoadPostIdsData(
  username: any,
  user_id: any,
  postIds: any[] = [],
  isChange?: boolean
) {
  const queryClient = useQueryClient();

  const getPostIdsFeed = async () => {
    let finalData: any = [];
    const { data: postIdData = [], error: postIdError }: any = await supabase
      .from(username)
      .select("*")
      .in("post_id", postIds)
      .ilike("index", "%active%");

    const { data: productData = [], error } = await supabase.rpc(
      "get_productbankposttidd",
      {
        userid: user_id,
        post_ids: postIds,
      }
    );

    finalData =
      productData !== null ? [...postIdData, ...productData] : [...postIdData];

    const sortedPostData = postIds.map((postId) =>
      finalData.find((data: any) => data.post_id.toString() === postId)
    );

    if (postIdError) {
      throw new Error(postIdError.message);
    }

    return sortedPostData;
  };

  const { data, error, isLoading } = useQuery(
    ["postIdsFeed", user_id, ...postIds],
    getPostIdsFeed,
    {
      enabled: Boolean(postIds && postIds.length >= 0),
      keepPreviousData: true,
    }
  );

  return {
    data,
    error,
    isLoading,
    refetch: () =>
      queryClient.invalidateQueries(["postIdsFeed", user_id, ...postIds,isChange]),
  };
}
