import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BannerSkeletonTemplateWrapper } from "../BannerSkeletonTemplateWrapper";

const NewsletterWrapper = (props: any) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const { title, component, user_id, brand_color, cta, flowdeskId } = props;
  const location = useLocation();

  useEffect(() => {
    // Flodesk Script Initialization
    (function (
      w: any,
      d: Document,
      t: string,
      h: string,
      s: string,
      n: string
    ) {
      w.FlodeskObject = n;
      const fn = function (...args: any[]) {
        (w[n].q = w[n].q || []).push(args);
      };
      w[n] = w[n] || fn;
      const f: any = d.getElementsByTagName(t)[0];
      const v = "?v=" + Math.floor(new Date().getTime() / (120 * 1000)) * 60;

      // Module script
      const sm: any = d.createElement(t);
      sm.async = true;
      sm.type = "module";
      sm.src = h + s + ".mjs" + v;
      f.parentNode.insertBefore(sm, f);

      // NoModule script
      const sn: any = d.createElement(t);
      sn.async = true;
      sn.noModule = true;
      sn.src = h + s + ".js" + v;
      f.parentNode.insertBefore(sn, f);
    })(
      window,
      document,
      "script",
      "https://assets.flodesk.com",
      "/universal",
      "fd"
    );

    // Flodesk form initialization
    // if (window.fd) {
    //   window.fd("form", {
    //     formId:flowdeskId,
    //     containerEl: `#fd-form-${flowdeskId}`,
    //     // formId: "66b547f8876ad814d05bcfc0",
    //     // containerEl: "#fd-form-66b547f8876ad814d05bcfc0",
    //   });
    // }
    const intervalId = setInterval(() => {
      if (window.fd && flowdeskId) {
        window.fd("form", {
          formId: flowdeskId,
          containerEl: `#fd-form-${flowdeskId}`,
        });
        setLoading(false);
        clearInterval(intervalId);
      }
    }, 500);

    return () => clearInterval(intervalId);

    // setLoading(false)
  }, [flowdeskId, loading]);

  return (
    <>
      {loading || !flowdeskId ? (
        <BannerSkeletonTemplateWrapper />
      ) : (
        <div
          className="outter-section-banner-subcomponent"
          style={{
            backgroundColor: brand_color && `${brand_color}`,
          }}
        >
          <div id={`fd-form-${flowdeskId}`}></div>
        </div>)}
    </>
  );
};

export default NewsletterWrapper;
