import { Button, Modal } from "antd";
import { useEffect, useState } from "react";
import { updateBrandColor } from "../../Utils/SupabaseServices/ControlsDatabase";
import ColorPicker from "react-pick-color";

export const ColorModal = (props: any) => {
  const {
    username,
    user_id,
    defaultColor,
    handleColor,
    isColorModalOpen,
    setIsColorModalOpen,
    defaultTextColor,
    ModalTitle,
    fromScreen,
    refetch,
  } = props;

  const [color, setColor] = useState(defaultColor);
  const [textColor, setTextColor] = useState(defaultTextColor);
  const filterButton = [
    {
      key: "#fff",
      label: "White",
    },
    {
      key: "#000",
      label: "Black",
    },
  ];
  useEffect(() => {
    setColor(props?.initialColor);
    setTextColor(props?.initialTextColor);
  }, [props?.initialColor, props?.initialTextColor]);
  const handleOk = () => {
    handleColor(color, textColor);
    updateBrandColor(username, user_id, color, textColor);
    refetch();
    setIsColorModalOpen(false);
  };
  const handleCancel = () => {
    setIsColorModalOpen(false);
    setColor(defaultColor);
    setTextColor(defaultTextColor);
    props?.setLocalDefaultColor(props?.initialColor);
    props?.setLocalDefaultTextColor(props?.initialTextColor);
  };

  function getBrightness(hex: string) {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, "");

    // Convert hex to RGB components
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    // Calculate brightness using a perceived brightness formula
    return 0.299 * r + 0.587 * g + 0.114 * b;
  }

  function handleTextColor(element: string) {
    const hexMatch = element.match(/#([0-9a-fA-F]{3,6})/);
    if (hexMatch) {
      const brightness = getBrightness(hexMatch[1]);
      const textColor = brightness > 186 ? "#000" : "#fff";
      setTextColor(textColor);
    }
  }

  const handleColorFilter = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    productFilter: string
  ) => {
    e.preventDefault();
    setTextColor(productFilter);
  };
  return (
    <>
      <Modal
        title={ModalTitle}
        open={isColorModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {/* {fromScreen === "generalControls" && ( */}
        <>
          <div>{color}</div>
          <div className="modal-title brand-modal-specing">
            <div className="mr-16">
              <ColorPicker
                color={color}
                onChange={(color: any) => {
                  setColor(color.hex);
                  handleTextColor(color.hex);
                }}
              />
            </div>
            <div className="w-100 color-preview-tab">
              <div>
                <h5>Configure</h5>
                {fromScreen === "socialLinksScreen" && (
                  <div className="mb-3">
                    {filterButton.map((btn) => (
                      <Button
                        key={btn.key}
                        onClick={(e) => handleColorFilter(e, btn.key)}
                        className={`${
                          textColor === btn.key ? "mr-2 active" : "mr-2"
                        }`}
                      >
                        {btn.label}
                      </Button>
                    ))}
                  </div>
                )}
                <span
                  className="colorControls"
                  style={{ backgroundColor: `${color}`, color: `${textColor}` }}
                >
                  {color}
                </span>
              </div>
            </div>
          </div>
        </>
        {/* )} */}

        {/* {fromScreen === "socialLinksScreen" && (<div className="w-100 color-preview-tab mt-3">
  
            <div>
              {filterButton.map(btn =>
                <Button key={btn.key} onClick={(e) => handleColorFilter(e, btn.key)} className={`${textColor === btn.key ? "mr-2 active" : "mr-2"}`}>
                  {btn.label}
                </Button>
              )}
            </div>
            <div>
              <span className="colorControls" style={{ backgroundColor: `${color}`, color: `${textColor}` }}>{color}</span>
            </div>
          </div>)} */}
      </Modal>
    </>
  );
};
