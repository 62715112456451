import { BsInfoCircle } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import useOverviewAnalytics from "./useOverviewAnalytics";
import StatBox from "../../../../components/StatBox/StatBox";
import "react-tooltip/dist/react-tooltip.css";
import { useEffect } from "react";
import { StaticVariables } from "../../../../Utils/StaticVariables";
import "../Analytics.css";

const Overview = ({
  user_id,
  startDate,
  endDate,
  customCategories,
  instagramLinks,
  showSocials,
}: any) => {
  instagramLinks = instagramLinks && instagramLinks.split(",");
  const {
    loadingUniqueUsers,
    uniqueUsers,
    loadingPageViews,
    pageViews,
    loadingHashtagSearches,
    hashtagSearches,
    loadingSearchBarSearches,
    searchBarSearches,
    loadingPopularSearches,
    popularSearches,
    instagramHighlightsSearches,
    loadinginstagramHighlightsSearches,
    customCategoriesSearches,
    loadingCustomCategoriesSearches,
    loadinginstagramLinksSearches,
    instagramLinksSearches,
   
  } = useOverviewAnalytics(
    user_id,
    startDate,
    endDate,
    customCategories,
    instagramLinks
  );

  const data = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Page D',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Page G',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  
  return (
    <div className="analytics">
      <div className="row">
        {/* {loadingPageAnalytics?<Spin/>:
        <AnalyticsChart
          loadingPageAnalytics={loadingPageAnalytics}
          pageAnalytics={pageAnalytics}
        />} */}

        <div className="analytics-titles">
          <StatBox
            isLoading={loadingPageViews}
            name="Page Views"
            data-testid="page-views"
            number={pageViews}
            icon={
              <BsInfoCircle
                className="info-tooltip"
                data-tooltip-id="pageCount"
                data-tooltip-content="Number of times your page has been visited."
              />
            }
          />
          <StatBox
            isLoading={loadingUniqueUsers}
            name="Unique Users"
            number={uniqueUsers}
            icon={
              <BsInfoCircle
                className="info-tooltip"
                data-tooltip-id="uniqueVisitors"
                data-tooltip-content="Number of unique visitors who have viewed your page."
              />
            }
          />
        </div>
        <p className="search-form">
          {" "}
          Searches Originating From{" "}
        </p>
        <div className="analytics-titles">
          <StatBox
            isLoading={loadingSearchBarSearches}
            name="Search Bar"
            number={searchBarSearches}
            icon={
              <BsInfoCircle
                className="info-tooltip"
                data-tooltip-id="searchBarSearches"
                data-tooltip-content="Number of searches made from the search bar."
              />
            }
          />
          {showSocials?.includes("instagram") &&
            StaticVariables().instagram_ANALYTICS.includes("Top Hashtags") && (
              <StatBox
                isLoading={loadingHashtagSearches}
                name="Top Hashtags"
                number={hashtagSearches}
                icon={
                  <BsInfoCircle
                    className="info-tooltip"
                    data-tooltip-id="hashtagSearches"
                    data-tooltip-content="Number of searches made from Top Hashtags."
                  />
                }
              />
            )}
          {showSocials?.includes("instagram") &&
            StaticVariables().instagram_ANALYTICS.includes(
              "Instagram Highlights"
            ) && (
              <StatBox
                isLoading={loadinginstagramHighlightsSearches}
                name="Instagram Highlights"
                number={instagramHighlightsSearches}
                icon={
                  <BsInfoCircle
                    className="info-tooltip"
                    data-tooltip-id="instagramHighlightsSearches"
                    data-tooltip-content="Number of times your Instagram Highlghts were viewed."
                  />
                }
              />
            )}

        

          {!customCategoriesSearches ||
          customCategoriesSearches.length === 0 ? (
            <>
              {customCategories &&
                customCategories.length > 0 &&
                customCategories.map((category: any, index: any) => (
                  <StatBox
                    key={index}
                    isLoading={true}
                    name={`${category.name}`}
                    // number={category.count ? category.count : 0}
                    icon={
                      <BsInfoCircle
                        className="info-tooltip"
                        data-tooltip-id="customSearches"
                        data-tooltip-content={`Number of times this custom category was viewed.`}
                      />
                    }
                  />
                ))}
            </>
          ) : (
            <>
              {customCategoriesSearches &&
                customCategoriesSearches.length &&
                customCategoriesSearches.map((category: any, index: any) => (
                  <StatBox
                    key={index}
                    isLoading={category.count == null || category.count === ""}
                    name={`${category.name}`}
                    number={category.count}
                    icon={
                      <BsInfoCircle
                        className="info-tooltip"
                        data-tooltip-id="customSearches"
                        data-tooltip-content={`Number of times this custom category was viewed.`}
                      />
                    }
                  />
                ))}
            </>
          )}

          <StatBox
            isLoading={loadinginstagramLinksSearches}
            name="My Links"
            
            number={instagramLinksSearches}
            icon={
              <BsInfoCircle
                className="info-tooltip"
                data-tooltip-id="instagramLinksSearches"
                data-tooltip-content="Number of times My Links were viewed."
              />
            }
          />
          {/* {!instagramLinksSearches || instagramLinksSearches.length === 0 ? (
            <>
              {instagramLinks &&
                instagramLinks.length &&
                instagramLinks.map((category: any, index: any) => (
                  <StatBox
                    isLoading={true}
                    name={`${category.split(":-")[0]}`}
                    // number={category.count ? category.count : 0}
                    icon={
                      <BsInfoCircle
                                                          className="info-tooltip"

                        data-tooltip-id="InstgramLinkSearches"
                        data-tooltip-content="Number of times your links have been visited"
                      />
                    }
                  />
                ))}
            </>
          ) : (
            <>
              {instagramLinksSearches &&
                instagramLinksSearches.length &&
                instagramLinksSearches.map((category: any) => (
                  <StatBox
                    isLoading={loadinginstagramLinksSearches}
                    name={Object.keys(category)[0]}
                    number={
                      Object.values(category)[0]
                        ? Object.values(category)[0] as number
                        : 0
                    }
                    icon={
                      <BsInfoCircle
                                                          className="info-tooltip"

                      data-tooltip-id="InstgramLinkSearches"
                      data-tooltip-content="Number of times your links have been visited"
                      />
                    }
                  />
                ))}
            </>
          )} */}
        </div>
      </div>
      <Tooltip
        className="tool-tip"
        id="searchBarSearches"
        place={"top"}
        offset={4}
      />
      <Tooltip
        className="tool-tip"
        id="hashtagSearches"
        place={"top"}
        offset={4}
      />
      <Tooltip
        className="tool-tip"
        id="recentSearches"
        place={"top"}
        offset={4}
      />
      <Tooltip
        className="tool-tip"
        id="popularSearches"
        place={"top"}
        offset={4}
      />
      <Tooltip
        className="tool-tip"
        id="customSearches"
        place={"top"}
        offset={4}
      />
      <Tooltip className="tool-tip" id="pageCount" place={"top"} offset={4} />
      <Tooltip
        className="tool-tip"
        id="uniqueVisitors"
        place={"top"}
        offset={4}
      />
      <Tooltip
        className="tool-tip"
        id="instagramHighlightsSearches"
        place={"top"}
        offset={4}
      />
    </div>
  );
};

export default Overview;
