import React, { ReactElement, useEffect, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { Avatar, Image, message, Modal, Upload } from "antd";
import type { UploadFile, UploadProps } from "antd";
import { supabase } from "../../../../config/supabaseClient";
import { PlusOutlined, EyeOutlined, UserOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
const getBase64 = (file: any): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
const ProfilePicture = (props: any) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([
    {
      uid: "-1",
      name: `${props.username}`,
      status: "done",
      url: `${props.userData?.picture}`,
    },
  ]);


  function svgToBase64(svg: ReactElement): string {
    const svgString = renderToStaticMarkup(svg);
    // Encode the SVG to Base64
    const encoded = btoa(svgString);
    return `data:image/svg+xml;base64,${encoded}`;
  }
  useEffect(() => {
    if (!props.userData?.picture) {
      const avatarBase64 = `data:image/svg+xml;base64,${btoa(
        `<svg width="50px" height="50px" viewBox="0 0 2.4 2.4" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.2 1.2C0.2 0.648 0.648 0.2 1.2 0.2s1 0.448 1 1a0.998 0.998 0 0 1 -0.344 0.755 0.7 0.7 0 0 0 -1.311 0A0.998 0.998 0 0 1 0.2 1.2m1.483 0.871 0.001 0.004A0.996 0.996 0 0 1 1.2 2.2a0.996 0.996 0 0 1 -0.484 -0.125q0.001 -0.002 0.001 -0.004a0.5 0.5 0 0 1 0.966 0M1 0.9a0.2 0.2 0 1 1 0.4 0 0.2 0.2 0 0 1 -0.4 0m0.2 -0.4a0.4 0.4 0 1 0 0 0.8 0.4 0.4 0 0 0 0 -0.8" fill="#00000040"/><path x="2.5" y="2.5" width="19" height="19" rx="9.5" stroke="" d="M1.2 0.25H1.2A0.95 0.95 0 0 1 2.15 1.2V1.2A0.95 0.95 0 0 1 1.2 2.15H1.2A0.95 0.95 0 0 1 0.25 1.2V1.2A0.95 0.95 0 0 1 1.2 0.25z" stroke-width="0.1"/></svg>`
      )}`;
      setFileList([
        {
          uid: "-1",
          name: `${props.username}`,
          status: "done",
          // thumbUrl: svgToBase64(<Avatar size={35} icon={<UserOutlined />} />),
          thumbUrl: avatarBase64, // Avatar SVG as base64
        },
      ]);
    }
  }, [props.userData?.picture]);
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleChange: UploadProps["onChange"] = async ({
    fileList: newFileList,
  }) => {
    setFileList([
      {
        uid: '-xxx',
        percent: 50,
        name: 'image.png',
        status: 'uploading',
        url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
      },
    ]);

    if (newFileList.length > 0) {
      const updatedFile: any = newFileList[newFileList.length - 1];

      if (!updatedFile.type.startsWith("image/")) {
        message.error("You can only upload image files!");
        return;
      }

      let sanitizedFileName = `${props.username}.jpeg`;

      sanitizedFileName = sanitizedFileName.replace(/[,'"_]/g, "");

      const timestamp = dayjs().format("YYYYMMDD_HHmmss");

      try {
        const { data, error }: any = await supabase.storage
          .from("profile-picture")
          .upload(sanitizedFileName, updatedFile.originFileObj, {
            contentType: updatedFile.type,
          });

        if (error && error.error === "Duplicate") {
          const retryFileName = `${props.username}/${props.username}__${timestamp}.jpeg`.replace(/[,'"_]/g, "");

          const { data: retryData, error: retryError } = await supabase.storage
            .from("profile-picture")
            .upload(retryFileName, updatedFile.originFileObj, {
              contentType: updatedFile.type,
            });

          if (retryError) throw retryError;

          const { data: urlData } = await supabase.storage
            .from("profile-picture")
            .getPublicUrl(retryFileName);

          await supabase
            .from("user")
            .update({
              picture: urlData.publicUrl,
              storage_url: urlData.publicUrl,
            })
            .eq("username", props.username);

          setTimeout(() => {
            setFileList([
              { ...updatedFile, url: urlData.publicUrl, status: "done" },
            ]);
          }, 500);
        } else if (data) {
          const { data: urlData } = await supabase.storage
            .from("profile-picture")
            .getPublicUrl(sanitizedFileName);

          await supabase
            .from("user")
            .update({
              picture: urlData.publicUrl,
              storage_url: urlData.publicUrl,
            })
            .eq("username", props.username);

          setTimeout(() => {
            setFileList([
              { ...updatedFile, url: urlData.publicUrl, status: "done" },
            ]);
          }, 500);
        }

        props.refetch();
        props.setIsEmail(true);
      } catch (uploadError) {
        message.error("An error occurred during the upload. Please try again.");
      }
    }
  };

  // const handleChange: UploadProps["onChange"] = async ({
  //   fileList: newFileList,
  // }) => {
  //   setFileList([
  //     {
  //       uid: '-xxx',
  //       percent: 50,
  //       name: 'image.png',
  //       status: 'uploading',
  //       url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
  //     },
  //   ]);
  //   if (newFileList.length > 0) {
  //     const updatedFile: any = newFileList[newFileList.length - 1];
  //     const timestamp = dayjs().format("YYYYMMDD_HHmmss");
  //     try {
  //       const { data, error }: any = await supabase.storage
  //         .from("profile-picture")
  //         .upload(`${props.username}.jpeg`, updatedFile.originFileObj, {
  //           contentType: updatedFile.type,
  //         });
  //       if (error && error.error == "Duplicate") {
  //         const { data: retryData, error: retryError } = await supabase.storage
  //           .from("profile-picture")
  //           .upload(
  //             `${props.username}/${props.username}__${timestamp}.jpeg`,
  //             updatedFile.originFileObj,
  //             { contentType: updatedFile.type }
  //           );
  //         if (retryError) throw retryError;
  //         const { data: urlData } = await supabase.storage
  //           .from("profile-picture")
  //           .getPublicUrl(
  //             `${props.username}/${props.username}__${timestamp}.jpeg`
  //           );
  //         await supabase
  //           .from("user")
  //           .update({
  //             picture: urlData.publicUrl,
  //             storage_url: urlData.publicUrl,
  //           })
  //           .eq("username", props.username);
  //         setTimeout(() => {
  //           setFileList([
  //             { ...updatedFile, url: urlData.publicUrl, status: "done" },
  //           ]);
  //         }, 500)

  //       } else if (data) {
  //         const { data: urlData } = await supabase.storage
  //           .from("profile-picture")
  //           .getPublicUrl(`${props.username}.jpeg`);
  //         await supabase
  //           .from("user")
  //           .update({
  //             picture: urlData.publicUrl,
  //             storage_url: urlData.publicUrl,
  //           })
  //           .eq("username", props.username);
  //         setTimeout(() => {
  //           setFileList([
  //             { ...updatedFile, url: urlData.publicUrl, status: "done" },
  //           ]);
  //         }, 500)

  //       }
  //       props.refetch();
  //       props.setIsEmail(true);
  //     } catch (uploadError) {
  //       message.error("An error occurred during the upload. Please try again.");
  //     }
  //   }
  // };
  const uploadButton = (
    <button className="background-none" type="button">
      <PlusOutlined />
      <div className="mt-8">Upload</div>
    </button>
  );
  const handleClosePreview = () => {
    setPreviewOpen(false);
    setPreviewImage("");
  };

  const beforeUpload = (file: any) => {
    const isImage = file.type.startsWith('image/');
    if (!isImage) {
      message.error(<div style={{ color: 'black' }}>You can only upload image files!</div>);
    }
    return isImage || Upload.LIST_IGNORE;
  };

  return (
    <>
      <div className="account-description-wrapper">
        <div className="account-social-logins-heading mb-30">
          <h5>Upload Profile Picture </h5>
        </div>
        <Upload
          accept="image/*"
          action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
          listType="picture-circle"
          fileList={fileList ? fileList : []}
          onPreview={handlePreview}
          onChange={handleChange}
          className="mb-30"
          data-testid="circular-picture"
          beforeUpload={beforeUpload}
          showUploadList={{
            showPreviewIcon: true,
            showRemoveIcon: false,
            showDownloadIcon: false,
            previewIcon: <EyeOutlined />,
          }}
        >
          {fileList.length > 0 || fileList[0].url ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {uploadButton}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar size={100} icon={<UserOutlined />} />
              <button className="background-none" type="button">
                <div className="mt-8">Upload Image</div>
              </button>
            </div>
          )}
        </Upload>
        {previewImage && (
          <Modal
            open={previewOpen}
            onCancel={handleClosePreview}
            footer={null}
            height="100%"
          >
            <div className="profile-upload">
              <Image
                style={{
                  marginTop: "5%",
                  objectFit: "contain",
                  height: "100%",
                }}
                src={previewImage}
                preview={false}
              />
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};
export default ProfilePicture;
