import React, { useCallback, useState } from "react";
import { Button, Input, Upload, UploadProps, Modal } from "antd";
import { addToProductBank } from "../../../../../../Utils/SupabaseServices/ProductBankDatabase";
import { supabase } from "../../../../../../config/supabaseClient";
import { storeContentNew } from "../../../../../../Utils/SupabaseServices/ContentDatabase";
import axios from "axios";
import { FaArrowLeft } from "react-icons/fa";
import { debounce } from "lodash";
import { handleProductFileUpload } from "../../../../../../Utils/uploadImage/UploadImage";

const CreateNewContentPost = ({
  username,
  user_id,
  isCreateContentModalOpen,
  setIsCreateContentModalOpen,
  setIsModalOpen,
  refetchData,
  from_id
}: any) => {
  const [productName, setProductName] = useState<string>("");
  const [productLink, setProductLink] = useState<string>("");
  const [productImage, setProductImage] = useState<string>("");
  const [fileList, setFileList] = useState<any[]>([]);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [isUploading, setIsUploading] = useState(false);

  const handleInputChange = (
    value: string,
    setter: React.Dispatch<React.SetStateAction<string>>
  ) => {
    setter(value);
  };

  const debouncedUpload = useCallback(
    debounce(async (info) => {
      const { file } = info;

      // Prevent multiple uploads
      if (isUploading) {
        return;
      }

      // Set uploading flag
      setIsUploading(true);

      try {
        await handleProductFileUpload({
          info,
          username,
          productName,
          setFileList,
          setProductImage,
          storageName: "content"
        });
      } finally {
        // Reset uploading flag after completion
        setIsUploading(false);
      }
    }, 300), // Debounce with 300ms delay
    [isUploading, username, productName, supabase]
  );


  const uploadProps: UploadProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    accept: "image/*",
    async onChange(info) {
      const updatedFileList = info.fileList.map(file => {
        if (file.status === "uploading" || file.status === "done" || file.status === "error") {
          return file;
        }
        // Default to uploading if no status
        return { ...file, status: "uploading" };
      });
      setFileList(updatedFileList);
      debouncedUpload(info);
    },
  }

  

  const handleCreate = async () => {
    if (!productName || !productLink || !productImage) {
      setErrorMsg("All fields are required!");
      return;
    }

    try {
      // await addToProductBank(
      //   productName,
      //   productLink,
      //   productImage,
      //   user_id,
      //   ""
      // );

      await storeContentNew(
        Math.floor(Math.random() * 1000) / 1000,
        user_id,
        productName,
        productImage,
        "FEED",
        "",
        "MISC",
        productLink
      );
      //  setTimeout(async () => {
      // const OCRUrl = `http://localhost:3001/api/ocr`;
      const OCRUrl = `https://dev.thesearchbubble.com/api/ocr`;
      let resOCR = await axios.post(OCRUrl, {
        id: user_id,
        user: username,
        from: "api",
      });
      // }, 100);
      if (resOCR.data) {
        setTimeout(() => {
          refetchData();  
        }, 5000); 
        // Reset fields
        handleClear();
        setIsCreateContentModalOpen(false);
      }
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  const handleClear = () => {
    setProductName("");
    setProductLink("");
    setProductImage("");
    from_id && from_id !== "FeedNew" && setIsModalOpen(false);
    setFileList([]);
  };

  const handleCancel = () => {
    setIsCreateContentModalOpen(false);
    handleClear();
  };

  const handlePrevious = () => {
    setIsModalOpen(true);
    setIsCreateContentModalOpen(false)
  };

  return (
    <Modal
      title={
        from_id && from_id == "FeedNew" ? "" :
          <div className="modal-title">
            <button
              onClick={handlePrevious}
              className="newbutton"
            >
              <FaArrowLeft />
            </button>
          </div>
      }
      open={isCreateContentModalOpen}
      onCancel={handleCancel}
      onOk={handleCreate}
      okText="Add"
      okButtonProps={{
        disabled:
          productName &&
            productName !== "" &&
            productLink &&
            productLink !== "" &&
            productImage &&
            productImage !== ""
            ? false
            : true,
      }}
    >
      <div>
        <h5 className="title mb-15">
          Create Content
        </h5>

        <div className="fieldGroup">

          <label className="bold-label">Content Name</label>
          <Input
            placeholder="Enter Content Name"
            value={productName}
            onChange={(e: any) => {
              handleInputChange(e.target.value, setProductName);
              setErrorMsg("");
            }}
            className="mb-15"
            id="product-name-add"
          />
          <label className="bold-label">Content Link</label>
          <Input
            placeholder="www.yourlink.com"
            value={productLink}
            onChange={(e: any) =>
              handleInputChange(e.target.value, setProductLink)
            }
            className="mb-15"
            id="product-link-add"
          />

          <div className="image-upload-section">
            <Upload
              {...uploadProps}
              multiple={false}
              maxCount={1}
              showUploadList={fileList.length === 0 ? false : { showRemoveIcon: false }}
            >
              <Button
                className="uploadButton"
                data-testid="upload-product"
                disabled={!productName}
              >
                <div className="text-center">
                  <p className="textContainerHeading">
                    Click to select image or drag & drop to this area to upload
                  </p>
                  <p className="upload-text"> JPG, JPEG, PNG, HEIC, or WEBP</p>
                </div>
              </Button>
            </Upload>
          </div>
        </div>
      </div>
      {/* Preview Section */}
      {productName && productImage ? (
        <>
          <div
            className="mt-2"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <img
              src={productImage}
              alt="Product Preview"
              style={{
                width: "140px",
                height: "250px",
                objectFit: "cover",
                borderRadius: "8px",
                border: "1px solid #ddd",
              }}
            />
            <p
              style={{
                textAlign: productName !== "" ? "left" : "center",
              }}
            >
              {productName}{" "}
            </p>
          </div>
        </>
      ) : null}
    </Modal>
  );
};

export default CreateNewContentPost;
