import { Spin } from "antd";
import React from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import StatBox from "../../../../../components/StatBox/StatBox";
import { BsInfoCircle } from "react-icons/bs";

const PageCharts = (props: any) => {
  const {
    data,
    loadingPageViews,
    loadingUniqueUsers,
    pageViews,
    uniqueUsers,
    loadingUserViewsAnalytics,
    userViewAnalytics,
    brand_color,
  } = props;

  const renderCustomTooltip = (props: any) => {
    const { active, payload } = props;
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div
          className="custom-tooltip"
          style={{
            border: "1px solid #f1f1f1",
          }}
        >
          <p className="m-2 p-3px">{data.formatted_date}</p>
          <p className="user-clr p-3px">
            Unique Users: {data.unique_users}
          </p>
          <p className="clicks-clr p-3px">
            Page Views: {data.page_views}
          </p>
        </div>
      );
    }

    return null;
  };
  const CustomLegend = () => (
    <div className="clicks">
      <div className="mr-20px">
        <div className="clicks-clr">
          <ul>
            <li>Page Views</li>
          </ul>
        </div>
      </div>
      <div className="mr-20px">
        <div className="user-clr">
          <ul>
            <li>Unique Users</li>
          </ul>
        </div>
      </div>
    </div>
  );
  return (
    <div
    className="loaderMyLink"
    >
      <div className="fullDiv">
        {loadingUserViewsAnalytics ? (
          <div
            className="spin"
          >
            <Spin />
          </div>
        ) : (
          <>
            <ResponsiveContainer width="100%" height={550}>
              <LineChart
                data={userViewAnalytics}
                margin={{
                  top: 15,
                  right: window.innerWidth <= 768 ? 0 : 30,
                  left: window.innerWidth <= 768 ? 0 : 20, 
                  bottom: 5,
                }}
              >
                <XAxis dataKey="formatted_date" stroke="grey" />
                <YAxis stroke="grey" width={60} tick={{ dx: 0 }} />
                <Tooltip content={renderCustomTooltip} />{" "}
                <Legend content={CustomLegend} />
                <Line
                  type="monotone"
                  dataKey="unique_users"
                  stroke="#8685EF"
                  activeDot={{ r: 8 }}
                />
                <Line type="monotone" dataKey="page_views" stroke="#00C6CF" />
              </LineChart>
            </ResponsiveContainer>
            <div className="analytics-titles mt-5" id="page-charts">
              <StatBox
                isLoading={loadingPageViews}
                name="Page Views"
                number={pageViews}
                icon={
                  <BsInfoCircle
                    className="info-tooltip"
                    data-tooltip-id="pageCount"
                    data-tooltip-content="Number of times your page has been visited."
                  />
                }
                classname="stat-page"
                brand_color={brand_color}
              />
              <StatBox
                isLoading={loadingUniqueUsers}
                name="Unique Users"
                number={uniqueUsers}
                icon={
                  <BsInfoCircle
                    className="info-tooltip"
                    data-tooltip-id="uniqueVisitors"
                    data-tooltip-content="Number of unique visitors who have viewed your page."
                  />
                }
                classname="stat-page"
                brand_color={brand_color}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PageCharts;
