import {
  Button,
  Carousel,
  Input,
  Pagination,
  Upload,
  UploadProps,
  Modal,
} from "antd";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { supabase } from "../../../../../config/supabaseClient";
import { HiCheck, HiPencil } from "react-icons/hi";
import Skeleton from "react-loading-skeleton";
import {
  addToProductBank,
  removeProductBank,
} from "../../../../../Utils/SupabaseServices/ProductBankDatabase";
import { useLoadProductBankPages } from "../../../../../Utils/customHooks/UpToDateContent/useLoadProductBankCount";
import { useLoadProductBankContent } from "../../../../../Utils/customHooks/UpToDateContent/useLoadProductBankConent";
import { handleProductFileUpload } from "../../../../../Utils/uploadImage/UploadImage";
import { debounce } from "lodash";
interface Post {
  name: string;
  url: string;
  image: string;
}
const POSTS_PER_PAGE = 10;
const CreateNewProduct = (props: any) => {
  const {
    username,
    user_id,
    defaultName,
    link,
    image,
    defaultFileList,
    cta,
    defaultAllPosts,
    reset,
    setReset,
  } = props;

  const [allPosts, setAllPosts] = useState<Post[]>([]);
  const [fileList, setFileList] = useState<any[]>([]);
  const [componentHeading, setComponentHeading] = useState("");
  const [productName, setProductName] = useState("");
  const [Productlink, setProductlink] = useState("");
  const [productImage, setProductImage] = useState("");
  const [ProductCTA, setProductCTA] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (reset === true) {
      setProductName("");
      setProductlink("");
      setProductImage("");
      setFileList([]);
      setReset(false);
      setCurrentPage(1);
    }
  }, [reset]);
  useEffect(() => {
    setComponentHeading(defaultName);

    setProductCTA(cta || "Check it out!");
  }, [defaultName, link, cta, image]);
  useEffect(() => {
    setAllPosts(defaultAllPosts);
  }, [defaultAllPosts]);
  useEffect(() => {
    props.handleData(componentHeading, "custom");
  }, [componentHeading]);
  const handleInputChange = (value: any, setter: any) => {
    setter(value);
  };

  const debouncedUpload = useCallback(
    debounce(async (info) => {
      const { file } = info;

      // Prevent multiple uploads
      if (isUploading) {
        return;
      }

      // Set uploading flag
      setIsUploading(true);

      try {
        await handleProductFileUpload({
          info,
          username,
          productName,
          setFileList,
          setProductImage,
          storageName: "product_bank",
        });
      } finally {
        // Reset uploading flag after completion
        setIsUploading(false);
      }
    }, 300), // Debounce with 300ms delay
    [isUploading, username, productName, supabase]
  );

  const uploadProps: UploadProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    accept: "image/*",
    async onChange(info) {
      setFileList([...info.fileList]);
      debouncedUpload(info);
    },
  };

  const { data: pages, refetch: refetchCount } = useLoadProductBankPages(
    user_id || "",

    POSTS_PER_PAGE,
    "",
    username || "",
    "All",
    "AllLinks"
  );
  const { data, isLoading, isError, error, refetch, isRefetching } =
    useLoadProductBankContent(
      user_id || "",
      username || "",
      currentPage,
      POSTS_PER_PAGE,
      pages?.totalPages,
      "",
      "All",
      "AllLinks"
    );
  async function handleCreate() {
    await addToProductBank(
      productName,
      Productlink,
      productImage,
      user_id,
      componentHeading
    );

    refetch();

    setProductName("");
    setProductlink("");
    setProductImage("");
    setFileList([]);
    setCurrentPage(1);
  }
  function handleClear() {
    setProductName("");
    setProductlink("");
    setProductImage("");
    setFileList([]);
  }

  return (
    <div>
      <div className="fieldGroup">
        <label className="bold-label">Product Name</label>
        <Input
          placeholder="Enter Product Name"
          value={productName}
          onChange={(e: any) => {
            handleInputChange(e.target.value, setProductName);
            props.setErrorMsg("");
          }}
          className="mb-15"
          id="product-name-add"
        />
        <label className="boldd-label">Product Link</label>
        <Input
          placeholder="www.yourlink.com"
          value={Productlink}
          onChange={(e: any) =>
            handleInputChange(e.target.value, setProductlink)
          }
          className="mb-15"
          id="product-link-add"
        />

        <div className="image-upload-section">
          <Upload
            accept="image/*"
            {...uploadProps}
            multiple={false}
            maxCount={1}
            showUploadList={fileList.length === 0 ? false : {showRemoveIcon: false,}}
          >
            <Button
              className="uploadButton"
              data-testid="upload-product"
              disabled={!productName}
            >
              <div className="text-center">
                <p className="textContainerHeading">
                  Click to select image or drag & drop to this area to upload
                </p>
                <p className="upload-text"> JPG, JPEG, PNG, HEIC, or WEBP</p>
              </div>
            </Button>
          </Upload>
        </div>
        <div className="clear-btn">
          <Button onClick={() => handleClear()} className="mr-4">
            Clear
          </Button>
          <Button
            onClick={() => handleCreate()}
            data-testid="add-product"
            style={
              productName &&
              productName !== "" &&
              Productlink &&
              Productlink !== "" &&
              productImage &&
              productImage !== ""
                ? //
                  {
                    backgroundColor: "black",
                    color: "white",
                    cursor: "pointer",
                  }
                : {
                    cursor: "not-allowed",
                    borderColor: "#d9d9d9",
                    color: "rgba(0, 0, 0, 0.25)",
                    background: "rgba(0, 0, 0, 0.04)",
                  }
            }
            disabled={
              productName &&
              productName !== "" &&
              Productlink &&
              Productlink !== "" &&
              productImage &&
              productImage !== ""
                ? false
                : true
            }
          >
            Add
          </Button>
        </div>
      </div>
      <AddedContentTable
        posts={allPosts}
        username={username}
        user_id={user_id}
        setAllPosts={setAllPosts}
        componentName={componentHeading}
        data={data}
        pages={pages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isRefetching={isRefetching}
      />
    </div>
  );
};

const AddedContentTable = (props: any) => {
  const {
    username,
    user_id,
    posts,
    componentName,
    setAllPosts,
    data,
    pages,
    currentPage,
    setCurrentPage,
    isRefetching,
  } = props;
  const [postIds, setPostIds] = useState(posts);
  const [deletedPosts, setDeletedPosts] = useState<any>([]);
  const [deleteId, setDeleteId] = useState("");
  useEffect(() => {
    setPostIds(posts);
  }, [posts]);

  const onMinusData = async (post_id: string) => {
    const isChecked = false;
    setDeletedPosts((prev: any[]) =>
      prev.includes(post_id) ? prev : [...prev, post_id]
    );
    let updatedPostArray = data?.pages?.[0]?.filter(
      (postId: any) => postId.table_id !== post_id
    );
    const postData = data?.pages?.[0]?.find(
      (post: any) => post.table_id === post_id
    );
    await removeProductBank(post_id, user_id, postData, "");
  };
  const [value, setValue] = useState<string>();
  const [edit, setEdit] = useState(false);
  const [editIndex, setEditIndex] = useState("");

  const handleDone = async (post_id: string) => {
    const { data: user, error } = await supabase
      .from("product_bank")
      .select("*")
      .eq("product_id", post_id)
      .eq("user_id", user_id);

    await supabase
      .from("product_bank")
      .update({ name: value })
      .eq("product_id", post_id)
      .eq("user_id", user_id);
    const postData = data?.pages?.[0].find(
      (post: any) => post.table_id === post_id
    );

    postData.table_name = value;

    setEdit(false);
    setEditIndex("");
  };

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  async function handleOk(name: any) {
    onMinusData(name);
    setOpenDeleteModal(false);
  }

  const handleCancel = () => {
    setOpenDeleteModal(false);
  };

  const SkeletonStatBox = () => {
    return <Skeleton className="state-box" />;
  };
  return (
    <>
      <div className="addedContent">
        <div className="addedSvg">
          <span className="display-flex">
            {data?.pages && (
              <div className="feed-tab-posts-container">
                {isRefetching ? (
                  <>
                    {[...Array(3)].map((_, index) => (
                      <SkeletonStatBox key={index} />
                    ))}
                  </>
                ) : (
                  <>
                    {data.pages.map((group: any, pageIndex: any) => (
                      <Fragment key={pageIndex}>
                        {group
                          .filter(
                            (elem: any) => !deletedPosts.includes(elem.table_id)
                          )
                          .map((image: any, index: any) => (
                            <div
                              key={index}
                              className="feed-tab-post-container post-width"
                            >
                              <div
                                className="feed-tab-media-wrapper"
                                id="added-products"
                                style={
                                  image.platform === "newsletter"
                                    ? {
                                        backgroundColor: "#efeded",
                                        textAlign: "center",
                                      }
                                    : {}
                                }
                              >
                                <Button
                                  onClick={() => {
                                    setDeleteId(image.table_id);
                                    setOpenDeleteModal(true);
                                  }}
                                  className="carousel-content-remove-btn"
                                >
                                  Delete
                                </Button>

                                {image.platform !== "newsletter" ? (
                                  <>
                                    {image.table_image ? (
                                      <>
                                        <img
                                          className="feed-tab-media-check h-250"
                                          src={
                                            image.table_image
                                              ? image.table_image
                                              : "/assets/dummy_video.png"
                                          }
                                          alt="post"
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <img
                                          className="feed-tab-media-check h-250"
                                          src={"/assets/dummy_video.png"}
                                          alt="post"
                                        />
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <div>
                                    <h4 className="mt-7">
                                      {new Intl.DateTimeFormat("en-US", {
                                        year: "numeric",
                                        day: "numeric",
                                        month: "long",
                                      }).format(new Date(image.post_date))}
                                    </h4>
                                    <h4> Newsletter</h4>
                                  </div>
                                )}
                              </div>
                              {edit === true && editIndex === index ? (
                                <>
                                  <Input
                                    value={value}
                                    onChange={(e) => setValue(e.target.value)}
                                  />{" "}
                                  <HiCheck
                                    onClick={() => {
                                      handleDone(image.table_id);
                                    }}
                                  />
                                </>
                              ) : (
                                <p
                                  style={{
                                    textAlign:
                                      image.table_name !== ""
                                        ? "left"
                                        : "center",
                                  }}
                                >
                                  {image?.table_name}{" "}
                                  <HiPencil
                                    onClick={() => {
                                      setValue(image.table_name);
                                      setEdit(true);
                                      setEditIndex(index);
                                    }}
                                  />
                                </p>
                              )}
                            </div>
                          ))}
                      </Fragment>
                    ))}
                  </>
                )}
              </div>
            )}
          </span>
        </div>
        {pages?.feedCount > POSTS_PER_PAGE && (
          <Pagination
            className="pagination"
            current={currentPage}
            total={pages?.feedCount}
            size="small"
            showSizeChanger={false}
            showQuickJumper
            pageSize={POSTS_PER_PAGE}
            onChange={(page: number) => setCurrentPage(page)}
          />
        )}
      </div>
      <Modal
        open={openDeleteModal}
        onCancel={handleCancel}
        onOk={(e: any) => handleOk(deleteId)}
        okText="Yes"
        data-testid="remove-product-confirm-modal"
        className="mw-350"
      >
        <p className="remove-product">
          Are you sure you want to remove this product from your product bank?
        </p>
      </Modal>
    </>
  );
};

export default CreateNewProduct;
