import React, { useCallback, useEffect, useState } from "react";
import { useLoadCustomContent } from "../../../../../Utils/customHooks/UpToDateContent/useLoadCustomContent";
import { Button, Carousel, Input, Pagination } from "antd";
import VideoComponent from "../../../../../components/VideoComponent/VideoComponent";
import Skeleton from "react-loading-skeleton";
import SearchInput from "../../../../../components/SearchInput/SearchInput";
import { useLoadSearchFeedCount } from "../../../../../Utils/customHooks/UpToDateContent/useLoadSearchFeedCount";
import { useLoadSearchFeed } from "../../../../../Utils/customHooks/UpToDateContent/useLoadSearchFeed";
import { StaticVariables } from "../../../../../Utils/StaticVariables";
import _ from "lodash";
import { HiCheck, HiPencil } from "react-icons/hi";
import { supabase } from "../../../../../config/supabaseClient";
import { getPostTitle } from "../../../../../Utils/HelperFunction/HelperFunction";

const POSTS_PER_PAGE = 10;
const showSocials = StaticVariables().PLATFORMS;
const contentOrder = "Default";

const EditFeedComponent = (props: any) => {
  const {
    username,
    user_id,
    component,
    defaultQuery,
    initial,
    isModalOpen,
    handleFeed,
  } = props;
  const isShopLinks =
    component.type !== "query-content" && component.type !== "content";
  const [localPostIds, setLocalPostIds] = useState(
    component.post_ids.split(",")
  );

  useEffect(() => {
    setLocalPostIds(component.post_ids.split(","));
  }, [initial]);

  const {
    isLoading: AddedDataLoading,
    data: AddedData,
    refetch:refetchAddedContent
  }: // forceRefetch,
  any = useLoadCustomContent(
    username,
    user_id,
    localPostIds,
    // component.post_ids,
    component.name
  );
  const [query, setQuery] = useState(component?.query || "");
  const [currentPage, setCurrentPage] = useState(1);

  const { data: pages, refetch: refetchCount }: any = useLoadSearchFeedCount(
    username,
    user_id,
    query,
    POSTS_PER_PAGE,
    showSocials,
    isShopLinks
  );

  const {
    isLoading,
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  }: any = useLoadSearchFeed(
    currentPage,
    username,
    user_id,
    query,
    POSTS_PER_PAGE,
    pages?.totalPages || 0,
    pages?.feedCount,
    pages?.postIds,
    showSocials,
    contentOrder,
    isShopLinks
  );
  return (
    <>
      {" "}
      <div className="margin-added-content">
        <div className="addedContent">
          <h5 className="addedContentTitle">{component.name}</h5>
          <PostStructure
            username={username}
            user_id={user_id}
            component={component}
            PostIdData={AddedData}
            localPostIds={localPostIds}
            setLocalPostIds={setLocalPostIds}
            handleFeed={handleFeed}
            type={component?.type}
            refetchAddedContent={refetchAddedContent}
            contentType="added"

          />
        </div>
        {/* {isSearchbar !== false && ( */}
        <SearchInput
          placeholder={
            isShopLinks ? "Search all shoppable content" : "Search content"
          }
          id="search-feed"
          value={query}
          onChange={(e: any) => {
            e.preventDefault();
            const elem: any = document.getElementById("search-feed");
            const searchVal = e.target.value;
            setQuery(e.target.value);
            // setDefaultQuery(e.target.value);
            setCurrentPage(1);
          }}
          handleKeyUp={(e: any) => {
            e.preventDefault();
            if (e.keyCode === 13) {
              const elem: any = document.getElementById("search-feed");
              const searchVal = e.target.value;
              if (searchVal.length === 0) {
                elem.blur();
                setQuery("");
                // setDefaultQuery("");
                return;
              }
              elem.blur();
              setQuery(searchVal);
              // setDefaultQuery(searchVal);
              setCurrentPage(1);
            }
          }}
          isActiveFilter={Boolean(query)}
          onSearchClick={() => {
            const elem: any = document.getElementById("search-feed");
            const searchVal = elem.value;
            if (searchVal.length === 0) {
              return;
            }
            elem.blur();
            setQuery(searchVal);
            // setDefaultQuery(searchVal);
            setCurrentPage(1);
          }}
          onClearClick={() => {
            const elem: any = document.getElementById("search-feed");
            elem.value = "";
            setQuery("");
            // setDefaultQuery("");
            // refetch();
            setCurrentPage(1);
            // setPostIds(postIds);
          }}
        />
        {/* )} */}
        {/* {showTag && query && (
          <Tag
            closable
            className="tag-style"
            onClose={() => handleClose(query)}
          >
            <span onDoubleClick={(e) => {}}>{query}</span>
          </Tag>
        )} */}
      </div>
      <PostStructure
        username={username}
        user_id={user_id}
        component={component}
        PostIdData={data?.pages?.[0]}
        postLoading={isLoading}
        localPostIds={localPostIds}
        setLocalPostIds={setLocalPostIds}
        handleFeed={handleFeed}
        type={component?.type}
        contentType="all"

      />
      {pages?.feedCount > POSTS_PER_PAGE && (
        <Pagination
          className="pagination"
          current={currentPage}
          total={pages?.feedCount}
          size="small"
          showSizeChanger={false}
          showQuickJumper
          pageSize={POSTS_PER_PAGE}
          onChange={(page: number) => setCurrentPage(page)}
        />
      )}
    </>
  );
};

// const AddedContentTable=(props:any)=>{
//     return (   )
// }

const PostStructure = (props: any) => {
  const {
    username,
    user_id,
    component,
    PostIdData,
    isLoading,
    localPostIds,
    setLocalPostIds,
    type,refetchAddedContent,contentType
  } = props;
  const [value, setValue] = useState<string>();

  const [edit, setEdit] = useState(false);
  const [editIndex, setEditIndex] = useState("");
  const SkeletonStatBox = () => {
    return (
      <div className="skeletonDiv">
        <Skeleton className="skeletonStyle" />
      </div>
    );
  };
  const debouncedSetCheckedValues = _.debounce((updatedArray) => {
    // setCheckedValues(updatedArray);
    props.handleFeed(updatedArray);
    setLocalPostIds(updatedArray);
  }, 100);
  const onAdd = useCallback(
    (post_id: string) => {
      const updatedCheckedValues = [post_id, ...localPostIds];
      debouncedSetCheckedValues(updatedCheckedValues);
      // if (Array.isArray(checkedValues)) {
      //   const updatedCheckedValues = [post_id, ...checkedValues];
      //   debouncedSetCheckedValues(updatedCheckedValues);
      // }
    },
    [props]
    // [checkedValues, props]
  );
  // const onMinus = (post_id: string) => {

  //   let updatedPostArray = checkedValues.filter(
  //     (postId: any) => postId !== post_id
  //   );

  //   setCheckedValues(updatedPostArray);
  //   props.handleFeed(updatedPostArray);
  //   // setCheckedValues(updatedPostArray);
  // };

  const onMinus = useCallback(
    (post_id: string) => {
      let updatedPostArray = localPostIds.filter(
        (postId: any) => postId !== post_id
      );
      debouncedSetCheckedValues(updatedPostArray);
      // Filter out the post_id to remove it from the checkedValues array
      // let updatedPostArray = checkedValues.filter(
      //   (postId: string) => postId !== post_id
      // );
      // debouncedSetCheckedValues(updatedPostArray);
      // Update state and pass to parent component's handleFeed
      // setCheckedValues(updatedPostArray);
      // props.handleFeed(updatedPostArray);
    },
    [props]

    // [checkedValues, props]
  );
  const handleDone = async (post_id: string) => {
    const postData = PostIdData.find((post: any) => post.post_id === post_id);
    postData.product_name = value;
    if (type === "content"||type==="query-content") {
      const { data: user, error } = await supabase
        .from(`${username}`)
        .update({ content_title: value })
        .eq("post_id", post_id)
        .eq("user_id", user_id);
      const { data: newContent, error: newErroor } = await supabase
        .from("product_bank")
        .update({ content_title: value })
        .eq("product_id", post_id)
        .eq("user_id", user_id);
      postData.content_title = value;
    } else {
      const { data: user, error } = await supabase
        .from(`${username}`)
        .update({ shop_title: value })
        .eq("post_id", post_id)
        .eq("user_id", user_id);
      const { data: newContent, error: newErroor } = await supabase
        .from("product_bank")
        .update({ shop_title: value })
        .eq("product_id", post_id)
        .eq("user_id", user_id);
      postData.shop_title = value;
    }
    // setIsChange(true);

    const { data: newContent, error: newErroor } = await supabase
      .from("content_new")
      .update({ product_name: value })
      .eq("post_id", post_id)
      .eq("user_id", user_id);

    // refetch();
    // PostIdDataRefetch();
    // refetchDashboard();
    refetchAddedContent()
    setEdit(false);
    setEditIndex("");
  };
  return (
    <>
      {isLoading ? (
        <div className="data-skeleton">
          {[...Array(3)].map((_, index) => (
            <div className={"m-0px"}>
              <Skeleton
                className="skeleton"
                style={{ marginRight: "20px", width: "140px", height: "220px" }}
              />
            </div>
          ))}
        </div>
      ) : (
        <>
          {PostIdData?.filter((element: any) => element !== undefined) ? (
            <div className="addedSvg">
              {PostIdData.filter((element: any) => element !== undefined)
                .length ? (
                PostIdData.filter((element: any) => element !== undefined).map(
                  (post: any, index: any) => (
                    <React.Fragment key={index}>
                      <div
                        key={index}
                        className="feed-tab-post-container added-post"
                      >
                        <div
                          className="feed-tab-media-wrapper"
                          style={
                            post?.platform === "newsletter"
                              ? {
                                  backgroundColor: "#efeded",
                                  textAlign: "center",
                                }
                              : {}
                          }
                        >
                          {/* <Button
                            // onClick={() => onMinus(post.post_id)}
                            className="carousel-content-remove-btn"
                          >
                            Remove
                          </Button> */}

                          {Array.isArray(localPostIds) &&
                          localPostIds?.includes(post.post_id) ? (
                            <Button
                              onClick={() => onMinus(post.post_id)}
                              className="carousel-content-remove-btn"
                            >
                              Remove
                            </Button>
                          ) : (
                            <Button
                              onClick={() => onAdd(post.post_id)}
                              className="carousel-content-add-btn"
                            >
                              Add
                            </Button>
                          )}

                          {post?.platform !== "newsletter" ? (
                            <>
                              {post?.media_url ? (
                                <>
                                  {post?.media_url.split(",").length > 1 &&
                                  post?.media_url
                                    .split(",")
                                    .every((url: any) => url.trim() !== "") ? (
                                    <Carousel
                                      dotPosition="bottom"
                                      className="feed-tab-media-carousel"
                                    >
                                      {post?.media_url
                                        .split(",")
                                        .map((media_url: any, index: any) => (
                                          <React.Fragment key={index}>
                                            {media_url.includes("video") ? (
                                              <div className="h-255">
                                                <VideoComponent
                                                  media_url={media_url}
                                                  thumbnail={
                                                    post.thumbnail &&
                                                    post.thumbnail.split(",")
                                                      .length &&
                                                    post.thumbnail.split(",")[
                                                      index
                                                    ]
                                                      ? post.thumbnail.split(
                                                          ","
                                                        )[index]
                                                      : "/assets/dummy_video.png"
                                                  }
                                                />
                                              </div>
                                            ) : (
                                              <img
                                                className="feed-tab-media-check"
                                                src={media_url}
                                                alt="post"
                                              />
                                            )}
                                          </React.Fragment>
                                        ))}
                                    </Carousel>
                                  ) : (
                                    <>
                                      {post.media_url.includes("video") ? (
                                        <div className="h-255">
                                          <VideoComponent
                                            media_url={post.media_url}
                                            thumbnail={post.thumbnail}
                                          />
                                        </div>
                                      ) : (
                                        <img
                                          className="feed-tab-media-check"
                                          src={
                                            post.media_url
                                              ? post.media_url
                                              : "/assets/dummy_video.png"
                                          }
                                          alt="post"
                                        />
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <img
                                  className="feed-tab-media-check"
                                  src={
                                    post?.thumbnail
                                      ? post?.thumbnail
                                      : "/assets/dummy_video.png"
                                  }
                                  alt="post"
                                />
                              )}
                            </>
                          ) : (
                            <div>
                              <h4 className="mt-7">
                                {new Intl.DateTimeFormat("en-US", {
                                  year: "numeric",
                                  day: "numeric",
                                  month: "long",
                                }).format(new Date(post?.post_date))}
                              </h4>
                              <h4> Newsletter</h4>
                            </div>
                          )}
                        </div>
                       {contentType!=="all"?<> {edit === true && editIndex === index ? (
                        <>
                          <Input
                            value={value}
                            onChange={(e: any) => setValue(e.target.value)}
                            className="w-80"
                          />{" "}
                          <HiCheck
                            onClick={() => {
                              handleDone(post.post_id);
                            }}
                          />
                        </>
                      ) : (
                        <p
                          style={{
                            textAlign:
                              post?.shop_title !== "" ||
                              post?.product_name !== "" ||
                              post?.content_title !== ""
                                ? "left"
                                : "center",
                          }}
                        >
                          {getPostTitle(post, type)}
                          <HiPencil
                            onClick={() => {
                              const nameValue = getPostTitle(post, type);
                              setValue(nameValue);
                              setEdit(true);
                              setEditIndex(index);
                            }}
                          />
                        </p>
                      )}</>:null}
                      </div>
                    </React.Fragment>
                  )
                )
              ) : (
                <React.Fragment>
                  {/* <p>No Added Data</p> */}
                  <>
                    <div
                      // key={index}
                      // className="feed-tab-post-container"
                      className="feed-tab-post"
                    >
                      {[...Array(4)].map((_, index) => (
                        <SkeletonStatBox key={index} />
                      ))}
                    </div>
                  </>
                </React.Fragment>
              )}
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default EditFeedComponent;
