import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "../../../styles/skeleton.css";
import { StaticVariables } from "../../../Utils/StaticVariables";

const AccountSkeleton = () => {
  function SkeletonStatBox() {
    return (
      <div className="skeleton-account-grid-item">
        <Skeleton />
      </div>
    );
  }
  return (
    <div>
      <>
        <div className="details">
          <h1 className="dashboard-mobile-hide">
            {StaticVariables().ACCOUNT_TITLE}
          </h1>
          <div className="row">
            <div className="skeleton-val">
              {[...Array(4)].map((_, index) => (
                <SkeletonStatBox key={index} />
              ))}
            </div>
          </div>
        </div>
      </>
      {/* )} */}
    </div>
  );
};

export default AccountSkeleton;
