import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAuth } from "../../../hooks/useAuth";
import { useParams } from "react-router-dom";
import { useLoadPartialTableCount } from "../Feed/useLoadPartialTableCount";
import { useLoadFirstEmbedding } from "./useLoadFirstSimilarity";
import { useLoadEmbeddingCount } from "./useLoadEmbeddingCount";
import { useLoadPages } from "./useLoadPages";
import { useLoadPosts } from "./useLoadPosts";
import { useLoadNecData } from "./useLoadNecData";
import { useLoadEmbeddingPosts } from "./useLoadEmbeddingPosts";
import { useLoadUser } from "../../../Utils/customHooks/useLoadUser";
import { Button, Col, Modal, Pagination, PaginationProps, Row } from "antd";
import { ContentSkeleton } from "../../../components/skeleton/DashboardView/feedSkeleton";
import TabOptions from "../Feed/TabOptions";
import SearchInput from "../../../components/SearchInput/SearchInput";
import BrokenLinkContent from "../Feed/BrokenLinkContent";
import ContentSearchTable from "./ContentSearchTable";
import { useLoadBrokenPages } from "./useLoadBrokenPages";
import { useLoadBrokenPosts } from "./useLoadBrokenPosts";
import { StaticVariables } from "../../../Utils/StaticVariables";
import {
  processEmbeddingPages,
  processNecData,
  processPages,
} from "../../../Utils/SearchProcessLayers/SearchProcessLayer";
import { PlusOutlined } from '@ant-design/icons';
import CreateNewContentPost from "../StayUpToDate/StayUpToDateControlsComponents/ContentComponent/ContentPost/CreateNewContentPost";

const POSTS_PER_PAGE = 10;

const FeedNew = (props: any) => {
  const { subscription_type } = props;
  const { user } = useAuth();
  const { username } = useParams();
  const user_id = user?.id || "";
  const [activeKey, setActiveKey] = useState<any>("active");
  const [isLimitError, setLimitError] = useState(false);
  const [activeFilter, setActiveFilter] = useState<string | null>(null);
  const [currentBrokenPage, setCurrentBrokenPage] = useState(1);
  const [currentDataPage, setCurrentDataPage] = useState(1);
  const [pageSize, setPageSize] = useState(POSTS_PER_PAGE); // Default page size

  const handleOk = () => {
    setLimitError(false);
  };
  const handleCancel = () => {
    setLimitError(false);
  };
  const { isLoading: userLoading, data: userData }: any = useLoadUser(username);
  const [iscontentLoading, setIsContentLoading] = useState(false);
  const [isCreateContentModalOpen, setIsContentModalOpen] = useState(false);
  const [addContentModalShow, setAddContentModalShow] = useState(false);
  // const [isContentDataFullyLoading, setIsContentDataFullyLoading] = useState(true);
  const [allData, setAllData] = useState<any>([])

  const { data: brokenPages, isLoading: brokenPagesLoading } =
    useLoadBrokenPages(
      user_id || "",
      activeKey,
      pageSize,
      activeFilter,
      username
    );
  const {
    isLoading: brokenDataLoading,
    // isError,

    data: brokenData,
    hasNextPage: hasNextBrokenPage,
    fetchNextPage: fetchNextBrokenPage,
    // isFetching,
    isFetchingNextPage: isFetchingNextBrokenPage,
    refetch: refetchBrokenData,
  } = useLoadBrokenPosts(
    currentBrokenPage,
    activeKey,
    pageSize,
    brokenPages?.totalPages,
    activeFilter,
    username,
    user_id || "",
    brokenPages?.postIds
  );





  const { value }: any = useLoadPartialTableCount(user_id, username);

  const { isLoading: pagesLoading, data: pages, refetch: refetchPageCount } = useLoadPages(
    user_id || "",
    activeKey,
    pageSize,
    activeFilter,
    username
  );
  const { isLoading: pagesFirstEmbedLoading, data: firstEmbedding }: any =
    useLoadFirstEmbedding(
      username || "",
      user_id || "",
      activeFilter,

      ["instagram", "youtube", "tiktok", "podcast", "pinterest", "newsletter"],
      pages?.feedCount
    );

  const { isLoading: pagesEmbedLoading, data: pagesEmbed } =
    useLoadEmbeddingCount(
      user_id || "",
      activeKey,
      pageSize,
      activeFilter,
      username,
      firstEmbedding?.threshold
    );

  const {
    isLoading,
    // isError,
    error,
    data,
    hasNextPage,
    fetchNextPage,
    // isFetching,
    isFetchingNextPage,
    refetch,
  } = useLoadPosts(
    currentDataPage,
    activeKey,
    pageSize,
    pages?.totalPages,
    activeFilter,
    username,
    user_id || "",
    pages?.postIds
  );

  const {
    isLoading: necLoading,
    // isError,
    error: necError,
    data: necData,

    refetch: refetchNec,
  }: any = useLoadNecData(
    user_id || "",
    activeKey,
    pageSize,
    activeFilter,
    username
  );
  const {
    isLoading: embedLoading,
    // isError,
    error: embedError,
    data: embedData,
    hasNextPage: hasEmbedNextPage,
    fetchNextPage: fetchEmbedNextPage,
    // isFetching,
    // isFetchingNextPage,
    refetch: refectchEmbed,
  }: any = useLoadEmbeddingPosts(
    currentDataPage,
    activeKey,
    pageSize,
    pagesEmbed?.totalPages,
    activeFilter,
    username,
    user_id || "",
    pagesEmbed?.postIds,
    firstEmbedding?.threshold
  );

  useEffect(() => {
      refetchPageCount()
      setAllData(data)
  }, [data?.pages[0]]);

  let necDataIncluded = false;

  const loadingRef = useRef(true); // useRef for mutable reference

  const logProcessStartEnd = (fn: any, ...args: any) => {
    let result;
    try {
      result = fn(...args);

      loadingRef.current = false; // Set loading to false

      // setMergedDataLoading(result); // Set the result in state
    } catch (error) {
    } finally {
      loadingRef.current = false; // Set loading to false
    }
    return result;
  };
  const mergedData: any =
    pages && pages?.totalPages !== 0 && allData?.pages
      ? logProcessStartEnd(
        processPages,
        allData.pages,
        pages?.feedCount,
        necData,
        necLoading,
        necDataIncluded,
        embedData?.pages
      )
      : pagesEmbed && pagesEmbed?.totalPages != 0
        ? logProcessStartEnd(
          processEmbeddingPages,
          userData,
          embedData?.pages,
          pagesEmbed?.feedCount,
          necDataIncluded,
          necData
        )
        : necData?.length > 0
          ? logProcessStartEnd(processNecData, necData, necDataIncluded)
          : "";

  const handleActiveFilter = () => {
    // const elem: any = document.getElementById("search-feed");
    // elem.value = "";
    // setActiveFilter(null);
    // setActiveFilter("")
  };

  const handlePageChange = (page: any) => {
    setCurrentBrokenPage(page);
  };
  const handlePageDataChange = (page: any) => {
    setCurrentDataPage(page);
  };

  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    current,
    pageSize
  ) => {
    setPageSize(pageSize);
  };

  const isBrokenDataFullyLoading = brokenDataLoading && brokenPagesLoading;

  const isContentDataFullyLoading = useMemo(() => {
    return (
      isLoading ||
      pagesLoading ||
      pagesFirstEmbedLoading ||
      pagesEmbedLoading ||
      iscontentLoading
    );
  }, [isLoading, pagesLoading, pagesEmbedLoading, iscontentLoading, allData]);

  return (
    <div>
      <div className="d-side">
        <h1 className="dashboard-mobile-hide">
          {StaticVariables().CONTENT_TITLE}
        </h1>

        <TabOptions
          activeKey={activeKey}
          setActiveKey={setActiveKey}
          handleActiveFilter={handleActiveFilter}
          subscription_type={subscription_type}
          brokenLinksContent={brokenData}
          loadingRef={loadingRef}
          setActiveFilter={setActiveFilter}
        />
      </div>

      <div className="detail-margin">
        <Row gutter={16} align="middle" wrap={false}>
          {pages?.feedCount != 0 && (activeKey === "active") && (
            <Col >
              {/* <Button
                  className="product-bank-add-btn"
                  onClick={() => setAddProductModalShow(true)}
                >
                  Add Product
                </Button> */}
              <Button
                icon={<PlusOutlined />}
                className="product-bank-add-btn-icon"
                onClick={() => setAddContentModalShow(true)}
              />
            </Col>
          )}
          <Col flex="auto">
            <SearchInput
              placeholder={
                activeKey === "active,ad"
                  ? `Search all ads`
                  : activeKey === "active,featured"
                    ? `Search all featured content`
                    : `Search all ${activeKey} content`
              }
              id="search-feed"
              handleKeyUp={(e: any) => {
                e.preventDefault();
                if (e.keyCode === 13) {
                  const elem: any = document.getElementById("search-feed");
                  const searchVal = e.target.value.trim();
                  if (searchVal.length === 0) {
                    elem.blur();
                    setActiveFilter(null);
                    setCurrentBrokenPage(1);
                    setCurrentDataPage(1);
                    return;
                  }
                  elem.blur();
                  setActiveFilter(searchVal);
                  setCurrentBrokenPage(1);
                  setCurrentDataPage(1);
                }
              }}
              isActiveFilter={Boolean(activeFilter)}
              onSearchClick={() => {
                const elem: any = document.getElementById("search-feed");
                const searchVal = elem.value;
                if (searchVal.length === 0) {
                  return;
                }
                elem.blur();
                setActiveFilter(searchVal);
                setCurrentBrokenPage(1);
                setCurrentDataPage(1);
              }}
              onClearClick={() => {
                const elem: any = document.getElementById("search-feed");
                elem.value = "";
                setActiveFilter(null);
                setCurrentBrokenPage(1);
                setCurrentDataPage(1);
              }}

            />
          </Col>
        </Row>
      </div>

      {activeKey === "broken_links" ? (
        <>
          {isBrokenDataFullyLoading ? (
            <ContentSkeleton />
          ) : (
            <>
              <BrokenLinkContent
                username={username}
                user_id={user_id}
                activeKey={activeKey}
                data={brokenData}
                setLimitError={setLimitError}
                filter={activeFilter}
                refetchData={refetchBrokenData}
                setIsContentLoading={setIsContentLoading}
                subscription_type={subscription_type}
                brokenDataLoading={brokenDataLoading && brokenPagesLoading}
              // brokenLinksContent={aaa}
              />
              {brokenPages?.feedCount && brokenPages?.feedCount > pageSize && (
                <Pagination
                  showQuickJumper
                  current={currentBrokenPage}
                  pageSize={pageSize}
                  total={brokenPages?.feedCount}
                  onChange={handlePageChange}
                  onShowSizeChange={onShowSizeChange}
                  // showSizeChanger={false}
                  className="pagination-data-change"
                />
              )}
            </>
          )}
        </>
      ) : (
        <>
          {" "}
          {isContentDataFullyLoading ? (
            <ContentSkeleton />
          ) : value ? (
            <>
              <ContentSearchTable
                username={username}
                user_id={user_id}
                activeKey={activeKey}
                data={mergedData}
                setLimitError={setLimitError}
                filter={activeFilter}
                refetchData={refetch}
                setIsContentLoading={setIsContentLoading}
                subscription_type={subscription_type}
                isLoading={isLoading}
                loadingRef={loadingRef.current}
                totalSize={
                  (pages?.feedCount || 0) + (pagesEmbed?.feedCount || 0)
                }
              />
              {(pages?.feedCount || 0) + (pagesEmbed?.feedCount || 0) && (pages?.feedCount || 0) + (pagesEmbed?.feedCount || 0) > pageSize ? (
                <Pagination
                  showQuickJumper
                  current={currentDataPage}
                  pageSize={pageSize}
                  total={(pages?.feedCount || 0) + (pagesEmbed?.feedCount || 0)}
                  onChange={handlePageDataChange}
                  // showSizeChanger={false}
                  onShowSizeChange={onShowSizeChange}
                  className="pagination-data-change"
                />
              ) : ""}
            </>
          ) : (
            ""
          )}
        </>
      )}
      <Modal
        title="Only 2 posts can be added as ads."
        open={isLimitError}
        onOk={handleOk}
        onCancel={handleCancel}
        className="account-modal"
      ></Modal>
      <CreateNewContentPost
        user_id={user_id}
        username={username}
        isCreateContentModalOpen={addContentModalShow}
        setIsCreateContentModalOpen={setAddContentModalShow}
        // setIsModalOpen={setIsModalOpen}
        from_id="FeedNew"
        refetchData={refetch}
      />
    </div>
  );
};

export default FeedNew;
