import { useState } from "react";
import { supabase } from "../../config/supabaseClient";
import { useQuery } from "react-query";
import {
  // fetchSearchResultsCount,
  getEmbedding,
  getQuerySuggestions,
  removeQualifiers,
} from "../SupabaseServices/ContentDatabase";
import { lemmatizer } from "lemmatizer";
import { StaticVariables } from "../../Utils/StaticVariables";
import dayjs from "dayjs";

/**
 *  Use this hook to control feed pagination
 * @param userId
 * @param index - "active" || "archived"
 * @param postsPerPage
 * @returns
 */
export function useFeedPostsPagination(
  username: any,
  user_id: any[],
  queryTerm: any,
  category: any,
  postsPerPage: number,
  filter: any[],
  activeHighlights: any,
  openAiSearch: boolean,
  extraId: any[],
  trending: any,
  showSocials: any[],
  checkedType: any,
  defaultCategory: any,
  contentOrder:any
) {
  queryTerm = queryTerm.toLowerCase().replace(/\s+/g, " ").trim();
  category = category && category.trim();
  trending = trending?.trim();

  async function getCount() {
    let feedCount;
    let postIds: any = [];
    const filteredArray = filter.filter((item: any) => item !== checkedType);
    if (user_id && user_id.length === 1) {
      if (category) {
        if (defaultCategory === queryTerm) {
          queryTerm = "";
        }
        if (
          category === checkedType ||
          category === "Top Hashtags" ||
          category === "Popular Searches" ||
          category === "Content Type" ||
          category === "My Links"
        ) {
          if (queryTerm) {
            const { data: content, error: contentError } = await supabase.rpc(
              "get_generalfffeedcount",
              {
                userid: user_id[0],
                // start_number: start,
                // end_num: end,
                filter: filteredArray,
                query_str: queryTerm,
                user_name: username,
                socials: showSocials,
                // extra_ids: extraId,
              }
            );
            feedCount = content[0].count;
          } else {
            if (checkedType === "Feed") {
              const { data: content, error: contentError } = await supabase.rpc(
                "get_generalfffeedcount",
                {
                  userid: user_id[0],
                  // start_number: start,
                  // end_num: end,
                  filter: ["REELS", "FEED"],
                  query_str: queryTerm,
                  user_name: username,
                  socials: showSocials,

                  // extra_ids: extraId,
                }
              );

              feedCount = content[0].count;
            } else if (checkedType === "Featured") {
              const { data: content, error: contentError } = await supabase.rpc(
                "get_featuredfeedcount",
                {
                  userid: user_id[0],
                  // start_number: start,
                  // end_num: end,
                  filter: filteredArray,
                  query_str: queryTerm,
                  user_name: username,
                  socials: showSocials,

                  // extra_ids: extraId,
                }
              );

              feedCount = content[0].count;
            } else {
              const { data: content, error: contentError } = await supabase.rpc(
                "get_generalfffeedcount",
                {
                  userid: user_id[0],
                  // start_number: start,
                  // end_num: end,
                  filter: filteredArray,
                  query_str: queryTerm,
                  user_name: username,
                  socials: showSocials,

                  // extra_ids: extraId,
                }
              );
              feedCount = content[0].count;
            }
          }
        } else if (category === "searchbar") {
          const elementsCount = 5000;
          const connectingWords = StaticVariables().connectingWords;

          // Split the sentence into words
          let words = queryTerm.split(" ");

          let singleWords: any = [];
          if (
            words.length === 1 &&
            !StaticVariables().SEARCH_EXCEPTIONS.includes(queryTerm)
          ) {
            const suffixRegex = /(s)$/i;
            if (suffixRegex.test(words[0])) {
              words = [`${words[0].replace(suffixRegex, "")}`];
            }
          }
          if (words.includes("shop") || words.includes("buy")) {
            // contentOrder="shoppable"

            words = words.filter(
              (word: any) => word !== "shop" && word !== "buy"
            );
          }

          // Filter out connecting words
          let filteredWords = words.filter(
            (word: any) => !connectingWords.includes(word.toLowerCase())
          );

          if (words.length <= 2) {
            // Join the filtered words back into a sentence
            const result = filteredWords.join(" ");
            const pgQuery = result.toLowerCase().replace(/\s+/g, " & ");
            // const embedding = await getEmbedding(pgQuery);
            const { data: countResult, error: countError }: any =
              await supabase.rpc(
                "semantic_searchcountftsorder",
                // "semantic_ssearchcountftspostidd",
                
                {
                  query_string: pgQuery,
                  // query_embedding: embedding,
                  similarity_threshold: 0.8,
                  match_count: elementsCount,
                  userid: user_id[0],
                  username: username,
                  socials: showSocials,
                  single: singleWords[0] || null,
                  plural: singleWords[1] || null,
                  content_order: contentOrder.split(" ")[0].toLowerCase(),

                  // filter:filter
                }
              );

            const { data, error } = await supabase.rpc(
              "get_productbankcountti",
              {
                query_string: queryTerm || "",
                userid: user_id[0],
                query_tagg: queryTerm || "",
                index_type: "active",
              }
            );
            let resCount = countResult.length + data?.[0]?.count;
            // let resCount = countResult.length;
            postIds = countResult.map((item: any) => item.post_id);
            feedCount = resCount;
          } else {
            feedCount = 0;
          }
        } else if (category === "Instagram Highlights") {
          const { data: content, error: contentError } = await supabase.rpc(
            "get_highlightsfeedcount",
            {
              userid: user_id[0],
              // start_number: start,
              // end_num: end,
              filter: ["HIGHLIGHTS"],
              query_str: queryTerm,
              user_name: username,

              // extra_ids: extraId,
            }
          );
          // feedCount=content.length

          feedCount = content[0].count;
        } else if (category.includes("Shoppable")) {
          const { data: content, error: contentError } = await supabase.rpc(
            "get_generalfffeedcountshop",
            {
              userid: user_id[0],
              // start_number: start,
              // end_num: end,
              filter: filteredArray,
              query_str: queryTerm,
              user_name: username,
              socials: showSocials,

              // extra_ids: extraId,
            }
          );

          feedCount = content[0].count;
        } else if (category.includes("Yesterday's Content")) {
          const today = dayjs().format("YYYY-MM-DD");
          // Get yesterday's date
          const yesterday = dayjs().subtract(1, "day").format("YYYY-MM-DD");

          if (queryTerm) {
            if (queryTerm === "all") {
              const { data: content, error: contentError } = await supabase.rpc(
                "get_storiesfeedcount",
                {
                  userid: user_id[0],
                  // start_number: start,
                  // end_num: end,
                  filter: filteredArray,
                  query_str: "",
                  user_name: username,
                  start_date: yesterday,
                  end_date: today,

                  // extra_ids: extraId,
                }
              );
              // feedCount=content.length
              feedCount = content[0].count;
            } else if (queryTerm.includes("shoppable")) {
              const { data: content, error: contentError } = await supabase.rpc(
                "get_storiesfeedcountshop",
                {
                  userid: user_id[0],
                  // start_number: start,
                  // end_num: end,
                  filter: filteredArray,
                  query_str: "",
                  user_name: username,
                  start_date: yesterday,
                  end_date: today,

                  // extra_ids: extraId,
                }
              );
              // feedCount=content.length
              feedCount = content[0].count;
            }
          } else {
            const { data: content, error: contentError } = await supabase.rpc(
              "get_storiesfeedcount",
              {
                userid: user_id[0],
                // start_number: start,
                // end_num: end,
                filter: filteredArray,
                query_str: "",
                user_name: username,
                start_date: yesterday,
                end_date: today,

                // extra_ids: extraId,
              }
            );
            // feedCount=content.length
            feedCount = content[0].count;
          }
        } else if (category.includes("Last 7")) {
          const today = dayjs().format("YYYY-MM-DD");

          const sevenDayDate = dayjs().subtract(7, "day").format("YYYY-MM-DD");

          if (queryTerm) {
            if (queryTerm === "all") {
              const { data: content, error: contentError } = await supabase.rpc(
                "get_storiesfeedcount",
                {
                  userid: user_id[0],
                  // start_number: start,
                  // end_num: end,
                  filter: filteredArray,
                  query_str: "",
                  user_name: username,
                  start_date: sevenDayDate,
                  end_date: today,

                  // extra_ids: extraId,
                }
              );

              // feedCount=content.length
              feedCount = content[0].count;
            } else if (queryTerm.includes("shoppable")) {
              const { data: content, error: contentError } = await supabase.rpc(
                "get_storiesfeedcountshop",
                {
                  userid: user_id[0],
                  // start_number: start,
                  // end_num: end,
                  filter: filteredArray,
                  query_str: "",
                  user_name: username,
                  start_date: sevenDayDate,
                  end_date: today,

                  // extra_ids: extraId,
                }
              );
              // feedCount=content.length
              feedCount = content[0].count;
            }
          } else {
            const { data: content, error: contentError } = await supabase.rpc(
              "get_storiesfeedcount",
              {
                userid: user_id[0],
                // start_number: start,
                // end_num: end,
                filter: filteredArray,
                query_str: queryTerm,
                user_name: username,
                start_date: sevenDayDate,
                end_date: today,

                // extra_ids: extraId,
              }
            );

            // feedCount=content.length
            feedCount = content[0].count;
          }
        } else {
          if (queryTerm) {
            const { data: content, error: contentError } = await supabase.rpc(
              "get_categoryfeedcount",
              {
                userid: user_id[0],
                // start_number: start,
                // end_num: end,
                filter: filteredArray,
                query_str: `|${queryTerm}|`,
                user_name: username,
                category: category,
                // extra_ids: extraId,
              }
            );
            // feedCount=content.length

            feedCount = content[0].count;
          } else {
            const { data: content, error: contentError } = await supabase.rpc(
              "get_highlightsfeedcount",
              {
                userid: user_id[0],
                // start_number: start,
                // end_num: end,
                filter: filteredArray,
                query_str: category,
                user_name: username,
                // extra_ids: extraId,
              }
            );
            // feedCount=content.length
            feedCount = content[0].count;
          }
        }
      } else {
        if (trending === checkedType) {
          // const searchQueryStr: any = await getQuerySuggestions(
          //   queryTerm,
          //   openAiSearch,
          //   extraId,
          //   category,
          //   activeHighlights
          // );

          // const { data: content, error: contentError } = await supabase.rpc(
          //   "get_generalfffeedcount",
          //   {
          //     userid: user_id[0],
          //     // start_number: start,
          //     // end_num: end,
          //     filter: filteredArray,
          //     query_str: queryTerm,
          //     user_name: username,
          //     socials: showSocials,
          if (checkedType === "Feed") {
            const { data: content, error: contentError } = await supabase.rpc(
              "get_generalfffeedcount",
              {
                userid: user_id[0],
                // start_number: start,
                // end_num: end,
                filter: ["REELS", "FEED"],
                query_str: queryTerm,
                user_name: username,
                socials: showSocials,

                // extra_ids: extraId,
              }
            );

            feedCount = content[0].count;
          } else if (checkedType === "Featured") {
            const { data: content, error: contentError } = await supabase.rpc(
              "get_featuredfeedcount",
              {
                userid: user_id[0],
                // start_number: start,
                // end_num: end,
                filter: filteredArray,
                query_str: queryTerm,
                user_name: username,
                socials: showSocials,

                // extra_ids: extraId,
              }
            );

            feedCount = content[0].count;
          } else {
            const { data: content, error: contentError } = await supabase.rpc(
              "get_generalfffeedcount",
              {
                userid: user_id[0],
                // start_number: start,
                // end_num: end,
                filter: filteredArray,
                query_str: queryTerm,
                user_name: username,
                socials: showSocials,

                // extra_ids: extraId,
              }
            );

            feedCount = content[0].count;
          }
        } else if (trending === "Most Liked") {
          const { data: content, error: contentError } = await supabase.rpc(
            "get_likesorderedcount",
            {
              userid: user_id[0],

              filter: filteredArray,
              query_str: queryTerm,
              extra_ids: extraId,
            }
          );
          feedCount = content.length;
        } else if (trending === "Most Commented") {
          const { data: content, error: contentError } = await supabase.rpc(
            "get_commentsorderedcount",
            {
              userid: user_id[0],
              filter: filteredArray,
              query_str: queryTerm,
              extra_ids: extraId,
            }
          );
          feedCount = content.length;
        } else if (trending === "Most Reached") {
          const { data: content, error: contentError } = await supabase.rpc(
            "get_reachorderedcount",
            {
              userid: user_id[0],

              filter: filteredArray,
              query_str: queryTerm,
              extra_ids: extraId,
            }
          );
          feedCount = content.length;
        } else if (trending === "Most Engaged") {
          const { data: content, error: contentError } = await supabase.rpc(
            "get_engagementorderedcount",
            {
              userid: user_id[0],

              filter: filteredArray,
              query_str: queryTerm,
              extra_ids: extraId,
            }
          );
          feedCount = content.length;
        } else if (trending === "Most Viewed") {
          const { data: content, error: contentError } = await supabase.rpc(
            "get_impressionorderedcount",
            {
              userid: user_id[0],
              filter: filteredArray,
              query_str: queryTerm,
              extra_ids: extraId,
            }
          );
          feedCount = content.length;
        }
      }
    } else {
      //-----for global page.
      const searchQueryStr: any = await getQuerySuggestions(
        queryTerm,
        openAiSearch,
        extraId,
        category,
        activeHighlights
      );
      let { count }: any = await supabase
        .from("content_new")
        .select("*", { count: "exact", head: true })
        .in("user_id", user_id)
        .in("type", filter)
        .or(searchQueryStr.query)
        .ilike("index", "%active%");
      feedCount = count;
    }

    // if (feedCount) {
    return {
      totalPages: Math.ceil(feedCount / postsPerPage),
      feedCount: feedCount,
      postIds: postIds || null,
    };
    // }
    // else {
    //   // return 0;
    //   return {
    //     totalPages: Math.ceil(feedCount / postsPerPage),
    //     feedCount: feedCount,
    //     postIds:postIds || null
    //   };
    // }
  }

  return useQuery(
    ["getFeedPageCount", filter, queryTerm, trending, category],
    getCount,
    {
      // refetchOnWindowFocus: true,
    }
  );
}
