import {
  Alert,
  Button,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Space,
  Spin,
} from "antd";
import React, { useState } from "react";
import {
  fetchAllPodcastEpisodes,
  storePodcastChannel,
} from "../../../Utils/SupabaseServices/ContentDatabase";
import { useAuth } from "../../../hooks/useAuth";
import { useParams } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";

const PodcastIntegrationModal = (props: any) => {
  const { user } = useAuth();
  const { username } = useParams();
  const { showPodcastModal, user_id, data } = props;
  const [podcastShowLink, setPodcastShowLink] = useState(data?.podcast_link);
  const [podcastShowName, setPodcastShowName] = useState(data?.podcast_artist);

  const [podcastId, setPodcastId] = useState(data?.podcast_link);
  const [errorPodcast, setErrorPodcast] = useState("");
  const [loadingPodcastConnect, setLoadingPodcastConnect] = useState(false);
  const [podcastsList, setPodcastsList] = useState<any>([]);
  const [showPodcastListModal, setShowPodcastListModal] = useState(false);
  const [showChannelIdInstructions, setShowChannelIdInstructions] =
    useState(false);
  const [value, setValue] = useState();

  const onChange = async (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

 

  const handleCancel = () => {
    setShowPodcastListModal(false);
  };

  async function fetchAndStorePodcast() {
    setLoadingPodcastConnect(true);


    // Regular expression to match the show ID in the URL
    const regex = /\/show\/(\w+)\?/;

    // Use the regex to extract the show ID
    const match = podcastShowLink?.match(regex);

    // Check if there's a match and get the show ID
    const showId = match ? match[1] : "";

    setPodcastId(showId);

    if (showId && showId!==null && showId!="") {
      const res = await storePodcastChannel(
        user?.id as string,
        podcastShowLink,
        showId,
        podcastShowName,
        username
      );
      if (res) {
       const fetch= await fetchAllPodcastEpisodes(
          username,
          user?.id as string,
          podcastShowLink,
          showId,
          podcastShowName
        );
        if(fetch){
          setErrorPodcast("");
          setLoadingPodcastConnect(false);
      // props.setShowPodcastModal(false)
      props.onClose();

        }else{
          setErrorPodcast("Error while connecting to podcast. Contact Server Support")
          setLoadingPodcastConnect(false);


        }
      }
      // if (!res) {
      //   setErrorPodcast("error while connecting to this podcast artist");
      // } else if (res.length === 0) {
      //   setErrorPodcast("No podcast related to this artist name");
      // } else {
      //   setPodcastsList(res);
      //   setShowPodcastListModal(true);
      //   props.setShowPodcastModal(false);
      // }

      

    } else {
      setErrorPodcast("No podcast found with this url");
      setLoadingPodcastConnect(false);

    }
  }
  return (
    <>
      <Modal
        title="Connect to Podcast Show"
        open={showPodcastModal}
        data-testid="podcast-modal"
        onOk={async () => {
          await fetchAndStorePodcast();
          // const res = await storePodcastChannel(user?.id as string, podcastId);
          // if (!res) {
          //   setErrorPodcast("error while connecting to this podcast artist");
          // } else if (res.length === 0) {
          //   setErrorPodcast("No podcast related to this artist name");
          // } else {
          //   setPodcastsList(res);
          //   setShowPodcastListModal(true);
          //   props.setShowPodcastModal(false);
          // }
        }}
        onCancel={() => {
          setErrorPodcast("");
          setPodcastShowName(data?.podcast_artist)
          setPodcastShowLink(data?.podcast_link)
          props.onClose();
          // props.setShowPodcastModal(false);
        }}
        okText="Connect"
        okButtonProps={{disabled: !(podcastShowName && podcastShowLink), loading: loadingPodcastConnect }}
        cancelButtonProps={{ disabled: loadingPodcastConnect }}
      >
        <p>
          By connecting your podcast show, all your episodes will be displayed
          in the feed of your main page. In the content tab you will be able to
          hide or delete the shows you don't want to show.{" "}
        </p>
        {loadingPodcastConnect ? (
          <div
            className="spin-integration"
          >
            <Spin />
          </div>
        ) : (
          <>
            {errorPodcast && (
              <Alert
                type="error"
                message={`Error: ${errorPodcast}`}
                showIcon
                className="alert-margin"
              />
            )}
            {/*  {data?.podcast_artist ? (
              <>
                <p>
                  <b>
                    Already connected podcast artist: {data?.podcast_artist}
                  </b>
                </p>
                <p>New name will connection will replace this one.</p>
              </>
            ) : (
              ""
            )} */}
            <p>To connect enter your podcast name and show url :</p>
            <Input
              placeholder="show name"
              id="name-pod"
              value={podcastShowName}
              onChange={(e) => {
                const value = e.target.value;
                setPodcastShowName(value);
                if (!value) {
                  setErrorPodcast("Enter valid name");
                } else {
                  setErrorPodcast("");
                }
              }} 
             
            />
            <Input
              placeholder="show link"
              id="link-pod"
              value={podcastShowLink}
              className="mt-5"
              onChange={(e) => {
                const value = e.target.value;
                setPodcastShowLink(value);
                if (!value) {
                  setErrorPodcast("Enter valid link");
                } else {
                  setErrorPodcast("");
                }
              }}  
              
            />
            {!showChannelIdInstructions ? (
              <p
                className="account-social-content-channelid-text"
                onClick={() => setShowChannelIdInstructions(true)}
              >
                Find Your Show URL
              </p>
            ) : (
              <div className="account-social-content-channelid-ins">
                <p>
                  You can see your show url in{" "}
                  <a
                    href="https://open.spotify.com/?"
                    className="account-social-content-channelid-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    spotify app
                  </a>
                </p>
                <ol>
                  <li>Sign in to Spotify</li>
                  <li>
                    Search your show{" "}
                    <BsChevronRight className="chevron-right" />
                    Click the three dots <b>...</b>
                  </li>
                  <li>
                    Click <b>Share</b>.{" "}
                    <BsChevronRight className="chevron-right" />
                    Select <b>Copy Show Link </b> for the URL.{" "}
                  </li>
                  <li>
                    Paste the <b>Show Link</b> here.
                  </li>
                </ol>
              </div>
            )}
          </>
        )}
        {/* <Button type="primary" onClick={showModal}>
          Open Modal
        </Button> */}
      </Modal>
      {/* <Modal
        title={podcastId ? `${podcastId}'s Podcasts` : ""}
        open={showPodcastListModal}
        onOk={handleOk}
        okText="Connect"
        onCancel={handleCancel}
      >
        <>
          <p>
            <b>Already connected podcast artist: {data?.podcast_link}</b>
          </p>

          {podcastsList.length ? (
            <>
              <p>Please choose one podcast you want to show on your feed :</p>
              <Radio.Group onChange={onChange} value={value}>
                <Space direction="vertical">
                  {podcastsList.map((podcast: any) => (
                    <Radio key={podcast.name} value={podcast.name}>
                      {podcast.name}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </>
          ) : (
            ""
          )}
        </>
      </Modal> */}
    </>
  );
};

export default PodcastIntegrationModal;
