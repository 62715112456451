// import { useState } from "react";
// import { useAuth } from "../../hooks/useAuth";
// import { Alert, Button, Input, Modal, Spin, Switch } from "antd";
// import "./IntegrationsBox.css";
// import { useLoadIntegrations } from "./useLoadIntegrations";
// import PodcastIntegrationModal from "./PlatformIntegrationModal/PodcastIntegrationModal";
// import NewsletterIntegrationModal from "./PlatformIntegrationModal/NewsletterIntegrationModal";
// import TiktokIntegrationModal from "./PlatformIntegrationModal/TiktokIntegrationModal";

// import {
//   updateFilloutToggle,
//   updateFlowdeskPopupToggle,
//   updateFlowdeskToggle,
//   updateInstagramToggle,
//   updateNewsletterToggle,
//   updatePinterestToggle,
//   updatePodcastToggle,
//   updateTiktokToggle,
//   updateTwitterToggle,
//   updateYoutubeToggle,
// } from "../../Utils/SupabaseServices/UserDatabase";
// import IntegrationPlatforms from "./IntegrationPlatforms";
// import YoutubeIntegrationModal from "./PlatformIntegrationModal/YoutubeIntegrationModal";
// import PinterestIntegrationModal from "./PlatformIntegrationModal/PinterestIntegrationModal";
// import InstagramIntegrationModal from "./PlatformIntegrationModal/InstagramIntegrationModal";
// import TwitterIntegrationModal from "./PlatformIntegrationModal/TwitterIntegrationModal";
// import FlowdeskIntegrationModal from "./PlatformIntegrationModal/FlowdeskIntegrationModal";
// import FlowdeskPopupIntegrationModal from "./PlatformIntegrationModal/FlowdeskPopupIntegrationModal";
// import FilloutIntegrationModal from "./PlatformIntegrationModal/FilloutIntegrationModal";

// export default function IntegrationsBox(props: any) {
//   const { subscription_type, first_connected_platform } = props;
//   const { user } = useAuth();

//   const [showChannelIdModal, setShowChannelIdModal] = useState(false);
//   const [showFormIdModal, setShowFormIdModal] = useState(false);
//   const [showPopupFormIdModal, setShowPopupFormIdModal] = useState(false);

//   const [showNewsletterModal, setShowNewsletterModal] = useState(false);
//   const [showPinterestModal, setShowPinterestModal] = useState(false);
//   const [showFlowdeskModal, setShowFlowdeskModal] = useState(false);
//   const [showFilloutModal, setShowFilloutModal] = useState(false);

//   const [showPodcastModal, setShowPodcastModal] = useState(false);
//   const [showTiktokModal, setShowTiktokModal] = useState(false);
//   const [showTwitterModal, setShowTwitterModal] = useState(false);

//   const [showInstagramModal, setShowInstagramModal] = useState(false);

//   const {
//     isLoading,
//     // isError,
//     // error,
//     data,
//     isFetching,
//     refetch,
//   } = useLoadIntegrations(user?.id as string);
//   //Instagram -------------------------------------------------
//   const [isPlatformEnabled, setIsPlatformEnabled] = useState({
//     isInstagramEnabled: Boolean(data?.is_instagram_on),
//     isYoutubeEnabled: Boolean(data?.is_youtube_on)
//   })
//   const isInstagramConnected = Boolean(data?.instagram_username);
//   const showInstagram = Boolean(data?.show_instagram);
//   // const isInstagramEnabled = Boolean(data?.is_instagram_on);
//   const toggleInstagram = async (checked: boolean) => {
//     const toggle = checked;
//     setIsPlatformEnabled((prev) => ({
//       ...prev,
//       isInstagramEnabled: toggle
//     }))
//     updateInstagramToggle(user?.id, toggle)
//   };

//   //Youtube-------------------------------------------------------
//   const isYoutubeConnected = Boolean(data?.youtube_channel_id);
//   const showYoutube = Boolean(data?.show_youtube);
//   const toggleYoutube = async (checked: boolean) => {
//     const toggle = checked;
//     setIsPlatformEnabled((prev) => ({
//       ...prev,
//       isYoutubeEnabled: toggle
//     }))
//     updateYoutubeToggle(user?.id, toggle).then(() => {
//       refetch();
//     });
//   };

//   //Podcast--------------------------------------------------------
//   const isPodcastConnected = Boolean(data?.podcast_link);
//   const showPodcast = Boolean(data?.show_podcast);
//   const isPodcastEnabled = Boolean(data?.is_podcast_on);
//   const togglePodcast = async (checked: boolean) => {
//     const toggle = checked;

//     updatePodcastToggle(user?.id, toggle).then(() => {
//       refetch();
//     });
//   };
//   //Newsletter ------------------------------------------------------
//   const isNewsletterConnected = Boolean(data?.newsletter_email);
//   const showNewsletter = Boolean(data?.show_newsletter);
//   const isNewsletterEnabled = Boolean(data?.is_newsletter_on);
//   const toggleNewsletter = async (checked: boolean) => {
//     const toggle = checked;

//     updateNewsletterToggle(user?.id, toggle).then(() => {
//       refetch();
//     });
//   };

//   //Tiktok ---------------------------------------------------------
//   const isTiktokConnected = Boolean(data?.tiktok_user);
//   const showTiktok = Boolean(data?.show_tiktok);
//   const isTiktokEnabled = Boolean(data?.is_tiktok_on);
//   const toggleTiktok = async (checked: boolean) => {
//     const toggle = checked;

//     updateTiktokToggle(user?.id, toggle).then(() => {
//       refetch();
//     });
//   };

//   //Twitter ---------------------------------------------------------
//   const isTwitterConnected = Boolean(data?.twitter_user);
//   const showTwitter = Boolean(data?.show_twitter);
//   const isTwitterEnabled = Boolean(data?.is_twitter_on);
//   const toggleTwitter = async (checked: boolean) => {
//     const toggle = checked;

//     updateTwitterToggle(user?.id, toggle).then(() => {
//       refetch();
//     });
//   };

//   //Pinterest ------------------------------------------------------
//   const isPinterestConnected = Boolean(data?.pinterest_user);

//   const showPinterest = Boolean(data?.show_pinterest);
//   const isPinterestEnabled = Boolean(data?.is_pinterest_on);
//   const togglePinterest = async (checked: boolean) => {
//     const toggle = checked;

//     updatePinterestToggle(user?.id, toggle).then(() => {
//       refetch();
//     });
//   };

//   //Flowdesk ------------------------------------------------------
//   const isFlowdeskConnected = Boolean(data?.flowdesk_user);

//   const showFlowdesk = Boolean(data?.show_flowdesk);
//   const isFlowdeskEnabled = Boolean(data?.is_flowdesk_on);
//   const toggleFlowdesk = async (checked: boolean) => {
//     const toggle = checked;

//     updateFlowdeskToggle(user?.id, toggle).then(() => {
//       refetch();
//     });
//   };

//   //FlowdeskPopup ------------------------------------------------------
//   const isFlowdeskPopupConnected = Boolean(data?.flowdeskpopup_user);

//   const showFlowdeskPopup = Boolean(data?.show_flowdeskpopup);
//   const isFlowdeskPopupEnabled = Boolean(data?.is_flowdeskpopup_on);
//   const toggleFlowdeskPopup = async (checked: boolean) => {
//     const toggle = checked;

//     updateFlowdeskPopupToggle(user?.id, toggle).then(() => {
//       refetch();
//     });
//   };

//   //Fillout ------------------------------------------------------
//   const isFilloutConnected = Boolean(data?.fillout_user);

//   const showFillout = Boolean(data?.show_fillout);
//   const isFilloutEnabled = Boolean(data?.is_fillout_on);
//   const toggleFillout = async (checked: boolean) => {
//     const toggle = checked;

//     updateFilloutToggle(user?.id, toggle).then(() => {
//       refetch();
//     });
//   };

//   // const isInstagramConnected = Boolean(data?.instagramId);

//   const [showConfirmation, setShowConfirmation] = useState(false);
//   const [platformTodelete, setPlatformToDelete] = useState("");

//   const handleDeleteClick = (platform: any) => {
//     setShowConfirmation(true);
//     setPlatformToDelete(platform);
//   };
//   const onClick = async () => {
//     setShowChannelIdModal(true);
//   };

//   return (
//     <>
//       <div className="account-social-content-connect">
//         {isLoading || isFetching ? (
//           <div className="spin-integration">
//             <Spin />
//           </div>
//         ) : (
//           <>
//             <IntegrationPlatforms
//               platformName="Instagram"
//               isConnected={isInstagramConnected}
//               isProcessing={showInstagram}
//               isPlatformEnabled={isPlatformEnabled.isInstagramEnabled}
//               onTogglePlatform={toggleInstagram}
//               userId={user?.id}
//               refetchData={refetch}
//               onConnectPlatform={() => setShowInstagramModal(true)}
//               onDeletePlatform={() => handleDeleteClick("Instagram")}
//               showConfirmation={showConfirmation}
//               setShowConfirmation={setShowConfirmation}
//               platformTodelete={platformTodelete}
//               subscription_type={subscription_type}
//               first_connected_platform={first_connected_platform}
//             // onDeletePlatform={/* Delete platform function */}
//             />
//             <IntegrationPlatforms
//               platformName="Youtube"
//               isConnected={isYoutubeConnected}
//               isProcessing={showYoutube}
//               isPlatformEnabled={isPlatformEnabled.isYoutubeEnabled}
//               onTogglePlatform={toggleYoutube}
//               onConnectPlatform={() => onClick()}
//               onDeletePlatform={() => handleDeleteClick("Youtube")}
//               showConfirmation={showConfirmation}
//               setShowConfirmation={setShowConfirmation}
//               platformTodelete={platformTodelete}
//               userId={user?.id}
//               refetchData={refetch}
//               subscription_type={subscription_type}
//               first_connected_platform={first_connected_platform}
//             />
//             {/* <IntegrationPlatforms
//         platformName="Podcast"
//         isConnected={isPodcastConnected}
//         isProcessing={showPodcast}
//         isPlatformEnabled={isPodcastEnabled}
//         onTogglePlatform={togglePodcast}
//         onConnectPlatform=
//         onDeletePlatform=
//       /> */}
//             <IntegrationPlatforms
//               platformName="Podcast"
//               isConnected={isPodcastConnected}
//               isProcessing={showPodcast}
//               isPlatformEnabled={isPodcastEnabled}
//               onTogglePlatform={togglePodcast}
//               onConnectPlatform={() => setShowPodcastModal(true)}
//               onDeletePlatform={() => handleDeleteClick("Podcast")}
//               showConfirmation={showConfirmation}
//               setShowConfirmation={setShowConfirmation}
//               platformTodelete={platformTodelete}
//               userId={user?.id}
//               refetchData={refetch}
//               subscription_type={subscription_type}
//               first_connected_platform={first_connected_platform}
//             />
//             <IntegrationPlatforms
//               platformName="Newsletter"
//               isConnected={isNewsletterConnected}
//               isProcessing={showNewsletter}
//               isPlatformEnabled={isNewsletterEnabled}
//               onTogglePlatform={toggleNewsletter}
//               onConnectPlatform={() => setShowNewsletterModal(true)}
//               onDeletePlatform={() => handleDeleteClick("Newsletter")}
//               showConfirmation={showConfirmation}
//               setShowConfirmation={setShowConfirmation}
//               platformTodelete={platformTodelete}
//               userId={user?.id}
//               refetchData={refetch}
//               subscription_type={subscription_type}
//               first_connected_platform={first_connected_platform}
//             />
//             <IntegrationPlatforms
//               platformName="Tiktok"
//               isConnected={isTiktokConnected}
//               isProcessing={showTiktok}
//               isPlatformEnabled={isTiktokEnabled}
//               onTogglePlatform={toggleTiktok}
//               onConnectPlatform={() => setShowTiktokModal(true)}
//               onDeletePlatform={() => handleDeleteClick("Tiktok")}
//               showConfirmation={showConfirmation}
//               setShowConfirmation={setShowConfirmation}
//               platformTodelete={platformTodelete}
//               userId={user?.id}
//               refetchData={refetch}
//               subscription_type={subscription_type}
//               first_connected_platform={first_connected_platform}
//             />
//             <IntegrationPlatforms
//               platformName="Pinterest"
//               isConnected={isPinterestConnected}
//               isProcessing={showPinterest}
//               isPlatformEnabled={isPinterestEnabled}
//               onTogglePlatform={togglePinterest}
//               onConnectPlatform={() => setShowPinterestModal(true)}
//               onDeletePlatform={() => handleDeleteClick("Pinterest")}
//               showConfirmation={showConfirmation}
//               setShowConfirmation={setShowConfirmation}
//               platformTodelete={platformTodelete}
//               userId={user?.id}
//               refetchData={refetch}
//               subscription_type={subscription_type}
//               first_connected_platform={first_connected_platform}
//             />

//             <IntegrationPlatforms
//               platformName="Twitter"
//               isConnected={isTwitterConnected}
//               isProcessing={showTwitter}
//               isPlatformEnabled={isTwitterEnabled}
//               onTogglePlatform={toggleTwitter}
//               onConnectPlatform={() => setShowTwitterModal(true)}
//               onDeletePlatform={() => handleDeleteClick("Twitter")}
//               showConfirmation={showConfirmation}
//               setShowConfirmation={setShowConfirmation}
//               platformTodelete={platformTodelete}
//               userId={user?.id}
//               refetchData={refetch}
//               subscription_type={subscription_type}
//               first_connected_platform={first_connected_platform}
//             />
//             <IntegrationPlatforms
//               platformName="Flodesk Inline"
//               isConnected={isFlowdeskConnected}
//               isProcessing={showFlowdesk}
//               isPlatformEnabled={isFlowdeskEnabled}
//               onTogglePlatform={toggleFlowdesk}
//               onConnectPlatform={() => setShowFormIdModal(true)}
//               onDeletePlatform={() => handleDeleteClick("Flodesk Inline")}
//               showConfirmation={showConfirmation}
//               setShowConfirmation={setShowConfirmation}
//               platformTodelete={platformTodelete}
//               userId={user?.id}
//               refetchData={refetch}
//               subscription_type={subscription_type}
//               first_connected_platform={first_connected_platform}
//             />
//             <IntegrationPlatforms
//               platformName="Flodesk Popup"
//               isConnected={isFlowdeskPopupConnected}
//               isProcessing={showFlowdeskPopup}
//               isPlatformEnabled={isFlowdeskPopupEnabled}
//               onTogglePlatform={toggleFlowdeskPopup}
//               onConnectPlatform={() => setShowPopupFormIdModal(true)}
//               onDeletePlatform={() => handleDeleteClick("Flodesk Popup")}
//               showConfirmation={showConfirmation}
//               setShowConfirmation={setShowConfirmation}
//               platformTodelete={platformTodelete}
//               userId={user?.id}
//               refetchData={refetch}
//               subscription_type={subscription_type}
//               first_connected_platform={first_connected_platform}
//             />
//             <IntegrationPlatforms
//               platformName="Fillout"
//               isConnected={isFilloutConnected}
//               isProcessing={showFillout}
//               isPlatformEnabled={isFilloutEnabled}
//               onTogglePlatform={toggleFillout}
//               onConnectPlatform={() => setShowFilloutModal(true)}
//               onDeletePlatform={() => handleDeleteClick("Fillout")}
//               showConfirmation={showConfirmation}
//               setShowConfirmation={setShowConfirmation}
//               platformTodelete={platformTodelete}
//               userId={user?.id}
//               refetchData={refetch}
//               subscription_type={subscription_type}
//               first_connected_platform={first_connected_platform}

//             />
//           </>
//         )}
//       </div>
//       <YoutubeIntegrationModal
//         showChannelIdModal={showChannelIdModal}
//         setShowChannelIdModal={setShowChannelIdModal}
//         user_id={user?.id}
//         data={data}
//         refetchData={refetch}
//       />

//       <NewsletterIntegrationModal
//         showNewsletterModal={showNewsletterModal}
//         setShowNewsletterModal={setShowNewsletterModal}
//         user_id={user?.id}
//         data={data}
//         refetchData={refetch}
//       />
//       <PodcastIntegrationModal
//         showPodcastModal={showPodcastModal}
//         setShowPodcastModal={setShowPodcastModal}
//         user_id={user?.id}
//         data={data}
//       />
//       <TiktokIntegrationModal
//         showTiktokModal={showTiktokModal}
//         setShowTiktokModal={setShowTiktokModal}
//         user_id={user?.id}
//         data={data}
//       />
//       <TwitterIntegrationModal
//         showTwitterModal={showTwitterModal}
//         setShowTwitterModal={setShowTwitterModal}
//         user_id={user?.id}
//         data={data}
//       />
//       <InstagramIntegrationModal
//         showInstagramModal={showInstagramModal}
//         setShowInstagramModal={setShowInstagramModal}
//         user_id={user?.id}
//         data={data}
//       />
//       <PinterestIntegrationModal
//         showPinterestModal={showPinterestModal}
//         setShowPinterestModal={setShowPinterestModal}
//         user_id={user?.id}
//         data={data}
//       />
//       <FlowdeskIntegrationModal
//         // showFlowdeskModal={showFlowdeskModal}
//         // setShowFlowdeskModal={setShowFlowdeskModal}
//         // user_id={user?.id}
//         // data={data}
//         showFormIdModal={showFormIdModal}
//         setShowFormIdModal={setShowFormIdModal}
//         user_id={user?.id}
//         data={data}
//         refetchData={refetch}
//       />
//       <FlowdeskPopupIntegrationModal
//         // showFlowdeskModal={showFlowdeskModal}
//         // setShowFlowdeskModal={setShowFlowdeskModal}
//         // user_id={user?.id}
//         // data={data}
//         showPopupFormIdModal={showPopupFormIdModal}
//         setShowPopupFormIdModal={setShowPopupFormIdModal}
//         user_id={user?.id}
//         data={data}
//         refetchData={refetch}
//       />
//       <FilloutIntegrationModal
//         // showFlowdeskModal={showFlowdeskModal}
//         // setShowFlowdeskModal={setShowFlowdeskModal}
//         // user_id={user?.id}
//         // data={data}
//         showFilloutModal={showFilloutModal}
//         setShowFilloutModal={setShowFilloutModal}
//         user_id={user?.id}
//         data={data}
//         refetchData={refetch}
//       />
//       {/* <FilloutIntegrationModal
//       showFilloutModal={showFilloutModal}
//       setShowFilloutModal={setShowFilloutModal}
//       user_id={user?.id}
//       data={data}
//     /> */}
//     </>
//   );
// }



import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { Modal, Spin } from "antd";
import "./IntegrationsBox.css";
import { useLoadIntegrations } from "./useLoadIntegrations";
import IntegrationPlatforms from "./IntegrationPlatforms";
import {
  updatePlatformToggle,
} from "../../Utils/SupabaseServices/UserDatabase";

import YoutubeIntegrationModal from "./PlatformIntegrationModal/YoutubeIntegrationModal";
import InstagramIntegrationModal from "./PlatformIntegrationModal/InstagramIntegrationModal";
import PodcastIntegrationModal from "./PlatformIntegrationModal/PodcastIntegrationModal";
import NewsletterIntegrationModal from "./PlatformIntegrationModal/NewsletterIntegrationModal";
import TiktokIntegrationModal from "./PlatformIntegrationModal/TiktokIntegrationModal";
import PinterestIntegrationModal from "./PlatformIntegrationModal/PinterestIntegrationModal";
import TwitterIntegrationModal from "./PlatformIntegrationModal/TwitterIntegrationModal";
import FlowdeskIntegrationModal from "./PlatformIntegrationModal/FlowdeskIntegrationModal";
import FlowdeskPopupIntegrationModal from "./PlatformIntegrationModal/FlowdeskPopupIntegrationModal";
import FilloutIntegrationModal from "./PlatformIntegrationModal/FilloutIntegrationModal";
// import PlatformModals from "./PlatformModals";

export default function IntegrationsBox({ subscription_type, first_connected_platform }: any) {
  const { user } = useAuth();
  const { isLoading, isFetching, data, refetch } = useLoadIntegrations(user?.id as string);

  const [modalState, setModalState] = useState({
    currentPlatform: "",
    isVisible: false,
  });

  const [platformToggles, setPlatformToggles] = useState<Record<string, boolean>>({});
  const [showConfirmation, setShowConfirmation] = useState({
    platformKey: "",
    platformToggles: false
  });

  const platforms = [
    { name: "Instagram", key: "instagram", modalKey: "InstagramModal" },
    { name: "Youtube", key: "youtube", modalKey: "YoutubeModal" },
    { name: "Podcast", key: "podcast", modalKey: "PodcastModal" },
    { name: "Newsletter", key: "newsletter", modalKey: "NewsletterModal" },
    { name: "Tiktok", key: "tiktok", modalKey: "TiktokModal" },
    // { name: "Pinterest", key: "pinterest", modalKey: "PinterestModal" },
    // { name: "Twitter", key: "twitter", modalKey: "TwitterModal" },
    { name: "Flodesk Inline", key: "flowdesk", modalKey: "FlowdeskModal" },
    { name: "Flodesk Popup", key: "flowdeskpopup", modalKey: "FlowdeskPopupModal" },
    { name: "Fillout", key: "fillout", modalKey: "FilloutModal" },
  ];

  const handleToggle = async (platformKey: string, checked: boolean) => {
    // setPlatformToggles((prevState) => ({
    //   ...prevState,
    //   [platformKey]: checked,
    // }));
    // await updatePlatformToggle(user?.id, platformKey, checked);
    if (checked == false) {
      setShowConfirmation({
        platformKey,
        platformToggles: !checked
      })
    } else {
      handleOffPlatformToggle(platformKey, checked)
    }

    // refetch();
  };



  const handleOffCancel = () => {
    setShowConfirmation({
      platformToggles: false,
      platformKey: "",
    })
  };

  const handleOffPlatformToggle = async (platformKey: string, checked: boolean) => {
    setPlatformToggles((prevState) => ({
      ...prevState,
      [platformKey]: checked,
    }));
    setShowConfirmation({
      platformToggles: false,
      platformKey: "",
    })
    await updatePlatformToggle(user?.id, platformKey, checked);
  }

  const handleModalOpen = (platformKey: string) => {
    setModalState({ currentPlatform: platformKey, isVisible: true });
  };

  const handleModalClose = () => {
    setModalState({ currentPlatform: "", isVisible: false });
  };

  useEffect(() => {
    if (data) {
      const initialToggles = platforms.reduce((acc, platform) => {
        acc[platform.key] = Boolean(data[`is_${platform.key}_on`]);
        return acc;
      }, {} as Record<string, boolean>);
      setPlatformToggles(initialToggles);
    }
  }, [data]);

  const handleConnected = (platformKey: string) => {
    switch (platformKey) {
      case "tiktok":
      case "twitter":
      case "fillout":
      case "pinterest":
      case "flowdesk":
      case "flowdeskpopup":
        return `${platformKey}_user`;

      case "instagram":
        return "instagram_username";

      case "youtube":
        return "youtube_channel_id";

      case "podcast":
        return "podcast_link";

      case "newsletter":
        return "newsletter_email";

      default:
        return `${platformKey}_user`;
    }
  };

  const platformNames = platforms.find((platform) => platform.key === showConfirmation.platformKey)?.name|| 'this platform';


  return (
    <>
      <div className="account-social-content-connect">
        {isLoading || isFetching ? (
          <div className="spin-integration">
            <Spin />
          </div>
        ) : (
          <>
            {platforms.map(({ name, key, modalKey }) => {
              const isConnected = handleConnected(key)
              return (
                <IntegrationPlatforms
                  key={key}
                  platformName={name}
                  isConnected={Boolean(data?.[`${isConnected}`])}
                  isProcessing={Boolean(data?.[`show_${key}`])}
                  // isPlatformEnabled={Boolean(data?.[`is_${key}_on`])}
                  isPlatformEnabled={platformToggles[key]}
                  onTogglePlatform={(checked: any) => handleToggle(key, checked)}
                  onConnectPlatform={() => handleModalOpen(modalKey)}
                  subscription_type={subscription_type}
                  first_connected_platform={first_connected_platform}
                />
              )
            })}
          </>)}
      </div>
      <PlatformModals
        modalState={modalState}
        onClose={handleModalClose}
        userId={user?.id}
        data={data}
        refetch={refetch}
      />
      <Modal
        title={ ""}
        open={showConfirmation.platformToggles}
        onOk={async () => handleOffPlatformToggle(showConfirmation.platformKey, false)}
        okText="Confirm"
        onCancel={handleOffCancel}
      >
        <p className="unlock-text">
          {`Are you sure you want to turn off the ${platformNames}?`}
        </p>
      </Modal>

    </>
  );
}


const PlatformModals = ({ modalState, onClose, userId, data, refetch }: any) => {
  const { currentPlatform, isVisible } = modalState;

  const modalMap: any = {
    InstagramModal: InstagramIntegrationModal,
    YoutubeModal: YoutubeIntegrationModal,
    PodcastModal: PodcastIntegrationModal,
    NewsletterModal: NewsletterIntegrationModal,
    TiktokModal: TiktokIntegrationModal,
    PinterestModal: PinterestIntegrationModal,
    TwitterModal: TwitterIntegrationModal,
    FlowdeskModal: FlowdeskIntegrationModal,
    FlowdeskPopupModal: FlowdeskPopupIntegrationModal,
    FilloutModal: FilloutIntegrationModal,
  };

  const SelectedModal = modalMap[currentPlatform];
  const props = {
    [`show${currentPlatform}`]: { isVisible }
  }
  return SelectedModal ? (
    <SelectedModal
      {...props}
      onClose={onClose}
      user_id={userId}
      data={data}
      refetchData={refetch}
    />
  ) : null;
};

