import { useInfiniteQuery, useQuery } from "react-query";
import { supabase } from "../../../config/supabaseClient";

export function useLoadProductBankPlatform(
  user_id: string,
  query_string: string,
  filter: string
) {
  const fetchProductBankPlateform = async () => {
    const { data, error } = await supabase.rpc("get_productbank_platformmmm", {
      userid: user_id,
      filter: filter,
      query_string: null, // or provide an array like ['example_query']
      query_tagg: null,
    });
    if (error) {
      throw new Error(error.message);
    }
    const filteredData = [...new Set(data)] // Remove duplicates
      .filter((platform: any) => platform && platform !== "MISC"); // Filter out falsy values and "MISC"

    const productFilterItems =
      filteredData.length > 1
        ? [
            { key: "All", label: "All" },
            ...filteredData.map((platform: any) => ({
              key: platform,
              label: platform,
            })),
          ]
        : [];
    return productFilterItems;
  };

  const { data, isLoading, isError, error, refetch } = useQuery(
    ["productbankplaform", filter],
    fetchProductBankPlateform
  );

  return { data, isLoading, isError, error, refetch };
}
