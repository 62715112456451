import { Button } from "antd";
import React from "react";
import { Card } from "react-bootstrap";
import { BsHourglassSplit } from "react-icons/bs";
import "../StayUpToDateComponent/StayUpToDate.css";
import { getPostTitle } from "../../Utils/HelperFunction/HelperFunction";

const ImagePostTag = React.memo((props: any) => {
  const {
    data,
    // handleRedirect,
    brand_color,
    title,
    redirectVariable,
    isLinks,
    isDashboard,
    type,
    isYoutube,
    isHasMediaUrl,
    productName,
    media_url,
    isCarousel,
  } = props;

  function getMediaSrc(type: any, data: any) {
    if (isCarousel) {
      return media_url;
    } else {
      if (type !== "content" && type !== "query-content") {
        if (type === "product-bank") {
          return data?.table_image ? data.table_image : data?.product_image;
        } else {
          return data?.media_url.includes("video")
            ? data?.product_image
              ? data?.product_image
              : data?.thumbnail
            : data?.product_image
            ? data?.product_image
            : data?.media_url;
        }
      } else {
        return data?.media_url
          ? data?.media_url
          : data?.media_url.includes("video")
          ? data?.thumbnail
          : data?.product_image;
      }
    }
  }
  const redirectUrl = !isLinks
    ? data?.permalink || data?.shop_link || data?.table_url
    : data?.shop_link || data?.table_url || data?.permalink;
  return (
    <a
      href={
        redirectUrl
        // !isLinks ? (data?.permalink ? data?.permalink : data?.table_url ? data?.table_url : data?.shop_link) :
        //   (data?.shop_link && data?.shop_link !== ""
        //     ? data?.shop_link
        //     : data?.permalink ? data?.permalink : data?.table_url)
      }
      target="_blank"
      onClick={(e) => {
        e.preventDefault();
        props.handleRedirect(
          data?.post_id,
          // data?.media_url.includes("video")
          //   ? data?.product_image
          //     ? data?.product_image
          //     : data?.thumbnail
          //   : data?.product_image
          //   ? data?.product_image
          //   : data?.media_url,
          getMediaSrc(type, data),
          title,
          data?.product_name
        );
        if (
          (data?.shop_link && data?.shop_link !== "") ||
          (data?.permalink && data?.permalink !== "") ||
          (data?.table_url && data?.table_url !== "")
        ) {
          window.open(
            redirectUrl,
            // data?.shop_link && data?.shop_link !== ""
            //   ? data?.shop_link
            //   : data.permalink
            //     ? data.permalink
            //     : data?.table_url,
            "_blank"
          );
        }
      }}
    >
      {!data?.media_url ? (
        <div className={"creator-feed-card-links not-available"}>
          <BsHourglassSplit className="hourglassSplit" />
          <p>
            Work in progress: This content will be available in a few minutes.
          </p>
        </div>
      ) : (
        <>
          <div
            className={
              isYoutube
                ? "creator-feed-image-wrapper-links youtube-feed-wrapper"
                : isHasMediaUrl
                ? "creator-feed-image-wrapper-links media-feed-wrapper"
                : "creator-feed-image-wrapper-links"
            }
          >
            <Card.Img
              variant="top"
              src={
                getMediaSrc(type, data)
                // data?.media_url.includes("video")
                //   ? data?.product_image
                //     ? data?.product_image
                //     : data?.thumbnail
                //   : data?.product_image
                //   ? data?.product_image
                //   : data?.media_url
              }
              className={
                isYoutube
                  ? "creator-feed-card-links-image youtube-feed-height"
                  : isHasMediaUrl
                  ? "creator-feed-card-video not-available media-feed-height"
                  : "creator-feed-card-links-image"
              }
              loading="eager"
              style={{ backgroundColor: brand_color && brand_color }}
            />
          </div>
          {type === "content" || type === "query-content" ? (
            productName ? (
              <p className="product-name">
                {productName}
                {/* {post?.product_name} */}
              </p>
            ) : null
          ) : data?.shop_title ? (
            <p className="product-name">{data.shop_title}</p>
          ) : data?.content_title ? (
            <p className="product-name">{data.content_title}</p>
          ) : data?.product_name ? (
            <p className="product-name">{data.product_name}</p>
          ) : null}{" "}
          {isLinks && (
            <Button className={"template-shop-btn-links"}>Shop</Button>
          )}
        </>
      )}
      {/* </div> */}
    </a>
  );
});

export default ImagePostTag;
